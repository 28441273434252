import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  IconButton,
} from "@mui/material";
import {
  Calendar,
  BarChart,
  Tag,
  Nfc,
  TreeDeciduous,
  Users,
  Camera,
  Map,
  Upload,
  Bike,
  Router,
  ChevronLeft,
  ChevronRight,
  Notebook,
} from "lucide-react";

export const routes = [
  {
    category: "Eventos",
    links: [
      {
        label: "Terminar evento",
        path: "event-list",
        icon: <Calendar />,
      },
      { label: "Leaderboards", path: "/Leaderboards", icon: <BarChart /> },
      {
        label: "CapitaLeaderboard",
        path: "/capitanleaderboard",
        icon: <BarChart />,
      },
      { label: "Reviews", path: "/reviews", icon: <Router /> },
    ],
  },
  {
    category: "Finanzas",
    links: [
      {
        label: "Ordenes",
        path: "ordercontrol",
        icon: <Notebook />,
      },
    ],
  },
  {
    category: "Administración de Dispositivos",
    links: [
      { label: "LoRa CSV Reader", path: "/signaltest", icon: <Router /> },
      { label: "Receiver Manager", path: "/ReceiverManager", icon: <Router /> },
      {
        label: "CSV Uploader",
        path: "csv-uploader",
        icon: <Upload />,
      },
      { label: "Usuarios", path: "/UsersControl", icon: <Users /> },
    ],
  },
  {
    category: "NFC y Tags",
    links: [
      { label: "Tags Control", path: "tagcontrol", icon: <Tag /> },
      { label: "Mis Tags", path: "/MyTags", icon: <Tag /> },
      { label: "Start NFC", path: "/StartNFC", icon: <Nfc /> },
      { label: "Finish NFC", path: "/finishNFC", icon: <Nfc /> },
      { label: "uploadTags", path: "/uploadTags", icon: <Upload /> },
      { label: "Riders Tags", path: "/riderstags", icon: <Tag /> },
    ],
  },
  {
    category: "Monitoreo",
    links: [
      { label: "MapCam", path: "/MapCam", icon: <Map /> },
      { label: "NfcCam", path: "/NfcCam", icon: <Camera /> },
      { label: "FinishCam", path: "/FinishCam", icon: <Camera /> },
      { label: "Active Riders", path: "/activeriders", icon: <Bike /> },
    ],
  },
  {
    category: "Otros",
    links: [
      { label: "CreatePark", path: "/CreatePark", icon: <TreeDeciduous /> },
      { label: "Manual Start", path: "/manualstart", icon: <Calendar /> },
      { label: "Manual Live Rider", path: "/ManualLiveRider", icon: <Bike /> },
    ],
  },
];

const ControlLinks = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleSidebar = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      sx={{
        backgroundColor: isMobile ? "#f5f5f5" : "#1F2937", // Oscuro en escritorio, claro en móvil
        color: isMobile ? "#333" : "#fff",
        minHeight: isMobile ? "auto" : "100vh",
        width: isMobile ? "100%" : isExpanded ? "240px" : "60px",
        transition: "width 0.3s",
        p: 3,
        boxShadow: isMobile ? "none" : "2px 0 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        alignItems: isExpanded ? "flex-start" : "center",
        overflow: "hidden",
      }}
    >
      {/* Botón de Expandir/Contraer */}
      {!isMobile && (
        <IconButton
          onClick={toggleSidebar}
          sx={{
            color: "#E5E7EB",
            alignSelf: isExpanded ? "flex-end" : "center",
            mb: 2,
          }}
        >
          {isExpanded ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      )}

      {/* Enlaces de Control por Categoría */}
      {routes.map((section, sectionIndex) => (
        <Box key={sectionIndex} mb={3} sx={{ width: "100%" }}>
          {isExpanded && (
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                color: isMobile ? "#333" : "#9CA3AF",
                mb: 1,
                pl: isExpanded ? 1 : 0,
              }}
            >
              {section.category}
            </Typography>
          )}
          <List
            sx={{
              display: isMobile ? "grid" : "block",
              gridTemplateColumns: isMobile ? "repeat(2, 1fr)" : "none",
              gap: isMobile ? 2 : 0,
              backgroundColor: isMobile ? "transparent" : "#1F2937",
              p: 0,
            }}
          >
            {section.links.map((route, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  component={Link}
                  to={route.path}
                  sx={{
                    color: isMobile ? "#333" : "#E5E7EB",
                    mb: isMobile ? 0 : 1,
                    borderRadius: 1,
                    textAlign: "center",
                    justifyContent: isExpanded ? "flex-start" : "center",
                    "&:hover": {
                      backgroundColor: isMobile ? "#e0e0e0" : "#374151",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: isMobile ? "#333" : "#9CA3AF",
                      minWidth: "auto",
                      mb: isMobile ? 0.5 : 0,
                    }}
                  >
                    {route.icon}
                  </ListItemIcon>
                  {isExpanded && (
                    <ListItemText
                      primary={route.label}
                      primaryTypographyProps={{
                        fontSize: "0.9rem",
                        color: isMobile ? "#333" : "#E5E7EB",
                      }}
                    />
                  )}
                </ListItem>
                {!isMobile &&
                  index < section.links.length - 1 &&
                  isExpanded && <Divider sx={{ borderColor: "#374151" }} />}
              </React.Fragment>
            ))}
          </List>
        </Box>
      ))}
    </Box>
  );
};

export default ControlLinks;
