import React, { useState } from "react";
import {
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Grid,
  CircularProgress,
  Box,
} from "@mui/material";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { storage, db } from "../../firebase";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

const CreateOrganization = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [organizationData, setOrganizationData] = useState({
    organizationName: "",
    logo: null,
    cover: null,
    country: "",
    state: "",
    instagram: "",
    facebook: "",
    email: "",
    phone: "",
  });
  const [logoPreview, setLogoPreview] = useState(null);
  const [coverPreview, setCoverPreview] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prev) => ({ ...prev, [name]: value }));
  };

  const handleLogoChange = (e) => {
    setOrganizationData({ ...organizationData, logo: e.target.files[0] });
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setLogoPreview(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCoverChange = (e) => {
    setOrganizationData({ ...organizationData, cover: e.target.files[0] });
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setCoverPreview(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (!organizationData.logo || !organizationData.cover) {
        alert(
          "Por favor, seleccione un logo y una foto de portada para la organización."
        );
        setLoading(false);
        return;
      }

      const logoRef = ref(
        storage,
        `organization_logos/${user.uid}/${organizationData.logo.name}`
      );
      const coverRef = ref(
        storage,
        `organization_covers/${user.uid}/${organizationData.cover.name}`
      );

      const uploadLogoTask = uploadBytesResumable(
        logoRef,
        organizationData.logo
      );
      const uploadCoverTask = uploadBytesResumable(
        coverRef,
        organizationData.cover
      );

      const logoUrl = await new Promise((resolve, reject) => {
        uploadLogoTask.on("state_changed", null, reject, () => {
          getDownloadURL(uploadLogoTask.snapshot.ref)
            .then(resolve)
            .catch(reject);
        });
      });

      const coverUrl = await new Promise((resolve, reject) => {
        uploadCoverTask.on("state_changed", null, reject, () => {
          getDownloadURL(uploadCoverTask.snapshot.ref)
            .then(resolve)
            .catch(reject);
        });
      });

      await addDoc(collection(db, "Organizations"), {
        organizationName: organizationData.organizationName,
        logo: logoUrl,
        cover: coverUrl,
        country: organizationData.country,
        state: organizationData.state,
        instagram: organizationData.instagram,
        facebook: organizationData.facebook,
        email: organizationData.email,
        phone: organizationData.phone,
        Owners: [user.uid],
        Followers: [],
        Guards: [],
        History: [],
        createdAt: serverTimestamp(),
      });

      setLoading(false);
      navigate(`/organizations`);
    } catch (error) {
      console.error("Error creating organization:", error);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={6} sx={{ p: 5, mt: 4, borderRadius: "20px" }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", fontWeight: "bold" }}
        >
          Crear Organización
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre de la Organización"
                type="text"
                name="organizationName"
                value={organizationData.organizationName}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleLogoChange}
                  style={{ display: "none" }}
                  id="raised-button-file-logo"
                />
                <label htmlFor="raised-button-file-logo">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<PhotoCamera />}
                    sx={{
                      backgroundColor: "#1976d2",
                      color: "white",
                      borderRadius: "10px",
                    }}
                  >
                    Subir Logo
                  </Button>
                </label>
                {logoPreview && (
                  <img
                    src={logoPreview}
                    alt="Logo Preview"
                    style={{
                      marginTop: "20px",
                      maxWidth: "100%",
                      borderRadius: "10px",
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  accept="image/*"
                  type="file"
                  onChange={handleCoverChange}
                  style={{ display: "none" }}
                  id="raised-button-file-cover"
                />
                <label htmlFor="raised-button-file-cover">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<PhotoCamera />}
                    sx={{
                      backgroundColor: "#1976d2",
                      color: "white",
                      borderRadius: "10px",
                    }}
                  >
                    Subir Portada
                  </Button>
                </label>
                {coverPreview && (
                  <img
                    src={coverPreview}
                    alt="Cover Preview"
                    style={{
                      marginTop: "20px",
                      maxWidth: "100%",
                      borderRadius: "10px",
                    }}
                  />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="País"
                type="text"
                name="country"
                value={organizationData.country}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Estado"
                type="text"
                name="state"
                value={organizationData.state}
                onChange={handleChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Instagram"
                type="text"
                name="instagram"
                value={organizationData.instagram}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Facebook"
                type="text"
                name="facebook"
                value={organizationData.facebook}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                name="email"
                value={organizationData.email}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Teléfono"
                type="text"
                name="phone"
                value={organizationData.phone}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                  sx={{
                    backgroundColor: "#000",
                    color: "white",
                    padding: "10px 20px",
                    fontSize: "16px",
                    borderRadius: "20px",
                  }}
                >
                  {loading ? (
                    <CircularProgress size={24} sx={{ color: "white" }} />
                  ) : (
                    "Crear Organización"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateOrganization;
