import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../../firebase";
import { doc, getDoc, updateDoc, serverTimestamp } from "firebase/firestore";
import { ArrowLeft, Edit2 } from "lucide-react";

const SingleOrderControl = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editingField, setEditingField] = useState(null); // Field being edited
  const [newPaymentMethod, setNewPaymentMethod] = useState("");
  const [newCreatedAt, setNewCreatedAt] = useState("");

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      try {
        const orderRef = doc(db, "orders", id);
        const orderSnap = await getDoc(orderRef);
        if (orderSnap.exists()) {
          const orderData = { id: orderSnap.id, ...orderSnap.data() };

          // Obtener información adicional del evento y la categoría
          const categoryRef = doc(
            db,
            "events",
            orderData.itemId,
            "categories",
            orderData.categoryId
          );
          const categorySnap = await getDoc(categoryRef);
          orderData.categoryName = categorySnap.exists()
            ? categorySnap.data().name
            : "No disponible";

          const itemRef = doc(db, "events", orderData.itemId);
          const itemSnap = await getDoc(itemRef);
          orderData.itemName = itemSnap.exists()
            ? itemSnap.data().eventName
            : "Evento no disponible";

          // Obtener el email del usuario
          const userRef = doc(db, "Users", orderData.userId);
          const userSnap = await getDoc(userRef);
          orderData.userEmail = userSnap.exists()
            ? userSnap.data().email
            : "Correo no disponible";

          // Manejar valores faltantes de createdAt y paymentMethod
          if (!orderData.createdAt) {
            orderData.createdAt = null;
          }
          if (!orderData.paymentMethod) {
            orderData.paymentMethod = null;
          }

          setOrder(orderData);
        } else {
          console.error("La orden no existe.");
        }
      } catch (error) {
        console.error("Error al obtener la orden:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrder();
  }, [id]);

  const handleSave = async () => {
    if (!order) return;

    setLoading(true);
    try {
      const updates = {};
      if (editingField === "paymentMethod" && newPaymentMethod) {
        updates.paymentMethod = parseInt(newPaymentMethod);
      }
      if (editingField === "createdAt" && newCreatedAt) {
        updates.createdAt = new Date(newCreatedAt);
      } else if (!order.createdAt) {
        updates.createdAt = serverTimestamp();
      }

      const orderRef = doc(db, "orders", order.id);
      await updateDoc(orderRef, updates);

      // Actualizar datos en la interfaz
      setOrder((prev) => ({
        ...prev,
        ...updates,
        createdAt: updates.createdAt || prev.createdAt,
        paymentMethod: updates.paymentMethod || prev.paymentMethod,
      }));

      setEditingField(null);
    } catch (error) {
      console.error("Error al guardar los cambios:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (field) => {
    setEditingField(field);
    if (field === "paymentMethod") {
      setNewPaymentMethod(order.paymentMethod || "");
    } else if (field === "createdAt") {
      setNewCreatedAt(
        order.createdAt
          ? new Date(order.createdAt.seconds * 1000)
              .toISOString()
              .slice(0, 16)
          : ""
      );
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "No disponible";
    if (timestamp.toDate) {
      return timestamp
        .toDate()
        .toLocaleString("es-MX", { timeZone: "America/Mexico_City" });
    }
    return new Date(timestamp).toLocaleString("es-MX", {
      timeZone: "America/Mexico_City",
    });
  };

  return (
    <div className="p-4">
      {/* Botón para regresar */}
      <button
        onClick={() => navigate(-1)}
        className="mb-4 flex items-center text-blue-600 hover:text-blue-800"
      >
        <ArrowLeft className="w-5 h-5 mr-2" />
        Regresar
      </button>

      {loading ? (
        <div className="text-center">Cargando...</div>
      ) : order ? (
        <div className="bg-white shadow-md rounded-lg p-4">
          <h1 className="text-2xl font-bold mb-4">Detalles de la Orden</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h2 className="text-gray-600 font-semibold">ID de Orden</h2>
              <p className="text-gray-800">{order.id}</p>
            </div>
            <div>
              <h2 className="text-gray-600 font-semibold">Usuario</h2>
              <p className="text-gray-800">{order.userId}</p>
            </div>
            <div>
              <h2 className="text-gray-600 font-semibold">Email</h2>
              <p className="text-gray-800">{order.userEmail}</p>
            </div>
            <div>
              <h2 className="text-gray-600 font-semibold">Evento</h2>
              <p className="text-gray-800">{order.itemName}</p>
            </div>
            <div>
              <h2 className="text-gray-600 font-semibold">Categoría</h2>
              <p className="text-gray-800">{order.categoryName}</p>
            </div>
            <div>
              <h2 className="text-gray-600 font-semibold">Estado</h2>
              <p
                className={`text-gray-800 px-3 py-1 rounded-full text-xs ${
                  order.status === "approved"
                    ? "bg-green-100 text-green-700"
                    : order.status === "in_process"
                    ? "bg-blue-100 text-blue-700"
                    : "bg-gray-100 text-gray-700"
                }`}
              >
                {order.status === "approved"
                  ? "Aprobado"
                  : order.status === "in_process"
                  ? "En Proceso"
                  : "Desconocido"}
              </p>
            </div>
            <div>
              <h2 className="text-gray-600 font-semibold flex items-center">
                Fecha de Creación{" "}
                <Edit2
                  className="w-4 h-4 ml-2 cursor-pointer text-yellow-600"
                  onClick={() => handleEditClick("createdAt")}
                />
              </h2>
              {editingField === "createdAt" ? (
                <input
                  type="datetime-local"
                  className="border border-gray-300 rounded-md p-2"
                  value={newCreatedAt}
                  onChange={(e) => setNewCreatedAt(e.target.value)}
                />
              ) : (
                <p className="text-gray-800">{formatTimestamp(order.createdAt)}</p>
              )}
            </div>
            <div>
              <h2 className="text-gray-600 font-semibold flex items-center">
                Método de Pago{" "}
                <Edit2
                  className="w-4 h-4 ml-2 cursor-pointer text-yellow-600"
                  onClick={() => handleEditClick("paymentMethod")}
                />
              </h2>
              {editingField === "paymentMethod" ? (
                <select
                  className="border border-gray-300 rounded-md p-2"
                  value={newPaymentMethod}
                  onChange={(e) => setNewPaymentMethod(e.target.value)}
                >
                  <option value="">Seleccione un método</option>
                  <option value="1">SpeedCoins</option>
                  <option value="2">Stripe</option>
                  <option value="3">MercadoPago</option>
                </select>
              ) : order.paymentMethod ? (
                <p className="text-gray-800">
                  {order.paymentMethod === 1
                    ? "SpeedCoins"
                    : order.paymentMethod === 2
                    ? "Stripe"
                    : order.paymentMethod === 3
                    ? "MercadoPago"
                    : "Desconocido"}
                </p>
              ) : (
                <p className="text-gray-800">No disponible</p>
              )}
            </div>
          </div>
          {editingField && (
            <div className="mt-4 flex justify-end">
              <button
                onClick={handleSave}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition"
              >
                Guardar Cambios
              </button>
              <button
                onClick={() => setEditingField(null)}
                className="ml-2 bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400 transition"
              >
                Cancelar
              </button>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center text-gray-600">
          No se pudo cargar la orden.
        </div>
      )}
    </div>
  );
};

export default SingleOrderControl;
