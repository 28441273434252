import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  CircularProgress,
  Badge,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import Merger from "./Merger";

// Salida del camou: 2000-01-01 00:27:54.378 UTC-7
// =======
// llegada del camou1: 2024-10-26 09:14:23.719 UTC-7 ****
// llegada del camou2: 2024-10-26 09:14:24.369 UTC-7

// tiempo total del kmu: 5:27

const MergeLateRuns = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [receivers, setReceivers] = useState([]);
  const [parks, setParks] = useState({});
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [receiverData, setReceiverData] = useState([]); // Array of receiver data to pass to Merger

  useEffect(() => {
    const fetchData = async () => {
      try {
        const parksQuery = await getDocs(collection(db, "Parks"));
        const parksData = {};
        parksQuery.forEach((doc) => {
          parksData[doc.id] = doc.data();
        });
        setParks(parksData);

        const eventsQuery = await getDocs(collection(db, "events"));
        const eventsData = eventsQuery.docs.map((doc) => ({
          id: doc.id,
          eventName: doc.data().eventName,
        }));
        setEvents(eventsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchReceiversForEvent = async (eventID) => {
    setLoading(true);
    try {
      const receiversQuery = await getDocs(collection(db, "Receivers"));
      const receiversData = await Promise.all(
        receiversQuery.docs.map(async (doc) => {
          const receiverData = { id: doc.id, ...doc.data() };
          if (receiverData.EventID === eventID) {
            if (receiverData.ParkID && receiverData.TrackID) {
              receiverData.TrackName = await fetchTrackName(
                receiverData.ParkID,
                receiverData.TrackID
              );
            }
            return receiverData;
          }
          return null;
        })
      );

      const filteredReceivers = receiversData.filter(
        (receiver) => receiver !== null
      );
      setReceivers(filteredReceivers);
      setReceiverData(
        filteredReceivers.map((receiver) => ({
          id: receiver.id,
          TrackName: receiver.TrackName || "Track desconocido",
          Type: receiver.Type === "0" ? "Inicio" : "Llegada",
        }))
      );
    } catch (error) {
      console.error("Error fetching receivers:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTrackName = async (parkID, trackID) => {
    try {
      const trackDoc = await getDoc(doc(db, `Parks/${parkID}/Tracks`, trackID));
      return trackDoc.exists()
        ? trackDoc.data().TrackName
        : "Track desconocido";
    } catch (error) {
      console.error(`Error fetching track name for ${trackID}:`, error);
      return "Track desconocido";
    }
  };

  const handleEventChange = (e) => {
    const eventID = e.target.value;
    setSelectedEvent(eventID);
    fetchReceiversForEvent(eventID);
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
        sx={{ fontWeight: "bold", color: "#37474f" }}
      >
        Receivers por Evento
      </Typography>

      <Box sx={{ mb: 3, display: "flex", justifyContent: "center" }}>
        <FormControl fullWidth sx={{ maxWidth: 400 }}>
          <InputLabel>Selecciona un Evento</InputLabel>
          <Select
            value={selectedEvent}
            onChange={handleEventChange}
            displayEmpty
          >
            <MenuItem value="">
              <em>Selecciona un evento</em>
            </MenuItem>
            {events.map((event) => (
              <MenuItem key={event.id} value={event.id}>
                {event.eventName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={3}>
            {receivers.map((receiver) => {
              const parkName =
                parks[receiver.ParkID]?.ParkName || "Parque desconocido";
              const isStartTime = receiver.Type === "0";
              const iconUrl = isStartTime
                ? "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/receivericonos%2Fbandera-de-carreras.png?alt=media&token=c2cd9b0c-fd84-4214-9959-75d637db0a64"
                : "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/receivericonos%2Fpunto-de-partida.png?alt=media&token=621c68e1-4c07-4e1d-8f72-7bc154121fdc";
              const label = isStartTime ? "Inicio" : "Llegada";

              return (
                <Grid item xs={12} sm={6} md={4} key={receiver.id}>
                  <Card
                    sx={{
                      boxShadow: 3,
                      borderRadius: 4,
                      transition: "transform 0.2s",
                      "&:hover": { transform: "scale(1.03)" },
                    }}
                  >
                    <CardContent
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Badge
                        badgeContent="LateRuns"
                        color="secondary"
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        sx={{ width: "100%" }}
                      >
                        <img
                          src={iconUrl}
                          alt={label}
                          style={{ width: 50, height: 50 }}
                        />
                      </Badge>
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#37474f" }}
                      >
                        {label}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>UID:</strong> {receiver.id}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Parque:</strong> {parkName}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <strong>Pista:</strong> {receiver.TrackName || "N/A"}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                      <Button
                        startIcon={<SettingsIcon />}
                        variant="outlined"
                        color="primary"
                        onClick={() => navigate(`/receiver/${receiver.id}`)}
                        sx={{ fontWeight: "bold", color: "#1976d2" }}
                      >
                        Ver detalles
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              );
            })}
          </Grid>

          {/* Pass receiver data (UID, TrackName, and Type) to Merger */}
          <Merger receivers={receiverData} />
        </>
      )}
    </Container>
  );
};

export default MergeLateRuns;
