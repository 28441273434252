import React, { useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Link,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomEvents from "./CustomEvents";

// URL del logo y video de Firebase Storage
const FlowHuntersLogo =
  "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/flowhunters%2Fgeneral-logotipo-01.jpg?alt=media&token=6d2cc8b1-8e66-486e-a012-c65f21bb1cd4";
const VideoHeader =
  "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/flowhunters%2Fvideo.webm?alt=media&token=4e5a656a-9366-4dec-b5fa-42078a041d4d";

const requirements = [
  {
    item: "Bicicleta de montaña",
    description: "Bicicleta apta para terreno montañoso.",
  },
  { item: "Casco", description: "Protección obligatoria para seguridad." },
  {
    item: "Ropa deportiva",
    description: "Ropa cómoda y adecuada para ciclismo.",
  },
  {
    item: "Hidratación",
    description: "Botella de agua o sistema de hidratación.",
  },
  {
    item: "Rodilleras",
    description: "Protección adicional para las rodillas.",
  },
  { item: "Guantes", description: "Guantes para mejor agarre y protección." },
];

const FlowHuntersPage = () => {
  const [openRegisterDialog, setOpenRegisterDialog] = useState(false);

  const handleShare = () => {
    const shareData = {
      title: "Flow Hunters - Academia de Ciclismo de Montaña",
      text: "Únete a nuestras clínicas para mejorar tus habilidades de ciclismo de montaña.",
      url: window.location.href,
    };
    if (navigator.share) {
      navigator.share(shareData).catch(console.error);
    } else {
      navigator.clipboard.writeText(
        `${shareData.title}\n${shareData.text}\n${shareData.url}`
      );
      alert("¡Detalles copiados al portapapeles!");
    }
  };

  return (
    <Box sx={{ bgcolor: "black", minHeight: "100vh", color: "white" }}>
      {/* Logo en el Centro con Link */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={{ xs: "40vh", md: "50vh" }}
        flexDirection="column"
      >
        <Link href="/" underline="none">
          <img
            src={FlowHuntersLogo}
            alt="Flow Hunters"
            style={{ width: 150, marginBottom: 20 }}
          />
        </Link>
        <Typography variant="h3" sx={{ fontWeight: 700, textAlign: "center" }}>
          Flow Hunters
        </Typography>
        <Typography
          variant="h5"
          color="text.secondary"
          sx={{ mt: 1, textAlign: "center" }}
        >
          Academia de Ciclismo de Montaña
        </Typography>
      </Box>

      {/* Video Header como Link */}
      <Box
        sx={{
          position: "relative",
          height: { xs: "30vh", md: "60vh" },
          overflow: "hidden",
        }}
      >
        <Link href="https://www.example.com" target="_blank" underline="none">
          <video
            src={VideoHeader}
            autoPlay
            loop
            muted
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Link>
      </Box>
      <CustomEvents />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box mt={4} textAlign="center">
          <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
            Requisitos
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {requirements.map((req, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  bgcolor: "rgba(255, 255, 255, 0.1)",
                  p: 2,
                  borderRadius: 2,
                  textAlign: "left",
                }}
              >
                <CheckCircleIcon sx={{ color: "white", mr: 2 }} />
                <Box>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", color: "white" }}
                  >
                    {req.item}
                  </Typography>
                  <Typography variant="body2" color="gray">
                    {req.description}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Eventos personalizados */}

        {/* Botones de Acción */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={2}
          mt={4}
        >
          <Button
            onClick={handleShare}
            variant="outlined"
            size="large"
            sx={{
              borderColor: "white",
              color: "white",
              fontWeight: 700,
              "&:hover": {
                borderColor: "rgba(255, 255, 255, 0.8)",
                color: "rgba(255, 255, 255, 0.8)",
              },
            }}
          >
            Compartir
          </Button>
          <Button
            startIcon={<WhatsAppIcon />}
            variant="contained"
            size="large"
            sx={{
              bgcolor: "#25D366",
              color: "white",
              fontWeight: 700,
              "&:hover": {
                bgcolor: "#1ebe5d",
              },
            }}
            href="https://wa.me/523331603462?text=Hola!%20quiero%20mas%20informacion!"
            target="_blank"
          >
            ¡Hola! Quiero más información
          </Button>
        </Box>
      </Container>

      {/* Dialogo de Registro */}
      <RegisterDialog
        open={openRegisterDialog}
        onClose={() => setOpenRegisterDialog(false)}
      />
    </Box>
  );
};

const RegisterDialog = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Registro a Clínica de Ciclismo</DialogTitle>
    <DialogContent>
      <Typography>
        Regístrate para unirte a nuestras clínicas y mejorar tus habilidades en
        ciclismo de montaña.
      </Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => alert("Iniciar sesión")} color="primary">
        Iniciar sesión
      </Button>
      <Button onClick={() => alert("Crear cuenta")} color="primary">
        Crear cuenta
      </Button>
    </DialogActions>
  </Dialog>
);

export default FlowHuntersPage;
