import React, { useState } from "react";
import { Button, Box, CircularProgress, Typography } from "@mui/material";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

const UploadStory = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type.includes("video") ||
        selectedFile.type.includes("image"))
    ) {
      setFile(selectedFile);
      setError("");
    } else {
      setError("Selecciona un archivo de video o imagen válido.");
      setFile(null);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      setError("Debe estar autenticado para subir una historia.");
      return;
    }

    setUploading(true); // Mover esto aquí asegura que solo se active después de la autenticación

    try {
      const uid = user.uid;
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `stories/${uid}_${Date.now()}_${file.name}`
      );
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progressValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progressValue);
        },
        (error) => {
          setError("Error al subir el archivo. Inténtelo de nuevo.");
          setUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const db = getFirestore();
          const storiesCollection = collection(db, "stories");

          await addDoc(storiesCollection, {
            source: downloadURL,
            userId: uid,
            timestamp: serverTimestamp(),
          });

          setUploading(false);
          setFile(null);
          setProgress(0);
          alert("Historia subida con éxito!");
        }
      );
    } catch (error) {
      console.error("Error durante la subida:", error);
      setError("Error durante la subida. Por favor, inténtelo de nuevo.");
      setUploading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        p: 3,
      }}
    >
      <Typography variant="h5">Sube tu historia</Typography>
      <input type="file" accept="video/*,image/*" onChange={handleFileChange} />
      {error && <Typography color="error">{error}</Typography>}
      {file && (
        <Typography variant="body2" color="textSecondary">
          Archivo seleccionado: {file.name}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleUpload}
        disabled={uploading || !file}
      >
        {uploading ? "Subiendo..." : "Subir Historia"}
      </Button>
      {uploading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 2,
          }}
        >
          <CircularProgress variant="determinate" value={progress} />
          <Typography variant="body2" color="textSecondary">
            Progreso: {Math.round(progress)}%
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UploadStory;
