import React, { useState, useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Alert,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Grid,
  Chip,
  Avatar,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import ShareIcon from "@mui/icons-material/Share";
import CopyIcon from "@mui/icons-material/ContentCopy";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const MyGiftCards = () => {
  const { user, loading: authLoading } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [giftcards, setGiftcards] = useState([]);
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveCount, setInactiveCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGiftcard, setSelectedGiftcard] = useState(null);
  const [userName, setUserName] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterReview, setFilterReview] = useState("all");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchGiftcards = async () => {
      if (authLoading) return;
      if (!user) {
        setErrorMessage("Usuario no autenticado. Por favor, inicia sesión.");
        setLoading(false);
        return;
      }

      try {
        const userDocRef = doc(db, "Users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) setUserName(userDoc.data().name);

        let giftcardsQuery = collection(db, "Giftcards");

        const conditions = [];
        conditions.push(where("managerUID", "==", user.uid));

        if (filterStatus !== "all") {
          conditions.push(where("active", "==", filterStatus === "active"));
        }
        if (filterReview !== "all") {
          conditions.push(where("review", "==", filterReview === "reviewed"));
        }

        giftcardsQuery = query(giftcardsQuery, ...conditions);
        const giftcardsSnapshot = await getDocs(giftcardsQuery);

        let giftcardsData = [];
        let active = 0;
        let inactive = 0;

        giftcardsSnapshot.forEach((doc) => {
          const data = doc.data();
          giftcardsData.push({ id: doc.id, ...data });
          data.active ? active++ : inactive++;
        });

        setGiftcards(giftcardsData);
        setActiveCount(active);
        setInactiveCount(inactive);
      } catch (error) {
        console.error("Error al obtener las giftcards:", error);
        setErrorMessage(
          "Error al obtener las giftcards. Por favor, intente nuevamente."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchGiftcards();
  }, [user, authLoading, filterStatus, filterReview]);

  const handleCardClick = (giftcard) => {
    setSelectedGiftcard(giftcard);
    setOpenDialog(true);
  };

  const handleDialogClose = (agree) => {
    setOpenDialog(false);
    if (agree && selectedGiftcard) {
      const shareUrl = `${window.location.origin}/giftcard/${selectedGiftcard.id}`;
      const shareMessage = `${userName} te ha enviado ${selectedGiftcard.amount} SpeedCoins! Canjea tu regalo ahora! ${shareUrl}`;
      navigator.clipboard.writeText(shareMessage);
      alert("Enlace copiado al portapapeles");
    }
  };

  const handleShareWhatsApp = () => {
    const shareUrl = `${window.location.origin}/giftcard/${selectedGiftcard.id}`;
    const shareMessage = `${userName} te ha enviado ${selectedGiftcard.amount} SpeedCoins! Canjea tu regalo ahora: ${shareUrl}`;
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      shareMessage
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  if (authLoading || loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (errorMessage) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 4, mt: 5 }}>
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          color="primary"
          sx={{ fontSize: isMobile ? "1.3rem" : "1.8rem", mt: 3 }}
        >
          Mis GiftCards de SpeedCoins
        </Typography>
        <Box display="flex" justifyContent="space-between" my={3}>
          <Chip label={`Activas: ${activeCount}`} color="success" />
          <Chip label={`Inactivas: ${inactiveCount}`} color="default" />
        </Box>
        <Box display="flex" justifyContent="space-around" mb={3}>
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel>Estado</InputLabel>
            <Select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              label="Estado"
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="active">Activas</MenuItem>
              <MenuItem value="inactive">Inactivas</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant="outlined" sx={{ minWidth: 120 }}>
            <InputLabel>Review</InputLabel>
            <Select
              value={filterReview}
              onChange={(e) => setFilterReview(e.target.value)}
              label="Review"
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="reviewed">Con Review</MenuItem>
              <MenuItem value="notReviewed">Sin Review</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {giftcards.map((giftcard) => (
            <Grid item xs={12} sm={6} md={4} key={giftcard.id}>
              <Card
                onClick={() => handleCardClick(giftcard)}
                sx={{
                  backgroundColor: giftcard.active ? green[50] : grey[100],
                  borderRadius: 3,
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s",
                  cursor: "pointer",
                  "&:hover": {
                    transform: "scale(1.02)",
                  },
                }}
              >
                <CardContent sx={{ p: 2 }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={1}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      color="primary"
                    >
                      GiftCard #{giftcard.id.slice(-6)}
                    </Typography>
                    <Chip
                      label={giftcard.active ? "Activa" : "Inactiva"}
                      color={giftcard.active ? "success" : "default"}
                      size="small"
                      sx={{ fontWeight: 500 }}
                    />
                  </Box>
                  <Box display="flex" alignItems="center" mb={1}>
                    <Avatar
                      src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedcoins%2Fmario-coins.gif?alt=media&token=977b8311-54c5-4d38-930e-12cb1ce7739e"
                      sx={{ width: 28, height: 28, mr: 1 }}
                    />
                    <Typography variant="body2" color="textSecondary">
                      {giftcard.amount} SpeedCoins
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="textSecondary">
                    Creado: {giftcard.timestamp.toDate().toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <Dialog
        open={openDialog}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Advertencia"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Este cupón puede ser canjeado por cualquier usuario, Livespeed no se
            hace responsable del uso que se le dé.
          </DialogContentText>
          <Box mt={2}>
            <Typography variant="body2">Enlace del cupón:</Typography>
            <Box
              display="flex"
              alignItems="center"
              mt={1}
              p={1}
              bgcolor={grey[200]}
              borderRadius={2}
            >
              <Typography variant="body2" sx={{ flexGrow: 1 }}>
                {`${window.location.origin}/giftcard/${selectedGiftcard?.id}`}
              </Typography>
              <IconButton
                color="primary"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/giftcard/${selectedGiftcard?.id}`
                  );
                  alert("Enlace copiado al portapapeles");
                }}
              >
                <CopyIcon />
              </IconButton>
              <IconButton color="primary" onClick={handleShareWhatsApp}>
                <WhatsAppIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => handleDialogClose(true)}
            color="primary"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default MyGiftCards;
