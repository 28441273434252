import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  CircularProgress,
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
} from "@mui/material";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";
import { db } from "../../firebase";

// Helper function to format Firestore timestamps
const formatTimestamp = (timestamp) => {
  if (timestamp && timestamp.seconds) {
    return new Date(timestamp.seconds * 1000).toLocaleString("es-MX", {
      timeZone: "America/Mexico_City",
    });
  }
  return "Fecha desconocida";
};

const Merger = ({ receivers }) => {
  const [loading, setLoading] = useState(true);
  const [lateRuns, setLateRuns] = useState([]);
  const [selectedReceiver, setSelectedReceiver] = useState("");
  const [selectedRuns, setSelectedRuns] = useState([]);

  function ajustarTiempoSalida(fechaIncorrecta) {
    // Convertir la fecha incorrecta de salida en un objeto Date
    let salidaIncorrecta = new Date(fechaIncorrecta);

    // Aplicar el desplazamiento basado en el ejemplo (24 años, 9 meses, 25 días, 8 horas, 41 minutos, 2 segundos)
    salidaIncorrecta.setFullYear(salidaIncorrecta.getFullYear() + 24);
    salidaIncorrecta.setMonth(salidaIncorrecta.getMonth() + 9);
    salidaIncorrecta.setDate(salidaIncorrecta.getDate() + 25);
    salidaIncorrecta.setHours(salidaIncorrecta.getHours() + 8);
    salidaIncorrecta.setMinutes(salidaIncorrecta.getMinutes() + 41);
    salidaIncorrecta.setSeconds(salidaIncorrecta.getSeconds() + 2);

    // Devolver la fecha corregida
    return salidaIncorrecta;
  }

  // Fetch LateRuns for the selected receiver and sort them by time
  const fetchLateRunsForReceiver = async (receiverId) => {
    setLoading(true);
    try {
      const receiverData = receivers.find((r) => r.id === receiverId);
      const lateRunsCollection = await getDocs(
        collection(db, `Receivers/${receiverId}/LateRuns`)
      );
      const runsData = lateRunsCollection.docs.map((doc) => ({
        id: doc.id,
        RUID: receiverData?.ReceiverName || receiverId,
        time: doc.data().time,
        uid: doc.data().uid,
      }));

      // Sort runs by time (ascending order)
      const sortedRuns = runsData.sort((a, b) => a.time.localeCompare(b.time));
      setLateRuns(sortedRuns);
    } catch (error) {
      console.error("Error fetching LateRuns:", error);
    } finally {
      setLoading(false);
    }
  };

  // Adjust all dates for the specified receiver and update in Firestore
  const adjustAllDatesForReceiver = async () => {
    setLoading(true);
    try {
      const adjustedRunsPromises = lateRuns.map(async (run) => {
        const adjustedTime = ajustarTiempoSalida(run.time);

        // Update document in Firestore with the corrected date
        const runDocRef = doc(
          db,
          `Receivers/${selectedReceiver}/LateRuns`,
          run.id
        );
        await updateDoc(runDocRef, {
          time: adjustedTime.toISOString(), // Save the adjusted date in ISO format
        });

        return { ...run, time: adjustedTime.toISOString() };
      });

      // Wait for all updates to complete
      const adjustedRuns = await Promise.all(adjustedRunsPromises);
      setLateRuns(adjustedRuns);
      alert("Todas las fechas de LateRuns han sido actualizadas en Firestore.");
    } catch (error) {
      console.error("Error al actualizar las fechas en Firestore:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handler for receiver selection
  const handleReceiverChange = (e) => {
    const receiverId = e.target.value;
    setSelectedReceiver(receiverId);
    fetchLateRunsForReceiver(receiverId);
    setSelectedRuns([]); // Clear selection on receiver change
  };

  // Send individual run data to the API
  const sendRunToApi = async (run) => {
    console.log("Sending run to API with data:", {
      RUID: run.RUID,
      time: run.time,
      uid: run.uid,
    });
    try {
      const response = await axios.post(
        "https://logtofirestore-aetu2mr6pq-uc.a.run.app/logToFirestore",
        {
          RUID: run.RUID,
          time: run.time,
          uid: run.uid,
        }
      );
      console.log(`API Response: ${response.data.message}`);
    } catch (error) {
      console.error("Error sending run to API:", error);
      alert("Error sending run to API");
    }
  };

  // Send all selected runs to the API with a delay
  const sendSelectedRunsToApi = async () => {
    for (const runId of selectedRuns) {
      const run = lateRuns.find((r) => r.id === runId);
      if (run) {
        await sendRunToApi(run); // Await each API call
        await new Promise((resolve) => setTimeout(resolve, 1500)); // 1.5-second delay
      }
    }
    alert("All selected LateRuns have been sent to the API.");
  };

  // Select/Deselect a run
  const handleSelectRun = (runId) => {
    setSelectedRuns((prevSelected) =>
      prevSelected.includes(runId)
        ? prevSelected.filter((id) => id !== runId)
        : [...prevSelected, runId]
    );
  };

  // Select or Deselect all runs
  const handleSelectAllRuns = () => {
    if (selectedRuns.length === lateRuns.length) {
      setSelectedRuns([]); // Deselect all
    } else {
      setSelectedRuns(lateRuns.map((run) => run.id)); // Select all
    }
  };

  // Delete selected LateRuns from Firebase
  const deleteSelectedRuns = async () => {
    if (selectedRuns.length === 0) {
      alert("No runs selected for deletion.");
      return;
    }
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${selectedRuns.length} selected LateRuns?`
    );
    if (confirmDelete) {
      try {
        await Promise.all(
          selectedRuns.map(async (runId) => {
            const runDoc = doc(
              db,
              `Receivers/${selectedReceiver}/LateRuns`,
              runId
            );
            await deleteDoc(runDoc);
          })
        );
        alert("Selected LateRuns have been deleted.");
        setLateRuns((prevRuns) =>
          prevRuns.filter((run) => !selectedRuns.includes(run.id))
        );
        setSelectedRuns([]);
      } catch (error) {
        console.error("Error deleting selected LateRuns:", error);
      }
    }
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Typography
        variant="h5"
        align="center"
        sx={{ fontWeight: "bold", color: "#37474f", mb: 2 }}
      >
        Detalles de LateRuns
      </Typography>

      <Box display="flex" justifyContent="center" gap={2} mb={3}>
        <FormControl sx={{ minWidth: 300 }}>
          <InputLabel>Selecciona Receiver</InputLabel>
          <Select value={selectedReceiver} onChange={handleReceiverChange}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {receivers.map((receiver) => (
              <MenuItem key={receiver.id} value={receiver.id}>
                {receiver.id} - {receiver.TrackName || "Track desconocido"}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box display="flex" justifyContent="center" gap={2} mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={sendSelectedRunsToApi}
          disabled={selectedRuns.length === 0}
        >
          Send Selected Runs to API
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={deleteSelectedRuns}
          disabled={selectedRuns.length === 0}
        >
          Delete Selected Runs
        </Button>
      </Box>

      {loading ? (
        <Box display="flex" justifyContent="center" p={2}>
          <CircularProgress />
        </Box>
      ) : lateRuns.length > 0 ? (
        <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    checked={selectedRuns.length === lateRuns.length}
                    indeterminate={
                      selectedRuns.length > 0 &&
                      selectedRuns.length < lateRuns.length
                    }
                    onChange={handleSelectAllRuns}
                  />
                  Select All
                </TableCell>
                <TableCell align="center">RUID</TableCell>
                <TableCell align="center">Time</TableCell>
                <TableCell align="center">UID</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {lateRuns.map((run) => (
                <TableRow key={run.id}>
                  <TableCell align="center">
                    <Checkbox
                      checked={selectedRuns.includes(run.id)}
                      onChange={() => handleSelectRun(run.id)}
                    />
                  </TableCell>
                  <TableCell align="center">{run.RUID}</TableCell>
                  <TableCell align="center">{run.time}</TableCell>
                  <TableCell align="center">{run.uid}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => sendRunToApi(run)}
                    >
                      Send to API
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {selectedReceiver === "xFmCCLNG6gIvJFk8jaBe" ? (
            <Button onClick={adjustAllDatesForReceiver}>
              cambiar fechas de stage 1 salida
            </Button>
          ) : null}
        </TableContainer>
      ) : (
        <Box display="flex" justifyContent="center" p={2}>
          <Typography variant="body1" color="textSecondary">
            No hay LateRuns disponibles para mostrar.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Merger;
