import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useUserAuth } from "../../context/userAuthContext";
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import { Button, Box, Typography, CircularProgress } from "@mui/material";
import { styled, keyframes } from "@mui/system";

// Animación flotante
const floatAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const FloatingMessage = styled(Box)({
  position: "fixed",
  bottom: "10%",
  right: "5%",
  backgroundColor: "#fff",
  borderRadius: "16px", // Borde redondeado
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  textAlign: "center",
  width: "300px",
  height: "200px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  color: "#000",
  animation: `${floatAnimation} 3s ease-in-out infinite`, // Animación flotante
});

const TagCheck = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [userTags, setUserTags] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user && user.uid) {
      const firestore = getFirestore();
      const q = query(
        collection(firestore, "Tags"),
        where("Owner", "==", user.uid)
      );

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const tags = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserTags(tags);
        setLoading(false);
      });

      return () => unsubscribe();
    }
  }, [user]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Si el usuario ya tiene un tag, no mostrar el mensaje
  if (userTags.length > 0) {
    return null;
  }

  // Si el usuario no tiene un tag, mostrar el mensaje y el botón
  return (
    <FloatingMessage>
      <Typography variant="h6">
        No tienes una pulsera agregada a tu cuenta!
      </Typography>
      <Button
        variant="contained"
        sx={{
          mt: 2,
          backgroundColor: "#000", // Botón negro
          "&:hover": {
            backgroundColor: "#333",
          },
        }}
        onClick={() => navigate("/MyTags")}
      >
        Agregar Tag
      </Button>
    </FloatingMessage>
  );
};

export default TagCheck;
