import React from "react";
import { useNavigate } from "react-router-dom";
import { ShoppingCart, Eye, Tag, Box, DollarSign } from "lucide-react";

const ProductList = ({ products, onAddToCart }) => {
  const navigate = useNavigate();

  const handleViewProduct = (productId) => {
    navigate(`/shop/product/${productId}`);
  };

  return (
    <div className="flex-1 bg-white p-4 rounded shadow-md">
      <h2 className="text-xl font-semibold mb-4 flex items-center">
        <Box className="mr-2" />
        Products
      </h2>
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {products.map((product) => (
          <div
            key={product.id}
            onClick={() => handleViewProduct(product.id)}
            className="border rounded-lg p-4 flex flex-col items-center shadow hover:shadow-lg transition cursor-pointer max-w-xs mx-auto sm:max-w-none"
          >
            {product.images && product.images.length > 0 && (
              <img
                src={product.images[0]}
                alt={product.title}
                className="h-32 w-full object-cover rounded mb-4"
              />
            )}
            <h3 className="text-lg font-semibold text-center">
              {product.title}
            </h3>

            {/* Product Details with Icons */}
            <div className="flex flex-col items-center text-gray-600 mt-2 space-y-1 text-sm">
              <p className="flex items-center">
                <Tag className="w-4 h-4 mr-1" /> Brand: {product.brand}
              </p>
              <p className="flex items-center">
                <Box className="w-4 h-4 mr-1" /> Model: {product.model}
              </p>
              <p className="flex items-center text-gray-800 font-bold mt-2 text-base">
                <DollarSign className="w-4 h-4 mr-1" /> ${product.cost}
              </p>
              <p className="flex items-center text-sm mt-1">
                <Box className="w-4 h-4 mr-1" /> Stock: {product.stock}
              </p>
            </div>

            {/* Buttons with Icons */}
            <div className="flex flex-col sm:flex-row gap-2 mt-4 w-full">
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevents triggering the navigate
                  onAddToCart(product);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded flex items-center justify-center gap-1 hover:bg-green-600 transition w-full"
              >
                <ShoppingCart className="w-4 h-4" /> Add to Cart
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation(); // Prevents triggering the navigate
                  handleViewProduct(product.id);
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded flex items-center justify-center gap-1 hover:bg-blue-600 transition w-full"
              >
                <Eye className="w-4 h-4" /> View Product
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductList;
