import React, { useState, useRef, useEffect } from "react";
import { Avatar, Dialog, DialogTitle, DialogContent } from "@mui/material";
import { X } from "lucide-react";
import { getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUserAuth } from "../../context/userAuthContext";

const StoryViewer = ({ stories, initialIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isSwipingDown, setIsSwipingDown] = useState(false);
  const [touchStartY, setTouchStartY] = useState(0);
  const [viewers, setViewers] = useState([]); // Lista de espectadores
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado del modal de viewers
  const videoRef = useRef(null); // Referencia al video
  const { user } = useUserAuth();

  const currentStory = stories[currentIndex];

  const isVideoFile = (url = "") => {
    const videoPatterns = [".mp4", ".mov", ".webm", ".ogg"];
    return videoPatterns.some((pattern) => url.toLowerCase().includes(pattern));
  };

  useEffect(() => {
    if (currentStory && currentStory.views) {
      fetchViewers();
    }
  }, [currentStory]);

  const fetchViewers = async () => {
    const viewersData = await Promise.all(
      currentStory.views.map(async (uid) => {
        const userDoc = await getDoc(doc(db, "Users", uid));
        return userDoc.exists() ? userDoc.data() : null;
      })
    );
    setViewers(viewersData.filter((viewer) => viewer !== null));
  };

  const handleNextStory = () => {
    if (currentIndex < stories.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevStory = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleTouchStart = (e) => {
    setTouchStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    const touchEndY = e.touches[0].clientY;
    if (touchEndY - touchStartY > 50) {
      setIsSwipingDown(true);
    }
  };

  const handleTouchEnd = () => {
    if (isSwipingDown) {
      onClose();
    }
    setIsSwipingDown(false);
  };

  const handleMouseDown = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleMouseUp = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleClickNavigation = (e) => {
    const clickPositionX = e.clientX;
    const screenWidth = window.innerWidth;

    if (clickPositionX < screenWidth / 2) {
      handlePrevStory(); // Ir a la historia anterior si clic en la izquierda
    } else {
      handleNextStory(); // Ir a la siguiente historia si clic en la derecha
    }
  };

  // Abre el modal de viewers
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Cierra el modal de viewers
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-90 flex flex-col items-center justify-center"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* Botón de cerrar */}
      <button onClick={onClose} className="absolute top-4 right-4 text-white">
        <X className="w-6 h-6" />
      </button>

      {/* Header con Avatar y Nombre */}
      <div className="absolute top-4 left-4 flex items-center bg-black bg-opacity-50 px-3 py-2 rounded-lg">
        <Avatar
          src={currentStory.photoUrl}
          alt={currentStory.nickname}
          className="w-8 h-8 mr-2"
        />
        <div>
          <p className="text-white font-semibold">{currentStory.nickname}</p>
          <p className="text-gray-300 text-xs">
            {currentStory.formattedTimestamp}
          </p>
        </div>
      </div>

      {/* Contenido de la historia */}
      <div
        className="w-full max-w-md h-[70vh] flex items-center justify-center"
        onClick={handleClickNavigation} // Navegación con clic izquierdo/derecho
        onMouseDown={handleMouseDown} // Pausa el video al mantener presionado
        onMouseUp={handleMouseUp} // Reanuda el video al soltar
      >
        {isVideoFile(currentStory.source) ? (
          <video
            ref={videoRef}
            src={currentStory.source}
            autoPlay
            playsInline
            className="w-full h-full rounded-lg object-cover"
          />
        ) : (
          <img
            src={currentStory.source}
            alt="story"
            className="w-full h-full rounded-lg object-cover"
          />
        )}
      </div>

      {/* Botón "Views" para ver espectadores, solo si el usuario es el creador */}
      {user?.uid === currentStory.userId && (
        <button
          onClick={handleOpenModal}
          className="mt-4 text-white text-sm bg-gray-700 bg-opacity-50 px-4 py-2 rounded-lg hover:bg-opacity-75"
        >
          Views
        </button>
      )}

      {/* Modal de viewers */}
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Viewers</DialogTitle>
        <DialogContent>
          <div className="flex flex-col gap-3">
            {viewers.map((viewer, index) => (
              <div key={index} className="flex items-center gap-2">
                <Avatar
                  src={viewer.photoUrl}
                  alt={viewer.name}
                  className="w-10 h-10"
                />
                <p className="text-sm">{viewer.name}</p>
              </div>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StoryViewer;
