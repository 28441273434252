import React, { useEffect, useState } from "react";
import {
  doc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import Flag from "react-world-flags";
import { CircularProgress } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Helper function for country codes
const getCountryCode = (country) => {
  const countryCodes = {
    Mexico: "MEX",
  };
  return countryCodes[country] || null;
};

const ProfileStats = () => {
  const { uid } = useParams();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [profileUser, setProfileUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ridesCount, setRidesCount] = useState(0);
  const [friendsCount, setFriendsCount] = useState(0);
  const [friendRequestSent, setFriendRequestSent] = useState(false);
  const [isFriend, setIsFriend] = useState(false);
  const [showLoginWarning, setShowLoginWarning] = useState(false);

  useEffect(() => {
    const unsubscribe = subscribeToUser(uid);
    return () => unsubscribe();
  }, [uid, user?.uid]);

  const subscribeToUser = (userId) => {
    const userRef = doc(db, "Users", userId);
    return onSnapshot(userRef, (userSnap) => {
      if (userSnap.exists()) {
        const userData = userSnap.data();
        setProfileUser(userData);
        setFriendsCount(userData.friends?.length || 0);
        setFriendRequestSent(
          userData.friendRequests?.includes(user?.uid) || false
        );
        setIsFriend(userData.friends?.includes(user?.uid) || false);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    const fetchRidesCount = async () => {
      const ridesRef = collection(db, "Rides");
      const q = query(ridesRef, where("riders", "array-contains", uid));
      const querySnapshot = await getDocs(q);
      setRidesCount(querySnapshot.size);
    };
    fetchRidesCount();
  }, [uid]);

  const handleAddFriend = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        await updateDoc(userRef, {
          friendRequests: arrayUnion(user.uid),
        });
        setFriendRequestSent(true);
      } catch (error) {
        console.error("Error adding friend: ", error);
      }
    }
  };

  const handleCancelFriendRequest = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        await updateDoc(userRef, {
          friendRequests: arrayRemove(user.uid),
        });
        setFriendRequestSent(false);
      } catch (error) {
        console.error("Error cancelling friend request: ", error);
      }
    }
  };

  const handleFollow = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        const currentUserRef = doc(db, "Users", user.uid);
        await updateDoc(userRef, {
          friends: arrayUnion(user.uid),
        });
        await updateDoc(currentUserRef, {
          friends: arrayUnion(uid),
        });
        setIsFriend(true);
        setFriendRequestSent(false);
      } catch (error) {
        console.error("Error following user: ", error);
      }
    }
  };

  const handleUnfollow = async () => {
    if (profileUser && user?.uid) {
      try {
        const userRef = doc(db, "Users", uid);
        const currentUserRef = doc(db, "Users", user.uid);
        await updateDoc(userRef, {
          friends: arrayRemove(user.uid),
        });
        await updateDoc(currentUserRef, {
          friends: arrayRemove(uid),
        });
        setIsFriend(false);
      } catch (error) {
        console.error("Error unfollowing user: ", error);
      }
    }
  };

  const handleSettingsClick = () => {
    navigate("/addProfile");
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <CircularProgress />
      </div>
    );
  }

  if (!profileUser) {
    return (
      <div className="text-center py-8">
        <p className="text-2xl font-bold">User not found</p>
      </div>
    );
  }

  const countryCode = getCountryCode(profileUser.country);

  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded-lg shadow-lg mt-6">
      <div className="flex items-center mb-4">
        <ArrowBackIcon
          className="cursor-pointer mr-2"
          onClick={() => navigate(-1)}
        />
        <h2 className="text-lg font-bold">Back</h2>
      </div>

      <div className="flex items-center">
        <img
          src={profileUser.photoUrl}
          alt={profileUser.name}
          className="w-24 h-24 rounded-full border-2 border-gray-300"
        />
        <div className="flex flex-col items-center text-center ml-6">
          <div className="flex space-x-6">
            <div>
              <span className="font-bold text-lg">{ridesCount}</span>
              <p className="text-sm text-gray-600">Rides</p>
            </div>
            <div
              onClick={() => navigate(`/myfriends/${uid}`)}
              className="cursor-pointer"
            >
              <span className="font-bold text-lg">{friendsCount}</span>
              <p className="text-sm text-gray-600">Followers</p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center mt-4">
        <p className="text-lg font-semibold">{profileUser.name}</p>
        <p className="text-sm text-gray-500">@{profileUser.nickName}</p>
      </div>

      {countryCode && (
        <div className="flex flex-col items-center mt-2">
          <Flag code={countryCode} height="20" className="mb-1" />
          <span className="text-sm text-gray-600 font-medium">
            {profileUser.country}
          </span>
          <span className="text-gray-500 text-sm">
            {profileUser.city}, {profileUser.state}
          </span>
        </div>
      )}

      <div className="mt-4 flex justify-center">
        {user?.uid === uid ? (
          <button
            onClick={handleSettingsClick}
            className="py-2 px-4 w-2/3 text-center text-sm font-semibold text-black bg-gray-200 rounded-md flex justify-center items-center"
          >
            <SettingsIcon className="mr-2" /> Edit Profile
          </button>
        ) : isFriend ? (
          <button
            onClick={handleUnfollow}
            className="py-2 px-4 w-2/3 text-sm font-semibold text-white bg-red-500 rounded-md"
          >
            Unfollow
          </button>
        ) : friendRequestSent ? (
          <button
            onClick={handleCancelFriendRequest}
            className="py-2 px-4 w-2/3 text-sm font-semibold text-black bg-gray-200 rounded-md"
          >
            Cancel Request
          </button>
        ) : (
          <button
            onClick={() => {
              if (user) {
                handleAddFriend();
              } else {
                setShowLoginWarning(true);
              }
            }}
            className="py-2 px-4 w-2/3 text-sm font-semibold text-white bg-blue-500 rounded-md"
          >
            Follow
          </button>
        )}
      </div>
    </div>
  );
};

export default ProfileStats;
