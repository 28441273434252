import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Typography, Button, CircularProgress } from "@mui/material";
import { convertArrayToCSV } from "convert-array-to-csv";

const DownloadRunsCSV = () => {
  const [runsData, setRunsData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchRuns = async () => {
      setLoading(true);
      try {
        const runsCollection = collection(db, "Runs");
        const runsSnapshot = await getDocs(runsCollection);
        const runsList = runsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRunsData(runsList);
      } catch (error) {
        console.error("Error fetching runs: ", error);
      }
      setLoading(false);
    };

    fetchRuns();
  }, []);

  const downloadCSV = () => {
    if (runsData.length === 0) {
      alert("No hay datos para descargar.");
      return;
    }

    const csvData = convertArrayToCSV(runsData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "runs_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Descargar Runs en CSV
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Button variant="contained" color="primary" onClick={downloadCSV}>
          Descargar CSV
        </Button>
      )}
    </div>
  );
};

export default DownloadRunsCSV;
