import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Paper,
  useTheme,
  CircularProgress,
  Slide,
} from "@mui/material";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import { useUserAuth } from "../../context/userAuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { Navigate } from "react-router-dom";
import UserForm from "./UserForm";
import Upgrades from "./Upgrades";

const NewUser = () => {
  const theme = useTheme();
  const { user } = useUserAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showUpgrades, setShowUpgrades] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const checkUserProfile = async () => {
      try {
        if (!user) {
          setLoading(false);
          return;
        }

        const userRef = doc(db, "Users", user.uid);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          const userData = userSnap.data();
          setUserInfo(userData);

          // Si el perfil ya está completo, redirigir a home
          if (userData.name && userData.nickName) {
            return <Navigate to="/home" />;
          }
        }
      } catch (error) {
        console.error("Error checking user profile:", error);
      } finally {
        setLoading(false);
      }
    };

    checkUserProfile();
  }, [user]);

  const handleCompleteProfile = () => {
    setShowForm(true);
    setTimeout(
      () => formRef.current?.scrollIntoView({ behavior: "smooth" }),
      300
    );
  };

  const handleFormComplete = () => {
    setShowForm(false);
    setShowUpgrades(true);
    setTimeout(
      () => formRef.current?.scrollIntoView({ behavior: "smooth" }),
      300
    );
  };

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        px: { xs: 2, sm: 4 },
        pt: { xs: 8, sm: 10 },
        pb: 4,
      }}
    >
      <Paper
        elevation={6}
        sx={{
          p: { xs: 3, sm: 4 },
          borderRadius: 3,
          bgcolor: "background.paper",
          width: "100%",
          maxWidth: "600px",
          mb: 4,
        }}
      >
        <Avatar
          sx={{
            width: 120,
            height: 120,
            mb: 2,
            bgcolor: theme.palette.primary.main,
            mx: "auto",
          }}
        >
          <DirectionsBikeIcon sx={{ fontSize: 80 }} />
        </Avatar>

        <WelcomeMessage userInfo={userInfo} />

        <Button
          variant="contained"
          onClick={handleCompleteProfile}
          sx={{
            borderRadius: 2,
            px: 3,
            py: 1.5,
            fontSize: "1.2rem",
            textTransform: "none",
            bgcolor: "black",
            "&:hover": {
              bgcolor: "#333",
            },
          }}
        >
          {userInfo ? "Actualizar Perfil" : "Completar Perfil"}
        </Button>
      </Paper>

      <FormSlide
        show={showForm}
        formRef={formRef}
        onComplete={handleFormComplete}
      />

      <UpgradesSlide show={showUpgrades} formRef={formRef} />
    </Box>
  );
};

const WelcomeMessage = ({ userInfo }) => (
  <>
    <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
      ¡Bienvenido a Livespeed.mx!
    </Typography>

    {userInfo && userInfo.photoUrl && (
      <Avatar
        src={userInfo.photoUrl}
        alt={userInfo.name}
        sx={{
          width: 120,
          height: 120,
          mb: 2,
          mx: "auto",
        }}
      />
    )}

    <Typography variant="body1" sx={{ mb: 3 }}>
      Para poder continuar necesitas completar tu perfil!
    </Typography>
  </>
);

const FormSlide = ({ show, formRef, onComplete }) => (
  <Slide direction="up" in={show} mountOnEnter unmountOnExit>
    <Box
      ref={formRef}
      sx={{ width: "100%", textAlign: "center", pb: 4, mt: 4 }}
    >
      <UserForm onComplete={onComplete} />
    </Box>
  </Slide>
);

const UpgradesSlide = ({ show, formRef }) => (
  <Slide direction="up" in={show} mountOnEnter unmountOnExit>
    <Box
      ref={formRef}
      sx={{ width: "100%", textAlign: "center", pb: 4, mt: 4 }}
    >
      <Upgrades />
    </Box>
  </Slide>
);

export default NewUser;
