import React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import { AccessTime, Nfc, Wifi, Bluetooth, Storage } from "@mui/icons-material";

const Receiver = () => {
  const features = [
    {
      icon: <AccessTime sx={{ mr: 1, color: "#ffffff", fontSize: "1.5rem" }} />,
      text: "Cronometraje en tiempo real",
    },
    {
      icon: <Nfc sx={{ mr: 1, color: "#ffffff", fontSize: "1.5rem" }} />,
      text: "NFC",
    },
    {
      icon: <Wifi sx={{ mr: 1, color: "#ffffff", fontSize: "1.5rem" }} />,
      text: "Wifi",
    },
    {
      icon: <Bluetooth sx={{ mr: 1, color: "#ffffff", fontSize: "1.5rem" }} />,
      text: "Bluetooth",
    },
    {
      icon: <Storage sx={{ mr: 1, color: "#ffffff", fontSize: "1.5rem" }} />,
      text: "Redundancia (base de datos en línea y local)",
    },
  ];

  return (
    <Card
      sx={{
        backgroundColor: "#1c1c1c", // Fondo ligeramente más claro para contraste
        color: "#ffffff",
        borderRadius: 3,
        boxShadow: "0 12px 24px rgba(0, 0, 0, 0.5)",
        padding: "2rem",
        maxWidth: "1000px", // Mayor anchura para mejor presentación
        margin: "2rem auto",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        {/* Imagen del Receiver */}
        <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
          <Box
            component="img"
            src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/receiver.png?alt=media&token=30c48dd5-bc18-4650-9175-fa74855b0a08"
            alt="Receiver"
            sx={{
              width: "80%", // Tamaño reducido para que no ocupe demasiado espacio
              height: "auto",
              borderRadius: "12px",
              boxShadow: "0 6px 16px rgba(255, 255, 255, 0.2)",
            }}
          />
        </Grid>

        {/* Características del Receiver */}
        <Grid item xs={12} md={6}>
          <CardContent>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: "bold",
                color: "#4caf50", // Color destacado para el título
                textAlign: { xs: "center", md: "left" }, // Centrar en pantallas pequeñas
              }}
            >
              Receiver
            </Typography>
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: "#f0f0f0",
                textAlign: { xs: "center", md: "left" }, // Centrar en pantallas pequeñas
              }}
            >
              Características del producto
            </Typography>
            <Box
              sx={{
                marginTop: "1.5rem",
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "flex-start" }, // Centrados en móviles, alineados a la izquierda en escritorio
              }}
            >
              {features.map((feature, idx) => (
                <Typography
                  key={idx}
                  variant="body1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                    fontSize: "1.2rem", // Aumentar tamaño del texto
                  }}
                >
                  {feature.icon} {feature.text}
                </Typography>
              ))}
            </Box>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Receiver;
