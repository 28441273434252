import React, { useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EventAddons from "./EventAddons";
import FormInput from "../Forms/FormInput";
import toast from "react-hot-toast";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { eventsSchema } from "./EventFormSchemas";
import { eventsApi } from "../../lib/client";
import { useNavigate } from "react-router-dom";

function EventsForm({ creatorUID, event }) {
  const navigate = useNavigate();
  const [addLocation, setAddLocation] = useState(false);

  // location: { lat: 29.110262, lng: -110.940463 },
  const initialValues = {
    creatorUID: event ? event.creatorUID : creatorUID,
    eventName: event ? event.eventName : "",
    description: event ? event.description : "",
    dateTime: event ? event.dateTime : "",
    price: event ? event.price : "",
    location: event ? event.location : undefined,
    photo: event ? event.photo : "",
    categories: event ? event.categories : [],
    addons: event ? event.addons : [],
  };

  const handleSubmit = async (values) => {
    try {
      if (event) {
        await eventsApi.updateEvent(event.id, values);
      } else {
        await eventsApi.createEvent(values);
      }
      toast.success(`Evento ${event ? "actualizado" : "creado"} con éxito`);
      navigate("/my-events");
    } catch (error) {
      toast.error(
        `Ocurrió un error al ${event ? "actualizar" : "crear"} el evento`
      );
      console.error(error);
    }
  };

  const handleAddLocation = (event, setFieldValue) => {
    setAddLocation(event.target.checked);
    if (event.target.checked) {
      setFieldValue("location", { lat: 29.110262, lng: -110.940463 });
    } else {
      setFieldValue("location", undefined);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={eventsSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, isValid, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              {/* Foto del Evento */}
              <Grid item xs={12}>
                <Box sx={{ bgcolor: "#F1F1F1", borderRadius: 2, p: 2 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <InputLabel htmlFor="photo">
                      Foto/Flyer del Evento
                    </InputLabel>
                    <input
                      type="file"
                      accept="image/*"
                      id="photo"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        setFieldValue("photo", e.target.files[0])
                      }
                    />
                    <label htmlFor="photo">
                      <IconButton color="primary" component="span">
                        <PhotoCameraIcon />
                      </IconButton>
                    </label>
                  </Stack>
                  {values.photo && (
                    <Stack spacing={1}>
                      <img
                        src={
                          event
                            ? values.photo
                            : URL.createObjectURL(values.photo)
                        }
                        alt="Vista previa"
                        style={{
                          // marginTop: "10px",
                          maxHeight: 300,
                          objectFit: "contain",
                        }}
                      />
                      <Button
                        fullWidth
                        variant="outlined"
                        color="warning"
                        onClick={() => {
                          setFieldValue("photo", "");
                        }}
                      >
                        Borrar foto
                      </Button>
                    </Stack>
                  )}
                </Box>
              </Grid>
              {/* Nombre del Evento */}
              <Grid item xs={12}>
                <FormInput
                  fullWidth
                  name="eventName"
                  label="Nombre del evento"
                />
              </Grid>
              {/* Descripción */}
              <Grid item xs={12}>
                <FormInput
                  fullWidth
                  label="Descripción"
                  name="description"
                  multiline
                  rows={4}
                />
              </Grid>
              {/* Fecha y Hora */}
              <Grid item xs={12}>
                <FormInput
                  fullWidth
                  label="Fecha y Hora"
                  type="datetime-local"
                  name="dateTime"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              {/* Precio */}
              <Grid item xs={12}>
                <FormInput
                  fullWidth
                  label="Precio"
                  type="number"
                  name="price"
                />
              </Grid>
              {/* Ubicación */}
              <Grid item xs={12}>
                <Box>
                  <FormControlLabel
                    labelPlacement="start"
                    sx={{
                      m: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    control={
                      <Switch
                        checked={addLocation}
                        onChange={(e) => handleAddLocation(e, setFieldValue)}
                      />
                    }
                    label="Agregar ubicación"
                  />
                </Box>
                {addLocation ? (
                  <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
                    <GoogleMap
                      mapContainerStyle={{
                        width: "100%",
                        height: "300px",
                      }}
                      center={values.location}
                      zoom={15}
                      onClick={(e) =>
                        setFieldValue("location", {
                          lat: e.latLng.lat(),
                          lng: e.latLng.lng(),
                        })
                      }
                    >
                      <Marker position={values.location} />
                    </GoogleMap>
                  </LoadScript>
                ) : null}
              </Grid>
              {/* Categorías */}
              <Grid item xs={12}>
                <Box sx={{ bgcolor: "#F1F1F1", borderRadius: 2, p: 2 }}>
                  <FieldArray name="categories">
                    {({ push, remove }) => (
                      <div>
                        {/* Campo de texto para agregar nueva categoría */}
                        <Stack
                          direction="row"
                          spacing={2}
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography>Categorías</Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              push({ name: "" });
                            }}
                            startIcon={<AddCircleIcon />}
                          >
                            Agregar Categoría
                          </Button>
                        </Stack>
                        <Divider sx={{ my: 1 }} />
                        {/* Lista de categorías actuales */}
                        <List dense>
                          {values.categories.map((category, catIndex) => (
                            <ListItem
                              key={catIndex}
                              secondaryAction={
                                <IconButton onClick={() => remove(catIndex)}>
                                  <DeleteIcon />
                                </IconButton>
                              }
                            >
                              <FormInput
                                fullWidth
                                name={`categories[${catIndex}].name`}
                                label="Nombre de la categoría"
                              />
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    )}
                  </FieldArray>
                </Box>
              </Grid>
              {/* Addons */}
              <Grid item xs={12}>
                <EventAddons
                  addons={values.addons}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              {/* Botón de Enviar */}
              <Grid item xs={12}>
                <Button
                  fullWidth
                  disabled={!isValid}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} color="secondary" />
                  ) : event ? (
                    "Guardar cambios"
                  ) : (
                    "Crear evento"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EventsForm;
