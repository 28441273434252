import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Avatar,
  Paper,
  Rating,
  Divider,
} from "@mui/material";

const ViewReviews = () => {
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsCollection = collection(db, "GiftcardReviews");
        const reviewsSnapshot = await getDocs(reviewsCollection);

        let totalRating = 0;
        const reviewsData = [];

        for (const reviewDoc of reviewsSnapshot.docs) {
          const reviewData = reviewDoc.data();
          totalRating += reviewData.stars;

          // Fetch user details from the Users collection
          const userRef = doc(db, "Users", reviewData.uid);
          const userDoc = await getDoc(userRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            reviewsData.push({
              ...reviewData,
              name: userData.name,
              photoUrl: userData.photoUrl,
            });
          }
        }

        setReviews(reviewsData);
        setAverageRating(
          reviewsSnapshot.size ? totalRating / reviewsSnapshot.size : 0
        );
      } catch (error) {
        console.error("Error al obtener las reseñas:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, []);

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 4, mt: 5 }}>
        <Typography variant="h4" gutterBottom align="center" color="primary">
          Reviews
        </Typography>
        <Box my={3} textAlign="center">
          <Typography variant="h6">Promedio de Calificación</Typography>
          <Rating
            value={averageRating}
            precision={0.1}
            readOnly
            sx={{ fontSize: "2rem", mt: 1 }}
          />
          <Typography variant="h5" color="textSecondary" mt={1}>
            {averageRating.toFixed(1)} / 5
          </Typography>
        </Box>
        <Divider sx={{ mb: 3 }} />
        {reviews.map((review, index) => (
          <Box key={index} display="flex" alignItems="center" mb={3}>
            <Avatar
              src={review.photoUrl}
              alt={review.name}
              sx={{ width: 56, height: 56, mr: 2 }}
            />
            <Box flexGrow={1}>
              <Typography variant="h6">{review.name}</Typography>
              <Rating value={review.stars} readOnly />
              <Typography variant="body2" color="textSecondary" mt={1}>
                {review.review}
              </Typography>
            </Box>
          </Box>
        ))}
      </Paper>
    </Container>
  );
};

export default ViewReviews;
