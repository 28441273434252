import React from "react";
import { Grid, Typography, Card, CardContent, CardMedia } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import PeopleIcon from "@mui/icons-material/People";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import TimerIcon from "@mui/icons-material/Timer";
import PaymentIcon from "@mui/icons-material/Payment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import LiveTvIcon from "@mui/icons-material/LiveTv"; // Nuevo ícono para Livestream

const features = [
  {
    title: "Cronometraje",
    description:
      "Mide tus tiempos con precisión y compite en tiempo real utilizando nuestra tecnología avanzada de cronometraje.",
    icon: <TimerIcon sx={{ fontSize: 50, color: "#00bcd4" }} />, // Icono azul claro
  },
  {
    title: "Inscripciones",
    description:
      "Regístrate fácilmente a eventos y gestiona tu participación de manera eficiente.",
    icon: <AssignmentIcon sx={{ fontSize: 50, color: "#ff5722" }} />, // Icono naranja oscuro
  },
  {
    title: "Control de Acceso",
    description:
      "Gestiona tus entradas y salidas de eventos de manera segura, con tecnología de control de acceso eficiente y confiable.",
    icon: <LockOpenIcon sx={{ fontSize: 50, color: "#ff9800" }} />, // Icono naranja
  },
  {
    title: "Livestream",
    description:
      "Transmisión en vivo de tus eventos utilizando drones y tecnología Starlink para no perder la transmisión sin importar la ubicación.",
    icon: <LiveTvIcon sx={{ fontSize: 50, color: "#d32f2f" }} />, // Icono rojo para Livestream
  },
  {
    title: "Perfil de Corredor",
    description:
      "Visualiza y personaliza tu perfil, mostrando tus logros, competencias y estadísticas de carreras.",
    icon: <PersonIcon sx={{ fontSize: 50, color: "#3f51b5" }} />, // Icono azul
  },
  {
    title: "Carreras por Equipos",
    description:
      "Participa en carreras por equipos, compite junto a tus compañeros y logra la victoria en conjunto.",
    icon: <GroupIcon sx={{ fontSize: 50, color: "#4caf50" }} />, // Icono verde
  },
  {
    title: "Marketplace",
    description:
      "Compra y vende equipo, encuentra ofertas exclusivas y todo lo necesario para tu próxima aventura en ciclismo.",
    icon: <ShoppingCartIcon sx={{ fontSize: 50, color: "#9c27b0" }} />, // Icono púrpura
  },
  {
    title: "Leaderboard",
    description:
      "Compite con otros y escala en el tablero de líderes, mostrando tus logros y tiempos récord.",
    icon: <LeaderboardIcon sx={{ fontSize: 50, color: "#f50057" }} />, // Icono rojo
  },
  {
    title: "Pago Cashless",
    description:
      "Realiza pagos de manera rápida y segura en eventos sin la necesidad de usar efectivo.",
    icon: <PaymentIcon sx={{ fontSize: 50, color: "#00bcd4" }} />, // Icono azul claro
  },
  {
    title: "Comunidades",
    description:
      "Únete a comunidades de ciclistas, participa en eventos y conéctate con otros que comparten tu pasión.",
    icon: <PeopleIcon sx={{ fontSize: 50, color: "#4caf50" }} />, // Icono verde
  },
];

const Features = () => {
  return (
    <Grid container spacing={{ xs: 2, sm: 3, md: 4 }} sx={{ py: 8, px: 2 }}>
      {/* Título en la parte superior */}
      <Grid item xs={12}>
        <Typography
          variant="h4"
          align="center"
          sx={{
            fontWeight: "bold",
            mb: 4,
            color: "#333", // Título en color oscuro
          }}
        >
          Features
        </Typography>
      </Grid>

      {/* Tarjetas de características */}
      {features.map((feature, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              p: 2,
              borderRadius: "15px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)", // Sombra suave
              backgroundColor: "#e0e0e0", // Fondo gris para las tarjetas
            }}
          >
            <CardMedia sx={{ my: 2 }}>{feature.icon}</CardMedia>
            <CardContent>
              <Typography
                variant="h6"
                component="h3"
                sx={{
                  fontWeight: "bold",
                  color: "#333", // Texto oscuro para títulos
                }}
              >
                {feature.title}
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, color: "#666" }}>
                {feature.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Features;
