import { TextField } from "@mui/material";
import { useField } from "formik";
import React from "react";

function FormInput({
  id,
  label,
  name,
  placeholder,
  disabled,
  type,
  fullWidth,
  multiline,
  rows,
  size,
  InputLabelProps,
}) {
  const [field, meta] = useField({ name, placeholder, disabled, type });
  return (
    <TextField
      id={id}
      name={name}
      type={type}
      rows={rows}
      label={label}
      disabled={disabled}
      value={field.value}
      multiline={multiline}
      fullWidth={fullWidth}
      size={size}
      InputLabelProps={InputLabelProps}
      onChange={field.onChange}
      onBlur={field.onBlur}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}

export default FormInput;
