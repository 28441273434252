import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { eventsApi } from "../../../lib/client";
import { ChevronLeft } from "lucide-react";
import { Button, CircularProgress } from "@mui/material";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";
import toast from "react-hot-toast";

function FinishEvent() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [event, setEvent] = useState(null);
  const [isPastEvent, setIsPastEvent] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasOfficialResults, setHasOfficialResults] = useState(true);
  const [runs, setRuns] = useState([]);

  useEffect(() => {
    const fetchEventAndRuns = async () => {
      const eventData = await eventsApi.getEventById(id);
      const runsCollection = collection(db, "Runs");
      const tagsCollection = collection(db, "Tags");

      const runsQuery = query(runsCollection, where("EventID", "==", id));
      const runsSnapshot = await getDocs(runsQuery);

      const runsData = await Promise.all(
        runsSnapshot.docs.map(async (runDoc) => {
          const runData = runDoc.data();

          const tagQuery = query(
            tagsCollection,
            where("NFCID", "==", runData.NFCIdentifier)
          );
          const tagSnapshot = await getDocs(tagQuery);

          let ownerName = "Desconocido";
          let ownerId = "";

          if (!tagSnapshot.empty) {
            const tagData = tagSnapshot.docs[0].data();
            if (tagData.Owner !== "") {
              const userRef = doc(db, "Users", tagData.Owner);
              const userDoc = await getDoc(userRef);
              if (userDoc.exists()) {
                ownerName = userDoc.data().name;
                ownerId = userDoc.id;
              }
            }
          }

          return {
            id: runDoc.id,
            ownerName,
            ownerId,
            ...runData,
          };
        })
      );

      runsData.sort((a, b) => a.NFCIdentifier.localeCompare(b.NFCIdentifier));

      const eventDate = new Date(eventData.dateTime);
      const currentDate = new Date();

      setRuns(runsData);
      setEvent(eventData);
      setHasOfficialResults(
        eventData.officialResults ? eventData.officialResults : false
      );
      setIsPastEvent(eventDate <= currentDate);
      setIsLoading(false);
    };
    fetchEventAndRuns();
  }, [id]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleFinishEvent = async () => {
    const confirmFinish = window.confirm(
      "¿Estás seguro de que deseas terminar el evento?"
    );
    if (!confirmFinish) return;

    try {
      setIsSubmitting(true);
      await Promise.all(
        runs.map(async (run) => {
          if (!run.ElapsedTime) return;
          const runDocRef = doc(db, "Runs", run.id);
          await updateDoc(runDocRef, { NFCIdentifier: run.ownerId });
        })
      );

      console.log("Runs actualizadas!");
      toast.success("Runs actualizadas!");
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error al actualizar las Runs:", error);
      toast.error("Error al actualizar las Runs");
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container py-2">
      <div className="my-2">
        <Button onClick={handleGoBack} variant="contained">
          <ChevronLeft />
          Regresar
        </Button>
      </div>
      <div className="flex flex-col bg-slate-200 justify-center items-center border rounded p-2">
        <p className="text-xl font-semibold">Terminar evento</p>
        <p className="text-lg">Nombre del evento: {event.eventName}</p>
        <p className="text-lg">Fecha: {event.dateTime}</p>
        <div>
          <p className="text-center">
            Esta acción reasignara las Runs al Owner que este registrado en el
            evento.
          </p>
        </div>
        <div className="flex gap-2 mt-2">
          <Button variant="outlined">Cancelar</Button>
          <Button
            onClick={handleFinishEvent}
            variant="contained"
            disabled={!isPastEvent || isSubmitting || hasOfficialResults}
          >
            {isSubmitting ? <CircularProgress size={24} /> : "Terminar"}
          </Button>
        </div>
        {event.officialResults && (
          <div>
            <p className="text-red-500 font-semibold">
              Este evento ya tiene resultados oficiales
            </p>
          </div>
        )}
      </div>
      {/* Tabla de Runs */}
      <div className="mt-4 overflow-auto">
        <h2 className="text-lg font-semibold mb-2">
          Runs Asociadas {runs.length}
        </h2>
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="border px-2 py-2">Id</th>
              <th className="border px-2 py-2">Nombre</th>
              <th className="border px-2 py-2">NFC Identifier</th>
              <th className="border px-2 py-2">Time</th>
              <th className="border px-2 py-2">RUID</th>
            </tr>
          </thead>
          <tbody>
            {runs.map((run) => (
              <tr key={run.id}>
                <td className="border px-2 py-2">{run.id}</td>
                <td className="border px-2 py-2">{run.ownerName}</td>
                <td className="border px-2 py-2">{run.NFCIdentifier}</td>
                <td className="border px-2 py-2">
                  {!run.ElapsedTime ? "AMOSAI" : run.ElapsedTime}
                </td>
                <td className="border px-2 py-2">{run.RUID}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default FinishEvent;
