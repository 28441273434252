import React, { useEffect, useState, useRef } from "react";
import {
  doc,
  collection,
  addDoc,
  deleteDoc,
  query,
  orderBy,
  onSnapshot,
  getDoc,
  serverTimestamp,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { db, storage, auth } from "../../firebase";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import SendIcon from "@mui/icons-material/Send";

const EventChat = ({ uid }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [largeImageUrl, setLargeImageUrl] = useState("");
  const [userId, setUserId] = useState(null);
  const [typing, setTyping] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      setLoading(true);
      const chatRef = collection(db, "events", uid, "chat");
      const q = query(chatRef, orderBy("timestamp", "asc"));
      onSnapshot(q, async (snapshot) => {
        const updatedMessages = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const data = doc.data();
            const userInfo = await fetchUserInfo(data.senderId);
            return { id: doc.id, ...data, userInfo };
          })
        );
        setMessages(updatedMessages);
        setLoading(false);
      });
    };

    const fetchUserId = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
      }
    };

    const listenToTypingStatus = () => {
      const typingRef = doc(db, "events", uid, "typing", "status");
      onSnapshot(typingRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          setIsUserTyping(data.isTyping && data.userId !== userId);
        }
      });
    };

    fetchMessages();
    fetchUserId();
    listenToTypingStatus();
  }, [uid, userId]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" && !selectedImage) return;

    let imageUrl = "";
    if (selectedImage) {
      const imageRef = ref(storage, `chat_images/${uid}/${selectedImage.name}`);
      const snapshot = await uploadBytes(imageRef, selectedImage);
      imageUrl = await getDownloadURL(snapshot.ref);
      setSelectedImage(null);
      setPreviewImage(null);
    }

    const chatRef = collection(db, "events", uid, "chat");
    const docRef = await addDoc(chatRef, {
      message: newMessage,
      senderId: userId,
      timestamp: serverTimestamp(),
      imageUrl,
    });

    await updateDoc(docRef, { sent: true });

    setNewMessage("");
    setTyping(false);

    const typingRef = doc(db, "events", uid, "typing", "status");
    await setDoc(typingRef, { isTyping: false, userId });
  };

  const handleTyping = async (e) => {
    setNewMessage(e.target.value);
    setTyping(true);

    const typingRef = doc(db, "events", uid, "typing", "status");
    await setDoc(typingRef, { isTyping: true, userId });
  };

  const handleStopTyping = async () => {
    setTyping(false);

    const typingRef = doc(db, "events", uid, "typing", "status");
    await setDoc(typingRef, { isTyping: false, userId });
  };

  const handleDeleteMessage = async () => {
    if (messageToDelete.imageUrl) {
      const imageRef = ref(storage, messageToDelete.imageUrl);
      await deleteObject(imageRef);
    }

    const messageRef = doc(db, "events", uid, "chat", messageToDelete.id);
    await deleteDoc(messageRef);

    setOpenDialog(false);
    setMessageToDelete(null);
  };

  const fetchUserInfo = async (userId) => {
    const userRef = doc(db, "Users", userId);
    const userSnap = await getDoc(userRef);
    return userSnap.exists() ? userSnap.data() : { name: "Desconocido", photoUrl: "" };
  };

  const handleOpenDialog = (message) => {
    setMessageToDelete(message);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMessageToDelete(null);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
      setPreviewImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleImageClick = (url) => {
    setLargeImageUrl(url);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setLargeImageUrl("");
  };

  return (
    <div className="flex flex-col h-[80vh] border rounded-lg shadow-md bg-white overflow-hidden">
      <h1 className="text-2xl font-semibold text-center bg-black text-white py-4">Chat</h1>
      <div className="flex-grow overflow-y-auto p-4 space-y-4">
        {loading ? (
          <div className="flex justify-center items-center h-full">
            <CircularProgress />
          </div>
        ) : (
          <>
            {messages.map((message) => (
              <div
                key={message.id}
                className={`flex ${
                  message.senderId === userId ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`max-w-[70%] p-4 rounded-lg shadow ${
                    message.senderId === userId ? "bg-black text-white" : "bg-gray-200 text-black"
                  } cursor-pointer`}
                  onClick={() =>
                    message.senderId === userId && handleOpenDialog(message)
                  }
                >
                  <div className="flex items-center space-x-2">
                    <img
                      src={message.userInfo?.photoUrl}
                      alt={message.userInfo?.name}
                      className="w-8 h-8 rounded-full"
                    />
                    <span className="font-semibold">{message.userInfo?.name}</span>
                  </div>
                  <p className="mt-2">{message.message}</p>
                  {message.imageUrl && (
                    <img
                      src={message.imageUrl}
                      alt="chat"
                      className="max-w-[100px] mt-2 rounded cursor-pointer"
                      onClick={() => handleImageClick(message.imageUrl)}
                    />
                  )}
                  <p className="text-xs mt-1 text-gray-500">
                    {new Date(message.timestamp?.seconds * 1000).toLocaleString()}
                  </p>
                  {message.senderId === userId && message.sent && (
                    <span className="text-xs text-green-500 ml-2">✔ Sent</span>
                  )}
                </div>
              </div>
            ))}
            {isUserTyping && (
              <p className="text-gray-500 text-sm pl-2">Alguien está escribiendo...</p>
            )}
          </>
        )}
      </div>
      {previewImage && (
        <div className="flex justify-center my-2">
          <img src={previewImage} alt="Preview" className="w-20 rounded-md" />
        </div>
      )}
      <div className="flex items-center p-4 border-t bg-gray-50 space-x-2">
        <input
          type="text"
          placeholder="Escribe un mensaje"
          value={newMessage}
          onChange={handleTyping}
          onBlur={handleStopTyping}
          className="flex-grow border rounded-md p-2 outline-none"
        />
        <label htmlFor="icon-button-file">
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="icon-button-file"
            type="file"
            onChange={handleImageChange}
          />
          <PhotoCamera className="text-black cursor-pointer" />
        </label>
        <button
  onClick={handleSendMessage}
  className="bg-black text-white p-3 rounded-full hover:bg-gray-800 flex items-center justify-center"
>
  <SendIcon fontSize="small" />
</button>
      </div>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"Eliminar Mensaje"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que quieres eliminar este mensaje?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleCloseDialog}
            className="px-4 py-2 text-black font-semibold hover:bg-gray-100 rounded"
          >
            Cancelar
          </button>
          <button
            onClick={handleDeleteMessage}
            className="px-4 py-2 text-red-600 font-semibold hover:bg-red-50 rounded"
          >
            Eliminar
          </button>
        </DialogActions>
      </Dialog>

      <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
        <DialogTitle>Imagen</DialogTitle>
        <DialogContent>
          <img
            src={largeImageUrl}
            alt="Vista ampliada"
            onClick={handleCloseImageDialog}
            className="w-full h-auto cursor-pointer"
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EventChat;
