import React, { useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../../firebase"; // Asegúrate de que esta sea la ruta correcta a tu configuración de Firebase
import { doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";

const SendSuccess = ({ order, eventName, categoryName }) => {
  useEffect(() => {
    const sendEmail = async () => {
      try {
        // Obtener UID del usuario logueado
        const auth = getAuth();
        const user = auth.currentUser;

        if (!user) {
          console.error("No se encontró un usuario logueado.");
          return;
        }

        const uid = user.uid;
        console.log("UID del usuario logueado:", uid);

        // Buscar el documento del usuario en Firestore para obtener el email
        const userRef = doc(db, "Users", uid);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
          console.error("No se encontró el usuario en la colección 'Users'.");
          return;
        }

        const userData = userSnap.data();
        const userEmail = userData.email;

        console.log("Correo del usuario:", userEmail);

        // Crear el mensaje del correo
        const emailMessage = `
          <div style="background-color: #f4f4f4; padding: 20px;">
            <div style="max-width: 600px; margin: 0 auto; background-color: #ffffff; padding: 20px; border-radius: 10px; text-align: center;">
              <a href="https://www.livespeed.mx" target="_blank">
                <img src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/LiveSpeed.png?alt=media" alt="Livespeed" style="max-width: 200px; margin-bottom: 20px;" />
              </a>
              <h1 style="color: #333;">¡Gracias por tu inscripción!</h1>
              <p style="color: #555; line-height: 1.6; font-size: 16px;">
                Has sido registrado con éxito en el evento <strong>${eventName}</strong> en la categoría <strong>${categoryName}</strong>.
                <br/><br/>
                Tu total de compra fue de <strong>$${order.total}</strong>.
                <br/><br/>
                ¡Nos vemos en la pista!
                <br/>
                <strong>Equipo de Livespeed</strong>
              </p>
              <p style="color: #555;">Fecha de inscripción: ${new Date().toLocaleDateString(
                "es-ES"
              )}</p>
            </div>
          </div>
        `;

        // Enviar el correo electrónico utilizando la función de Firebase
        const functions = getFunctions();
        const mailSender = httpsCallable(functions, "mailSender");

        console.log("Enviando correo a:", userEmail);

        const result = await mailSender({
          email: userEmail,
          subject: "Confirmación de Inscripción - Livespeed",
          message: emailMessage,
        });

        console.log("Resultado del envío de correo:", result);
      } catch (error) {
        console.error("Error al enviar el correo:", error);
      }
    };

    sendEmail();
  }, [order, eventName, categoryName]);

  return null; // No UI necesario para este componente
};

export default SendSuccess;
