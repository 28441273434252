import React, { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-hot-toast";
import axios from "axios";

const CaptchaVerification = ({ onVerified }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const verifyCaptcha = async () => {
      if (!executeRecaptcha) {
        console.error("No se pudo cargar reCAPTCHA v3");
        return;
      }

      try {
        const token = await executeRecaptcha("submit");

        // Usar la URL completa del endpoint en producción
        const response = await axios.post(
          "https://stripe-441018.wl.r.appspot.com/api/verify-captcha",
          { token }
        );

        if (response.data.success) {
          toast.success("¡Captcha verificado exitosamente!");
          onVerified(true);
        } else {
          toast.error("La verificación del captcha falló. Intenta de nuevo.");
          onVerified(false);
        }
      } catch (error) {
        console.error("Error al verificar el captcha:", error);
        toast.error("Hubo un error en la verificación del captcha.");
        onVerified(false);
      }
    };

    verifyCaptcha();
  }, [executeRecaptcha, onVerified]);

  return null;
};

export default CaptchaVerification;
