import React, { useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { useNavigate } from "react-router-dom";

const Combo = () => {
  const originalPrice = 2400;
  const comboPrice = 2200;
  const savings = originalPrice - comboPrice;
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const handlePayment = () => {
    if (user) {
      window.location.href =
        "https://livespeed.mx/payevent/WkjJarJjKKXEMvPSgWBL";
    } else {
      setShowPopup(true);
    }
  };

  const handleIndividualPayment = () => {
    navigate("/events");
  };

  const closePopup = () => setShowPopup(false);

  return (
    <div className="relative bg-black text-white shadow-xl rounded-lg overflow-hidden max-w-md mx-auto my-8 text-center">
      {/* Sedes del Combo */}
      <div className="grid grid-cols-2 md:grid-cols-4 bg-gray-900 py-4 text-center text-sm font-semibold">
        <div>
          <p>Hermosillo</p>
          <p className="text-gray-400">23 de noviembre</p>
        </div>
        <div>
          <p>Obregón</p>
          <p className="text-gray-400">18 de enero</p>
        </div>
        <div>
          <p>Esmeralda</p>
          <p className="text-gray-400">8 de marzo</p>
        </div>
        <div>
          <p>Capital Flow</p>
          <p className="text-gray-400">29 de marzo</p>
        </div>
      </div>

      {/* Imagen de fondo con superposición */}
      <div className="relative">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/event_images%2Fundefined%2F451986342_442570301935704_2481461073964117583_n.jpg?alt=media&token=b1ffa3a4-7c16-45fe-85dd-035a1b1e32a6"
          alt="Combo Offer"
          className="w-full h-64 object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <h2 className="text-3xl font-extrabold text-white">
            ¡Combo Especial x 4 eventos!
          </h2>
        </div>
      </div>

      {/* Detalles de la oferta */}
      <div className="p-6 bg-black">
        <p className="text-gray-300 mb-4">
          Cada evento cuesta <span className="font-bold text-white">$600</span>.
          Si te inscribes en los 4 eventos en modo combo, ¡obtienes un
          descuento!
        </p>
        <div className="bg-gray-800 rounded-lg p-4 my-4">
          <p className="text-lg font-medium text-gray-400 mb-1">
            Precio original:
            <span className="line-through text-red-600 ml-2">
              ${originalPrice}
            </span>
          </p>
          <p className="text-2xl font-bold text-white mb-1">
            Precio Combo: <span className="text-green-400">${comboPrice}</span>
          </p>
          <p className="text-md text-gray-300">
            Te ahorras{" "}
            <span className="text-green-400 font-semibold">${savings}</span> al
            inscribirte en el combo.
          </p>
        </div>

        {/* Botón de Pagar Combo */}
        <button
          onClick={handlePayment}
          className="w-full px-6 py-3 bg-white text-black font-semibold text-lg rounded-lg shadow-lg hover:bg-gray-200 transition duration-300 ease-in-out mb-4"
        >
          Pagar Combo
        </button>

        {/* Botón de Pagar Individual */}
        <button
          onClick={handleIndividualPayment}
          className="w-full px-6 py-3 bg-gray-700 text-white font-semibold text-lg rounded-lg shadow-lg hover:bg-gray-600 transition duration-300 ease-in-out"
        >
          Pagar Individual
        </button>
      </div>

      {/* Popup de Autenticación */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm text-center">
            <h3 className="text-2xl font-bold mb-4 text-gray-800">
              ¡Inicia sesión o crea una cuenta!
            </h3>
            <p className="text-gray-600 mb-6">
              Necesitas iniciar sesión para continuar con el pago del combo.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => navigate("/login")}
                className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition"
              >
                Iniciar Sesión
              </button>
              <button
                onClick={() => navigate("/signup")}
                className="px-4 py-2 bg-green-600 text-white font-semibold rounded-lg shadow-md hover:bg-green-700 transition"
              >
                Crear Cuenta
              </button>
            </div>
            <button
              onClick={closePopup}
              className="mt-6 text-gray-500 hover:text-gray-700 underline"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Combo;
