import React from "react";
import { CheckCircle, Tag } from "lucide-react";

const AddonsStep = ({
  addons,
  selectedAddons,
  addonSizes,
  onAddonChange,
  onSizeChange,
}) => {
  const handleVariantChange = (addon, variant, option) => {
    onSizeChange(addon.id, option.name);
  };

  const getAddonPrice = (addon, selectedOption) => {
    if (addon.variants && selectedOption) {
      const variantOption = addon.variants
        .flatMap((variant) => variant.options)
        .find((option) => option.name === selectedOption);

      const basePrice = addon.price + (variantOption?.price || 0);
      const finalPrice = addon.discount
        ? basePrice - addon.discount
        : basePrice;

      return { basePrice, finalPrice };
    }
    const finalPrice = addon.discount
      ? addon.price - addon.discount
      : addon.price;
    return { basePrice: addon.price, finalPrice };
  };

  if (addons.length === 0) {
    return <p>No hay addons, puedes continuar.</p>;
  }

  return (
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      {addons.map((addon) => {
        const isSelected = selectedAddons.some((a) => a.id === addon.id);
        const selectedOption = addonSizes[addon.id];
        const { basePrice, finalPrice } = getAddonPrice(addon, selectedOption);

        return (
          <div
            key={addon.id}
            onClick={() => onAddonChange(addon, !isSelected)}
            className={`relative p-4 rounded-lg transition-transform transform hover:scale-105 ${
              isSelected
                ? "border-2 border-black shadow-lg"
                : "border border-gray-300"
            } cursor-pointer bg-white`}
          >
            {isSelected && (
              <CheckCircle className="absolute top-2 right-2 text-green-500 w-6 h-6" />
            )}

            {addon.image && (
              <img
                src={addon.image}
                alt={addon.name}
                className="rounded-md w-full h-36 object-cover mb-4"
              />
            )}
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-lg font-bold">{addon.name}</h3>
              {addon.discount && (
                <Tag className="text-red-500 w-5 h-5" />
              )}
            </div>

            {addon.discount ? (
              <div>
                <p className="text-sm text-gray-500 line-through">
                  Precio anterior: ${basePrice}
                </p>
                <p className="text-md font-bold text-red-600">
                  Precio con descuento: ${finalPrice}
                </p>
              </div>
            ) : (
              <p className="text-md font-medium text-gray-700">
                Precio: ${finalPrice}
              </p>
            )}

            {/* Renderizar variantes si existen */}
            {addon.variants?.map((variant) => (
              <div key={variant.name} className="mt-3">
                <label className="block text-sm font-semibold text-gray-600">
                  {variant.name}
                </label>
                <select
                  value={selectedOption || ""}
                  onChange={(e) =>
                    handleVariantChange(
                      addon,
                      variant,
                      variant.options.find((opt) => opt.name === e.target.value)
                    )
                  }
                  className="w-full p-2 mt-1 border rounded-lg text-gray-700 bg-gray-50 focus:border-black focus:outline-none"
                >
                  {variant.options.map((option) => (
                    <option key={option.name} value={option.name}>
                      {option.name} {option.price ? `- $${option.price}` : ""}
                    </option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default AddonsStep;
