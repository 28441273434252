// OrderCount.js
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";

const OrderCount = () => {
  const [orderCount, setOrderCount] = useState(0);

  useEffect(() => {
    const fetchOrderCount = async () => {
      try {
        const ordersSnapshot = await getDocs(collection(db, "orders"));
        setOrderCount(ordersSnapshot.size);
      } catch (error) {
        console.error("Error fetching order count:", error);
      }
    };

    fetchOrderCount();
  }, []);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition">
      <h3 className="text-xl font-bold text-gray-700">Orders</h3>
      <p className="text-2xl font-semibold text-orange-600 mt-2">
        {orderCount}
      </p>
    </div>
  );
};

export default OrderCount;
