import React, { useState, useEffect } from "react";
import { db, storage } from "../../firebase";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useUserAuth } from "../../context/userAuthContext";
import { v4 as uuidv4 } from "uuid";

const CreateShopProduct = () => {
  const { user } = useUserAuth();
  const [title, setTitle] = useState("");
  const [cost, setCost] = useState("");
  const [description, setDescription] = useState("");
  const [stock, setStock] = useState("");
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newSubcategory, setNewSubcategory] = useState("");
  const [newBrand, setNewBrand] = useState("");
  const [newModel, setNewModel] = useState("");
  const [imageFiles, setImageFiles] = useState([]);

  useEffect(() => {
    fetchCategories();
    fetchBrands();
  }, []);

  const fetchCategories = async () => {
    const categoryCollection = collection(db, "ShopCategories");
    const categorySnapshot = await getDocs(categoryCollection);
    const categoryData = categorySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setCategories(categoryData);
  };

  const fetchBrands = async () => {
    const brandCollection = collection(db, "ShopBrand");
    const brandSnapshot = await getDocs(brandCollection);
    const brandData = brandSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setBrands(brandData);
  };

  const handleImageUpload = async () => {
    const uploadPromises = imageFiles.map(async (file) => {
      const storageRef = ref(storage, `ShopImages/${uuidv4()}-${file.name}`);
      await uploadBytes(storageRef, file);
      return getDownloadURL(storageRef);
    });
    return Promise.all(uploadPromises);
  };

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    try {
      const imageUrls = await handleImageUpload();

      const productData = {
        title,
        brand: selectedBrand,
        model: selectedModel,
        cost: parseFloat(cost),
        description,
        images: imageUrls,
        stock: parseInt(stock),
        category: selectedCategory,
        subcategory: selectedSubcategory,
        creatorUID: user.uid,
      };

      await addDoc(collection(db, "ShopItems"), productData);
      alert("Product created successfully!");
    } catch (error) {
      console.error("Error creating product:", error);
      alert("Error creating product. Please try again.");
    }
  };

  const handleAddCategory = async () => {
    if (!newCategory) return;
    try {
      const categoryRef = await addDoc(collection(db, "ShopCategories"), {
        name: newCategory,
      });
      setCategories([...categories, { id: categoryRef.id, name: newCategory }]);
      setNewCategory("");
      alert("Category added successfully!");
    } catch (error) {
      console.error("Error adding category:", error);
      alert("Error adding category. Please try again.");
    }
  };

  const handleAddSubcategory = async () => {
    if (!newSubcategory || !selectedCategory) return;
    try {
      const subcategoryRef = await addDoc(
        collection(db, "ShopCategories", selectedCategory, "subcategory"),
        { name: newSubcategory }
      );
      setSubcategories([
        ...subcategories,
        { id: subcategoryRef.id, name: newSubcategory },
      ]);
      setNewSubcategory("");
      alert("Subcategory added successfully!");
    } catch (error) {
      console.error("Error adding subcategory:", error);
      alert("Error adding subcategory. Please try again.");
    }
  };

  const handleAddBrand = async () => {
    if (!newBrand) return;
    try {
      const brandRef = await addDoc(collection(db, "ShopBrand"), {
        name: newBrand,
      });
      setBrands([...brands, { id: brandRef.id, name: newBrand }]);
      setNewBrand("");
      alert("Brand added successfully!");
    } catch (error) {
      console.error("Error adding brand:", error);
      alert("Error adding brand. Please try again.");
    }
  };

  const handleAddModel = async () => {
    if (!newModel || !selectedBrand) return;
    try {
      const modelRef = await addDoc(
        collection(db, "ShopBrand", selectedBrand, "Models"),
        { name: newModel }
      );
      setModels([...models, { id: modelRef.id, name: newModel }]);
      setNewModel("");
      alert("Model added successfully!");
    } catch (error) {
      console.error("Error adding model:", error);
      alert("Error adding model. Please try again.");
    }
  };

  const handleCategoryChange = async (categoryId) => {
    setSelectedCategory(categoryId);
    setSelectedSubcategory("");
    const subcategorySnapshot = await getDocs(
      collection(db, "ShopCategories", categoryId, "subcategory")
    );
    const subcategoryData = subcategorySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setSubcategories(subcategoryData);
  };

  const handleBrandChange = async (brandId) => {
    setSelectedBrand(brandId);
    setSelectedModel("");
    const modelSnapshot = await getDocs(
      collection(db, "ShopBrand", brandId, "Models")
    );
    const modelData = modelSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setModels(modelData);
  };

  return (
    <div className="max-w-lg mx-auto bg-white p-6 rounded-lg shadow-md mt-4">
      <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">
        Create New Product
      </h2>
      <form onSubmit={handleCreateProduct} className="space-y-4">
        <input
          type="text"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
        />
        <input
          type="number"
          placeholder="Cost"
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          required
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
        />
        <textarea
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
        />
        <input
          type="number"
          placeholder="Stock"
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          required
          className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
        />

        {/* Category Selection */}
        <div>
          <label className="block text-gray-700 font-medium mb-2">
            Category
          </label>
          <select
            value={selectedCategory}
            onChange={(e) => handleCategoryChange(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Add New Category"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            className="flex-1 p-3 border border-gray-300 rounded-lg mt-2"
          />
          <button
            type="button"
            onClick={handleAddCategory}
            className="p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition mt-2"
          >
            Add Category
          </button>
        </div>

        {/* Subcategory Selection */}
        <div>
          <label className="block text-gray-700 font-medium mb-2">
            Subcategory
          </label>
          <select
            value={selectedSubcategory}
            onChange={(e) => setSelectedSubcategory(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          >
            <option value="">Select a subcategory</option>
            {subcategories.map((subcategory) => (
              <option key={subcategory.id} value={subcategory.id}>
                {subcategory.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Add New Subcategory"
            value={newSubcategory}
            onChange={(e) => setNewSubcategory(e.target.value)}
            className="flex-1 p-3 border border-gray-300 rounded-lg mt-2"
          />
          <button
            type="button"
            onClick={handleAddSubcategory}
            className="p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition mt-2"
          >
            Add Subcategory
          </button>
        </div>

        {/* Brand Selection */}
        <div>
          <label className="block text-gray-700 font-medium mb-2">Brand</label>
          <select
            value={selectedBrand}
            onChange={(e) => handleBrandChange(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          >
            <option value="">Select a brand</option>
            {brands.map((brand) => (
              <option key={brand.id} value={brand.id}>
                {brand.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Add New Brand"
            value={newBrand}
            onChange={(e) => setNewBrand(e.target.value)}
            className="flex-1 p-3 border border-gray-300 rounded-lg mt-2"
          />
          <button
            type="button"
            onClick={handleAddBrand}
            className="p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition mt-2"
          >
            Add Brand
          </button>
        </div>

        {/* Model Selection */}
        <div>
          <label className="block text-gray-700 font-medium mb-2">Model</label>
          <select
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          >
            <option value="">Select a model</option>
            {models.map((model) => (
              <option key={model.id} value={model.id}>
                {model.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Add New Model"
            value={newModel}
            onChange={(e) => setNewModel(e.target.value)}
            className="flex-1 p-3 border border-gray-300 rounded-lg mt-2"
          />
          <button
            type="button"
            onClick={handleAddModel}
            className="p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition mt-2"
          >
            Add Model
          </button>
        </div>

        <div>
          <label className="block text-gray-700 font-medium mb-2">
            Upload Images
          </label>
          <input
            type="file"
            multiple
            onChange={(e) => setImageFiles([...e.target.files])}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring focus:ring-blue-200"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-green-600 text-white p-3 rounded-lg hover:bg-green-700 transition"
        >
          Create Product
        </button>
      </form>
    </div>
  );
};

export default CreateShopProduct;
