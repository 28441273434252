import React, { useState, useRef } from "react";
import { CalendarDays } from "lucide-react";
import ConfettiExplosion from "react-confetti-explosion";

const CategoryStep = ({ categories, selectedCategory, onCategoryChange }) => {
  const [isExploding, setIsExploding] = useState(false);
  const [explosionPosition, setExplosionPosition] = useState({ x: 0, y: 0 });

  // Usamos useRef para las dos referencias de audio
  const explosionSoundRef = useRef(null);
  const ebikeSoundRef = useRef(null);

  const handleCategoryClick = (event, categoryId, categoryName) => {
    onCategoryChange(categoryId);

    // Obtener la posición del clic relativo a la página
    const x = event.clientX;
    const y = event.clientY;

    // Guardar la posición del clic para el confetti
    setExplosionPosition({ x, y });

    // Mostrar confetti
    setIsExploding(true);

    // Reproducir el audio adecuado
    if (categoryName.toLowerCase().includes("e-bike")) {
      ebikeSoundRef.current.play(); // Reproducir sonido de e-bike
    } else {
      explosionSoundRef.current.play(); // Reproducir sonido de fuegos artificiales
    }

    setTimeout(() => setIsExploding(false), 2200); // Desactiva el confetti después de 2.2 segundos
  };

  return (
    <div className="relative">
      {/* Componente para el sonido de explosión de fuegos artificiales */}
      <audio
        ref={explosionSoundRef}
        src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2Ffirework.mp3?alt=media&token=f7cb205d-f6a4-4833-8996-08407fdea0ce"
      />

      {/* Componente para el sonido de e-bike */}
      <audio
        ref={ebikeSoundRef}
        src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedy%2Fsi%20soy%20ama%20%5B%20ezmp3.cc%20%5D.mp3?alt=media&token=3072ab7f-22bd-434f-a56a-2d5333b3c17e"
      />

      {/* Componente de confetti explosion */}
      {isExploding && (
        <div
          className="absolute left-0 top-0 z-10 pointer-events-none"
          style={{
            left: explosionPosition.x,
            top: explosionPosition.y,
            transform: "translate(-50%, -50%)",
          }}
        >
          <ConfettiExplosion
            particleCount={200}
            duration={2500}
            force={0.6}
            width={1000}
          />
        </div>
      )}

      <h2 className="text-lg font-semibold mb-4 flex items-center">
        <CalendarDays className="mr-2" />
        Selecciona tu categoría
      </h2>
      <div className="space-y-4">
        {categories.map((category) => (
          <div
            key={category.id}
            onClick={(e) => handleCategoryClick(e, category.id, category.name)}
            className={`p-4 border-4 rounded-lg text-center cursor-pointer transition-colors duration-300 ${
              selectedCategory === category.id ? "border-black" : "border-gray-300"
            } hover:border-black`}
          >
            <p className="text-md font-medium">{category.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryStep;
