import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import Typed from "typed.js";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

const Header = () => {
  const typedElement = useRef(null);
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const typed = new Typed(typedElement.current, {
      strings: ["Hobby", "Evento", "Aventura", "Parque", "Venta"],
      typeSpeed: 100,
      backSpeed: 50,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);

  const handleVideoClick = () => {
    setIsMuted(!isMuted);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  return (
    <Box
      component="header"
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        color: "black",
        padding: "20px 20px 0 20px", // Ajuste de padding, sin padding inferior
        boxSizing: "border-box",
        margin: 0, // Asegura que no hay margen en el contenedor principal
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: "800px",
          marginBottom: "20px",
        }}
      >
        <video
          ref={videoRef}
          src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/chava.mp4?alt=media&token=18c1b43b-03ed-44a4-8287-3f49eb0c2212"
          autoPlay
          loop
          muted
          onClick={handleVideoClick}
          style={{
            width: "100%",
            borderRadius: "15px", // Bordes redondeados
          }}
        />
        <IconButton
          onClick={handleVideoClick}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "#fff",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
          }}
        >
          {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
        </IconButton>
      </Box>

      <Box
        component="section"
        sx={{
          maxWidth: "800px",
          width: "100%",
          mb: 2,
          zIndex: 1,
          marginBottom: 0, // Asegura que no hay margen inferior en este contenedor
          paddingBottom: 0, // Elimina el padding inferior
        }}
      >
        <Typography
          variant="h2"
          component="h1"
          sx={{
            fontWeight: 700,
            color: "black",
            fontSize: "3rem",
            "@media (max-width: 600px)": {
              fontSize: "2rem",
            },
            marginBottom: 0, // Asegura que no hay margen inferior
          }}
        >
          Elevamos tu
          <br />
          <span ref={typedElement} style={{ color: "#555555" }}></span>
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            pt: 2,
            pb: 1,
            lineHeight: 1.6,
            color: "black",
            fontSize: "1.2rem",
            marginBottom: 0, // Asegura que no hay margen inferior
            paddingBottom: 0, // Elimina el padding inferior
          }}
        >
          Mide, compite y mejora en tiempo real con la máxima precisión y
          análisis de inteligencia artificial.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1.5,
          width: "100%",
          maxWidth: "300px",
          marginBottom: 0, // Ajuste del margin bottom para reducir separación
          paddingBottom: 0, // Elimina el padding inferior
        }}
      >
        <Button
          component={Link}
          to={"/Login"}
          variant="contained"
          sx={{
            width: "100%",
            px: 4,
            py: 1.5,
            fontSize: "1rem",
            textTransform: "capitalize",
            borderRadius: 2,
            color: "#fff",
            backgroundColor: "#14192d",
            "&:hover": {
              backgroundColor: "#0f1423",
            },
            marginBottom: 0, // Asegura que no hay margen inferior
          }}
        >
          Inicia Sesión
        </Button>
        <Button
          component={Link}
          to={"/SignUp"}
          variant="outlined"
          sx={{
            width: "100%",
            px: 4,
            py: 1.5,
            fontSize: "1rem",
            textTransform: "capitalize",
            borderRadius: 2,
            color: "black",
            borderColor: "black",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
              borderColor: "black",
            },
            marginBottom: 0, // Asegura que no hay margen inferior
          }}
        >
          Regístrate
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
