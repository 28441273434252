import React, { useState } from "react";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { Trash2 } from "lucide-react";
import Papa from "papaparse";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import axios from "axios";

function CsvUploader() {
  const [data, setData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          const sortedResult = result.data.sort((a, b) =>
            a.Time.localeCompare(b.Time)
          );
          setData(sortedResult); // Store parsed data in state
        },
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    } else {
      setData(null);
    }
  };

  const sendRunToApi = async (run) => {
    console.log("Sending run to API with data:", {
      RUID: run.RUID,
      time: run.Time,
      uid: run.UID,
    });
    try {
      const response = await axios.post(
        "https://logtofirestore-aetu2mr6pq-uc.a.run.app/logToFirestore",
        {
          RUID: run.RUID,
          time: run.Time,
          uid: run.UID,
        }
      );
      console.log(`API Response: ${response.data.message}`);
    } catch (error) {
      console.error("Error sending run to API:", error);
    }
  };

  const handleUploadRuns = async () => {
    if (!data) return;
    const selectedData = selectedRows.map((index) => data[index]); // Map selected indices to data
    for (const run of selectedData) {
      await sendRunToApi(run);
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }
    alert("Done!")
  };

  const handleSelectRow = (index) => {
    setSelectedRows((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index]
    );
  };

  const handleDeleteRow = (index) => {
    setData((prevData) => prevData.filter((_, i) => i !== index));
    setSelectedRows((prevSelected) => prevSelected.filter((i) => i !== index));
  };

  const handleSelectAllRuns = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]); // Deselect all
    } else {
      setSelectedRows(data.map((_, index) => index)); // Select all rows by index
    }
  };

  return (
    <div className="container py-4 flex flex-col items-center">
      <h2 className="text-lg font-semibold mb-2">Subir archivo CSV</h2>
      <input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        className="mb-2 p-2 border rounded-lg text-sm text-gray-700 bg-white cursor-pointer focus:outline-none"
      />
      <Button
        variant="contained"
        color="primary"
        className="mt-2"
        disabled={!data}
        onClick={handleUploadRuns}
      >
        Cargar archivo
      </Button>
      {data && data.length > 0 && (
        <TableContainer component={Paper} className="mt-6">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    checked={selectedRows.length === data.length}
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < data.length
                    }
                    onChange={handleSelectAllRuns}
                  />
                </TableCell>
                {Object.keys(data[0]).map((header) => (
                  <TableCell key={header} className="font-semibold">
                    {header}
                  </TableCell>
                ))}
                <TableCell className="font-semibold">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index} selected={selectedRows.includes(index)}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedRows.includes(index)}
                      onChange={() => handleSelectRow(index)}
                    />
                  </TableCell>
                  {Object.values(row).map((cell, cellIndex) => (
                    <TableCell key={cellIndex}>{cell}</TableCell>
                  ))}
                  <TableCell>
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteRow(index)}
                    >
                      <Trash2 size={20} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default CsvUploader;
