import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  TableContainer,
  Button,
  Input,
  useMediaQuery,
  useTheme,
  IconButton,
  Drawer,
  AppBar,
  Toolbar,
} from "@mui/material";
import {
  Wifi as WifiIcon,
  MyLocation as MyLocationIcon,
  CloudUpload as CloudUploadIcon,
  Menu as MenuIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import Papa from "papaparse";

const googleMapsApiKey = "AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const initialCenter = {
  lat: 29.1078886,
  lng: -110.9364619,
};

const LivespeedMap = () => {
  const [data, setData] = useState([]);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (results) => {
          const parsedData = results.data.map((row) => ({
            date: row.date,
            time: row.time,
            from: row.from,
            senderName: row["sender name"],
            senderLat: row["sender lat"] ? parseFloat(row["sender lat"]) : null,
            senderLong: row["sender long"]
              ? parseFloat(row["sender long"])
              : null,
            rxLat: row["rx lat"] ? parseFloat(row["rx lat"]) : null,
            rxLong: row["rx long"] ? parseFloat(row["rx long"]) : null,
            rxElevation: row["rx elevation"] || "No disponible",
            rxSnr: row["rx snr"] || "No disponible",
            distance: row.distance || "No disponible",
            hopLimit: row["hop limit"] || "No disponible",
            payload: row.payload || "No disponible",
          }));
          setData(parsedData); // No filtramos los datos por coordenadas para mostrar todo
        },
      });
    }
  };

  const handleMarkerClick = (point) => {
    setSelectedPoint(point);
    if (isMobile) {
      setDrawerOpen(true);
    }
  };

  const handleRowClick = (point) => {
    setSelectedPoint(point);
    if (isMobile) {
      setDrawerOpen(true);
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const renderPointInfo = () => (
    <Box my={2}>
      <Typography variant="h6">Información del Punto</Typography>
      <Typography>
        <WifiIcon /> Nombre: {selectedPoint.senderName}
      </Typography>
      <Typography>
        <MyLocationIcon /> Coordenadas:{" "}
        {selectedPoint.senderLat
          ? `${selectedPoint.senderLat}, ${selectedPoint.senderLong}`
          : "No disponible"}
      </Typography>
      <Typography>Fecha: {selectedPoint.date}</Typography>
      <Typography>Hora: {selectedPoint.time}</Typography>
      <Typography>Elevación: {selectedPoint.rxElevation}</Typography>
      <Typography>SNR: {selectedPoint.rxSnr}</Typography>
      <Typography>Distancia: {selectedPoint.distance}</Typography>
      <Typography>Payload: {selectedPoint.payload}</Typography>
    </Box>
  );

  return (
    <Container maxWidth="xl" sx={{ pb: 4 }}>
      <AppBar position="static" color="primary" sx={{ mb: 2 }}>
        <Toolbar>
          {isMobile && (
            <IconButton edge="start" color="inherit" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Livespeed Map
          </Typography>
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
            sx={{ bgcolor: "secondary.main" }}
          >
            Cargar CSV
            <Input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              sx={{ display: "none" }}
            />
          </Button>
        </Toolbar>
      </AppBar>

      <Box sx={{ height: 400, width: "100%", mb: 2 }}>
        <LoadScript googleMapsApiKey={googleMapsApiKey}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={
              selectedPoint
                ? {
                    lat: selectedPoint.senderLat || initialCenter.lat,
                    lng: selectedPoint.senderLong || initialCenter.lng,
                  }
                : initialCenter
            }
            zoom={10}
          >
            {data
              .filter((point) => point.senderLat && point.senderLong) // Mostrar solo los puntos con coordenadas válidas
              .map((point, index) => (
                <Marker
                  key={index}
                  position={{ lat: point.senderLat, lng: point.senderLong }}
                  onClick={() => handleMarkerClick(point)}
                  icon={{
                    url:
                      selectedPoint === point
                        ? "http://maps.google.com/mapfiles/ms/icons/blue-dot.png"
                        : "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                  }}
                />
              ))}
          </GoogleMap>
        </LoadScript>
      </Box>

      {!isMobile && selectedPoint && renderPointInfo()}

      <Paper elevation={3} sx={{ mt: 2, overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell>Fecha</TableCell>
                <TableCell>Hora</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Latitud</TableCell>
                <TableCell>Longitud</TableCell>
                <TableCell>Distancia</TableCell>
                <TableCell>Payload</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((point, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(point)}
                  sx={{
                    bgcolor:
                      selectedPoint === point ? "action.selected" : "inherit",
                    cursor: "pointer",
                    "&:hover": { bgcolor: "action.hover" },
                  }}
                >
                  <TableCell>{point.date}</TableCell>
                  <TableCell>{point.time}</TableCell>
                  <TableCell>{point.senderName}</TableCell>
                  <TableCell>{point.senderLat || "No disponible"}</TableCell>
                  <TableCell>{point.senderLong || "No disponible"}</TableCell>
                  <TableCell>{point.distance}</TableCell>
                  <TableCell>{point.payload}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Drawer
        anchor="bottom"
        open={isMobile && drawerOpen}
        onClose={toggleDrawer}
        PaperProps={{
          sx: { height: "50%" },
        }}
      >
        <Box sx={{ p: 2 }}>
          <IconButton
            onClick={toggleDrawer}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {selectedPoint && renderPointInfo()}
        </Box>
      </Drawer>
    </Container>
  );
};

export default LivespeedMap;
