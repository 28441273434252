import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Paper } from "@mui/material";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import ParkIcon from "@mui/icons-material/Park";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useNavigate, useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Analytics = () => {
  const navigate = useNavigate();
  const { uid } = useParams();
  const [eventCount, setEventCount] = useState(0);

  useEffect(() => {
    const fetchEventCount = async () => {
      try {
        const q = query(
          collection(db, "events"),
          where("organization", "==", uid)
        );
        const querySnapshot = await getDocs(q);
        setEventCount(querySnapshot.size);
        console.log("Cantidad de eventos: ", querySnapshot.size);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEventCount();
  }, [uid]);

  const data = {
    labels: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio"],
    datasets: [
      {
        label: "Revenue",
        data: [12000, 19000, 3000, 5000, 20000, 30000],
        borderColor: "#3f51b5",
        backgroundColor: "rgba(63, 81, 181, 0.5)",
      },
      {
        label: "Costos",
        data: [8000, 15000, 2000, 4000, 18000, 25000],
        borderColor: "#f50057",
        backgroundColor: "rgba(245, 0, 87, 0.5)",
      },
    ],
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              textAlign: "center",
              backgroundColor: "#000",
              color: "#fff",
            }}
          >
            <PedalBikeIcon sx={{ fontSize: 30, color: "#fff" }} />
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Corredores
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              15
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              textAlign: "center",
              backgroundColor: "#000",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={() => navigate(`/sedes/${uid}`)}
          >
            <ParkIcon sx={{ fontSize: 30, color: "#fff" }} />
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Sedes
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {eventCount}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              textAlign: "center",
              backgroundColor: "#000",
              color: "#fff",
            }}
          >
            <AttachMoneyIcon sx={{ fontSize: 30, color: "#fff" }} />
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Revenue
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              $50,000
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={3}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              textAlign: "center",
              backgroundColor: "#000",
              color: "#fff",
            }}
          >
            <MoneyOffIcon sx={{ fontSize: 30, color: "#fff" }} />
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              Costos
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              $30,000
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ mt: 4 }}>
        <Paper
          elevation={3}
          sx={{ p: 3, backgroundColor: "#000", color: "#fff" }}
        >
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Análisis Financiero
          </Typography>
          <Line data={data} />
        </Paper>
      </Box>
    </Box>
  );
};

export default Analytics;
