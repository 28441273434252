import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Paper,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  TextField,
  Button,
} from "@mui/material";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";

const SpeedCoinsCap = () => {
  const { user } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [totalCoins, setTotalCoins] = useState(0);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchUsersData = async () => {
      if (!user) {
        setError("Usuario no autenticado. Por favor, inicia sesión.");
        setLoading(false);
        return;
      }

      // Verificar si el usuario tiene el rol de admin
      if (!user.type?.includes("admin")) {
        setError("No tienes permiso para acceder a esta página.");
        setLoading(false);
        return;
      }

      console.log("Usuario autenticado:", user);

      try {
        const usersCollection = collection(db, "Users");
        const userDocs = await getDocs(usersCollection);
        let users = [];
        let total = 0;

        userDocs.forEach((doc) => {
          const data = doc.data();
          if (data.balance !== undefined) {
            const userBalance = Number(data.balance) || 0;
            users.push({
              uid: doc.id,
              name: data.name || "Usuario",
              balance: userBalance,
            });
            total += userBalance;
          }
        });

        users.sort((a, b) => b.balance - a.balance);
        setUsersData(users);
        setFilteredUsers(users);
        setTotalCoins(total);
      } catch (error) {
        console.error("Error al obtener los datos de los usuarios:", error);
        setError(
          "Error al obtener los datos de los usuarios. Por favor, intente nuevamente."
        );
      } finally {
        setLoading(false);
      }
    };

    if (user) {
      fetchUsersData();
    }
  }, [user]);

  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      setFilteredUsers(usersData);
    } else {
      const filtered = usersData.filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUsers(filtered);
    }
  };

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Alert severity="error">{error}</Alert>
        </Box>
      </Container>
    );
  }

  const topHolders = usersData.slice(0, 10);
  const chartData = {
    labels: topHolders.map((user) => `${user.name} (${user.uid})`),
    datasets: [
      {
        label: "SpeedCoins",
        data: topHolders.map((user) => user.balance),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
        ],
        borderColor: "#fff",
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 4, mt: 5 }}>
        <Typography variant="h4" gutterBottom align="center" color="primary">
          Capitalización de SpeedCoins
        </Typography>
        <Divider sx={{ mb: 3 }} />

        <Typography
          variant="h6"
          gutterBottom
          align="center"
          color="textSecondary"
        >
          Total de SpeedCoins en circulación: {totalCoins.toFixed(2)}
        </Typography>

        <Box display="flex" justifyContent="center" alignItems="center" my={3}>
          <TextField
            label="Buscar Usuario"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ mr: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleSearch}>
            Buscar
          </Button>
        </Box>

        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Posición</strong>
                </TableCell>
                <TableCell>
                  <strong>Nombre de Usuario</strong>
                </TableCell>
                <TableCell>
                  <strong>Dirección (UID)</strong>
                </TableCell>
                <TableCell align="right">
                  <strong>Balance de SpeedCoins</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user, index) => (
                <TableRow key={user.uid}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.uid}</TableCell>
                  <TableCell align="right">
                    ${user.balance.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ mt: 5 }}>
          <Typography variant="h5" gutterBottom align="center" color="primary">
            Top 10 Holders de SpeedCoins
          </Typography>
          <Pie data={chartData} />
        </Box>
      </Paper>
    </Container>
  );
};

export default SpeedCoinsCap;
