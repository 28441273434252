import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Grid,
  Paper,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const Sedes = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const q = query(
          collection(db, "events"),
          where("organization", "==", uid)
        );
        const querySnapshot = await getDocs(q);
        const fetchedEvents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [uid]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
        <Button
          variant="contained"
          startIcon={<ArrowBackIcon />}
          sx={{ mr: 2, backgroundColor: "#000", color: "#fff" }}
          onClick={() => navigate(-1)}
        >
          Regresar
        </Button>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Sedes
        </Typography>
      </Box>
      {events.length > 0 ? (
        <Grid container spacing={4} sx={{ mt: 2 }}>
          {events.map((event) => (
            <Grid item xs={12} sm={6} md={4} key={event.id}>
              <Paper
                elevation={4}
                sx={{
                  p: 3,
                  textAlign: "center",
                  backgroundColor: "#1e1e1e",
                  color: "#fff",
                  borderRadius: "15px",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {event.eventName}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Fecha: {new Date(event.dateTime).toLocaleDateString()}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Ubicación:{" "}
                  {event.location
                    ? `${event.location.lat}, ${event.location.lng}`
                    : "No especificada"}
                </Typography>
                {event.photo && (
                  <Box
                    component="img"
                    src={event.photo}
                    alt={event.eventName}
                    sx={{
                      width: "100%",
                      height: "350px",
                      objectFit: "cover",
                      mt: 2,
                      borderRadius: "10px",
                    }}
                  />
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
          No hay sedes registradas para esta organización.
        </Typography>
      )}
    </Container>
  );
};

export default Sedes;
