import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Search,
  Calendar,
  User,
  DollarSign,
  Coffee,
  ChevronDown,
} from "lucide-react";

const EventHome = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("upcoming");
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, "events");
        const eventsSnapshot = await getDocs(eventsCollection);
        const eventsData = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          dateTime: doc.data().dateTime ? new Date(doc.data().dateTime) : null,
        }));
        const sortedEvents = eventsData.sort((a, b) => b.dateTime - a.dateTime);
        setEvents(sortedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (eventId) => navigate(`/event/${eventId}`);
  const handleResultsClick = (eventId) => navigate(`/leaderboard/${eventId}`);

  const isEventPast = (eventDate) => eventDate < new Date();
  const isEventToday = (eventDate) =>
    eventDate.toDateString() === new Date().toDateString();

  const formatDate = (date) =>
    new Intl.DateTimeFormat("es-ES", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    }).format(date);

  const handleSearchChange = (event) =>
    setSearchTerm(event.target.value.toLowerCase());

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter || filter);
    setFilterOpen(false);
  };

  const toggleFilter = () => setFilterOpen((prev) => !prev);

  const getCountdown = (eventDate) => {
    const diff = eventDate - new Date();
    if (diff <= 0) return null;
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${days}d ${hours}h ${minutes}m`;
  };

  const filteredEvents = events
    .filter((event) => !event.invisible)
    .filter((event) =>
      filter === "upcoming"
        ? !isEventPast(event.dateTime) && !isEventToday(event.dateTime)
        : filter === "ongoing"
        ? isEventToday(event.dateTime)
        : isEventPast(event.dateTime)
    )
    .filter((event) => event.eventName.toLowerCase().includes(searchTerm))
    .sort((a, b) =>
      filter === "upcoming" ? a.dateTime - b.dateTime : b.dateTime - a.dateTime
    );

  return (
    <div className="container mx-auto px-4 py-8 bg-white text-gray-900">
      <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">
        Eventos
      </h2>

      {/* Search Bar */}
      <div className="flex justify-center mb-6">
        <div className="relative w-full max-w-md">
          <input
            type="text"
            placeholder="Buscar eventos por título"
            value={searchTerm}
            onChange={handleSearchChange}
            className="w-full p-3 pl-4 rounded-full border border-gray-400 focus:outline-none bg-white text-gray-900 pr-12 shadow-sm"
          />
          <Search className="absolute top-3 right-4 text-gray-500" size={20} />
        </div>
      </div>

      {/* Filter Button */}
      <div className="flex justify-center mb-8">
        <button
          onClick={toggleFilter}
          className="flex items-center px-5 py-2 font-semibold rounded-full bg-gray-800 text-white shadow-md"
        >
          Filtrar Eventos
          <ChevronDown className="ml-2" />
        </button>
      </div>

      {/* Filter Options */}
      {filterOpen && (
        <div className="flex justify-center mb-6 space-x-4">
          <button
            onClick={() => handleFilterChange("upcoming")}
            className={`px-4 py-2 font-semibold rounded-full shadow ${
              filter === "upcoming"
                ? "bg-gray-800 text-white"
                : "bg-gray-200 text-gray-800"
            }`}
          >
            Eventos Futuros
          </button>
          <button
            onClick={() => handleFilterChange("ongoing")}
            className={`px-4 py-2 font-semibold rounded-full shadow ${
              filter === "ongoing"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-800"
            }`}
          >
            En Curso
          </button>
          <button
            onClick={() => handleFilterChange("past")}
            className={`px-4 py-2 font-semibold rounded-full shadow ${
              filter === "past"
                ? "bg-gray-800 text-white"
                : "bg-gray-200 text-gray-800"
            }`}
          >
            Eventos Pasados
          </button>
        </div>
      )}

      {/* Events Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {loading
          ? [...Array(6)].map((_, index) => (
              <div
                key={index}
                className="w-full h-[500px] bg-gray-300 animate-pulse rounded-lg"
              />
            ))
          : filteredEvents.map((event) => {
              const isPast = isEventPast(event.dateTime);
              const isToday = isEventToday(event.dateTime);
              const countdown = getCountdown(event.dateTime);

              return (
                <div
                  key={event.id}
                  onClick={() => handleEventClick(event.id)}
                  className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden transition-transform duration-300 hover:scale-105 cursor-pointer"
                >
                  {/* Event Image */}
                  <img
                    src={event.photo}
                    alt={`Evento ${event.eventName}`}
                    className="w-full h-[300px] object-cover"
                  />

                  {/* Event Details */}
                  <div className="p-4 bg-gray-900 text-white">
                    <h3 className="text-xl font-bold mb-2">
                      {event.eventName}
                    </h3>
                    <div className="flex items-center text-gray-300 mb-2">
                      <Calendar className="mr-2" size={16} />
                      <span>{formatDate(event.dateTime)}</span>
                    </div>
                    <div className="flex items-center text-gray-300 mb-4">
                      <User className="mr-2" size={16} />
                      <span>{event.organization}</span>
                    </div>
                    <div className="flex justify-between items-center mb-4">
                      <span
                        className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-bold ${
                          event.price > 0 ? "bg-gray-700" : "bg-green-600"
                        } text-white`}
                      >
                        {event.price > 0 ? `$${event.price}` : "Gratuito"}
                        {event.price > 0 ? (
                          <DollarSign className="ml-1" size={16} />
                        ) : (
                          <Coffee className="ml-1" size={16} />
                        )}
                      </span>
                      <div className="text-xs font-semibold px-3 py-1 rounded-full bg-gray-700 text-white">
                        {isPast
                          ? "Evento finalizado"
                          : isToday
                          ? "En curso"
                          : countdown}
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      {(isPast || isToday) && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleResultsClick(event.id);
                          }}
                          className="px-4 py-2 text-sm font-bold text-gray-800 border border-white rounded-lg bg-white bg-opacity-20 hover:bg-opacity-30 transition-colors"
                        >
                          Resultados
                        </button>
                      )}
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEventClick(event.id);
                        }}
                        className="px-4 py-2 text-sm font-bold rounded-lg bg-white text-gray-800 border border-gray-300 hover:bg-gray-100 transition-colors"
                      >
                        {isPast ? "Ver detalles" : "Registrarte"}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
};

export default EventHome;
