import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  Typography,
  Box,
  Avatar,
  Fade,
  Paper,
} from "@mui/material";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import TimerIcon from "@mui/icons-material/Timer";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import ShareIcon from "@mui/icons-material/Share";
import { toPng } from "html-to-image";

const lightTheme = {
  background: "#ffffff",
  paper: "#f5f5f5",
  primary: "#000000",
  secondary: "#333333",
  accent: "#e0e0e0",
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    backgroundColor: lightTheme.background,
    color: lightTheme.primary,
    borderRadius: "16px",
    maxWidth: "600px",
    width: "100%",
  },
}));

const RunnerBox = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
  padding: "12px",
  borderRadius: "12px",
  backgroundColor: lightTheme.paper,
  marginBottom: "16px",
  border: `1px solid ${lightTheme.accent}`,
  "&:hover": {
    transform: "translateY(-2px)",
    transition: "transform 0.3s ease",
  },
}));

const ResultBox = styled(Paper)(({ theme }) => ({
  textAlign: "center",
  padding: "16px",
  borderRadius: "12px",
  backgroundColor: lightTheme.paper,
  border: `1px solid ${lightTheme.accent}`,
  marginTop: "16px",
}));

const StyledSelect = styled(Select)({
  width: "100%",
  color: lightTheme.primary,
  "& .MuiSelect-select": {
    backgroundColor: lightTheme.paper,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: lightTheme.accent,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: lightTheme.primary,
  },
});

// Helper function to download images as Blobs and convert them to Base64
const downloadImageAsBase64 = async (url) => {
  try {
    const response = await fetch(url, { mode: "no-cors" });
    const blob = await response.blob();
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error downloading image:", error);
    return "/default-avatar.jpg"; // Fallback image
  }
};

const ComparePopup = ({
  open,
  onClose,
  runs,
  getTimeDifference,
  ownerData,
  leaderboardLink,
}) => {
  const [selectedRunner1, setSelectedRunner1] = useState("");
  const [selectedRunner2, setSelectedRunner2] = useState("");
  const [timeDifference, setTimeDifference] = useState(null);
  const [animatedTime, setAnimatedTime] = useState(0);
  const popupRef = useRef(null);
  const [runner1PhotoUrl, setRunner1PhotoUrl] = useState("/default-avatar.jpg");
  const [runner2PhotoUrl, setRunner2PhotoUrl] = useState("/default-avatar.jpg");

  const availableRunner2Options = runs.filter(
    (run) => run.id !== selectedRunner1
  );

  useEffect(() => {
    if (
      selectedRunner1 &&
      selectedRunner2 &&
      selectedRunner1 !== selectedRunner2
    ) {
      const runner1 = runs.find((run) => run.id === selectedRunner1);
      const runner2 = runs.find((run) => run.id === selectedRunner2);
      const differenceInMs = getTimeDifference(
        runner1.ElapsedTime,
        runner2.ElapsedTime
      );
      setTimeDifference(differenceInMs);
    }
  }, [selectedRunner1, selectedRunner2, runs, getTimeDifference]);

  useEffect(() => {
    if (timeDifference !== null) {
      let start = 0;
      const duration = 1000;
      const increment = Math.abs(timeDifference) / (duration / 10);
      const interval = setInterval(() => {
        start += increment;
        if (start >= Math.abs(timeDifference)) {
          clearInterval(interval);
          setAnimatedTime(Math.abs(timeDifference));
        } else {
          setAnimatedTime(start);
        }
      }, 10);

      return () => clearInterval(interval);
    }
  }, [timeDifference]);

  const formatTimeDifference = (differenceInMs) => {
    const ms = Math.floor(differenceInMs % 1000);
    const seconds = Math.floor((differenceInMs / 1000) % 60);
    const minutes = Math.floor((differenceInMs / (1000 * 60)) % 60);

    if (minutes > 0) {
      return `${minutes}m ${seconds}s ${ms}ms`;
    } else if (seconds > 0) {
      return `${seconds}s ${ms}ms`;
    } else {
      return `${ms}ms`;
    }
  };

  const removeLeadingZeros = (timeString) => {
    return timeString.replace(/^0+/, "");
  };

  // Fetch the user photos for display
  useEffect(() => {
    if (selectedRunner1) {
      const runner1 = runs.find((run) => run.id === selectedRunner1);
      const runner1Url =
        ownerData[runner1.NFCIdentifier]?.PhotoUrl || "/default-avatar.jpg";
      setRunner1PhotoUrl(runner1Url);
    }

    if (selectedRunner2) {
      const runner2 = runs.find((run) => run.id === selectedRunner2);
      const runner2Url =
        ownerData[runner2.NFCIdentifier]?.PhotoUrl || "/default-avatar.jpg";
      setRunner2PhotoUrl(runner2Url);
    }
  }, [selectedRunner1, selectedRunner2, ownerData, runs]);

  const handleShareWhatsApp = async () => {
    if (popupRef.current) {
      try {
        // Fetch the images as Base64 before generating the screenshot
        const runner1PhotoBase64 = await downloadImageAsBase64(runner1PhotoUrl);
        const runner2PhotoBase64 = await downloadImageAsBase64(runner2PhotoUrl);

        // Temporarily replace the avatars with the Base64 images
        setRunner1PhotoUrl(runner1PhotoBase64);
        setRunner2PhotoUrl(runner2PhotoBase64);

        // Generate the screenshot
        const dataUrl = await toPng(popupRef.current, { useCORS: true });
        const runner1Name =
          ownerData[
            runs.find((run) => run.id === selectedRunner1)?.NFCIdentifier
          ]?.Owner || "Corredor 1";
        const runner2Name =
          ownerData[
            runs.find((run) => run.id === selectedRunner2)?.NFCIdentifier
          ]?.Owner || "Corredor 2";
        const timeDiff = formatTimeDifference(Math.abs(timeDifference));

        const message = `${runner1Name} le ganó a ${runner2Name} por ${timeDiff}. Checa los resultados en: ${leaderboardLink}`;

        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          message
        )}`;

        window.open(whatsappUrl, "_blank");

        const link = document.createElement("a");
        link.download = "comparador-ciclistas.png";
        link.href = dataUrl;
        link.click();

        // Restore the original image URLs after generating the screenshot
        setRunner1PhotoUrl(
          ownerData[
            runs.find((run) => run.id === selectedRunner1)?.NFCIdentifier
          ]?.PhotoUrl || "/default-avatar.jpg"
        );
        setRunner2PhotoUrl(
          ownerData[
            runs.find((run) => run.id === selectedRunner2)?.NFCIdentifier
          ]?.PhotoUrl || "/default-avatar.jpg"
        );
      } catch (err) {
        console.error("Error al generar la imagen o compartir", err);
      }
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div ref={popupRef}>
        <DialogTitle
          sx={{
            textAlign: "center",
            pb: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <EmojiEventsIcon sx={{ color: "#ffd700", fontSize: 24 }} />
          <Typography variant="h6" component="span" sx={{ fontWeight: "bold" }}>
            Comparador de Ciclistas
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ bgcolor: lightTheme.background }}>
          <RunnerBox elevation={3}>
            <Avatar
              src={runner1PhotoUrl}
              sx={{
                width: 50,
                height: 50,
                background: "linear-gradient(45deg, #FF6B6B 30%, #FF8E53 90%)",
              }}
            />
            <StyledSelect
              value={selectedRunner1}
              onChange={(e) => setSelectedRunner1(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Selecciona el primer ciclista
              </MenuItem>
              {runs.map((run) => (
                <MenuItem key={run.id} value={run.id}>
                  {ownerData[run.NFCIdentifier]?.Owner || "Desconocido"} -{" "}
                  {removeLeadingZeros(run.ElapsedTime)}
                </MenuItem>
              ))}
            </StyledSelect>
          </RunnerBox>

          <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
            <CompareArrowsIcon
              sx={{ color: lightTheme.secondary, fontSize: 30 }}
            />
          </Box>

          <RunnerBox elevation={3}>
            <Avatar
              src={runner2PhotoUrl}
              sx={{
                width: 50,
                height: 50,
                background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
              }}
            />
            <StyledSelect
              value={selectedRunner2}
              onChange={(e) => setSelectedRunner2(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Selecciona el segundo ciclista
              </MenuItem>
              {availableRunner2Options.map((run) => (
                <MenuItem key={run.id} value={run.id}>
                  {ownerData[run.NFCIdentifier]?.Owner || "Desconocido"} -{" "}
                  {removeLeadingZeros(run.ElapsedTime)}
                </MenuItem>
              ))}
            </StyledSelect>
          </RunnerBox>

          {timeDifference !== null && (
            <Fade in={Boolean(timeDifference)}>
              <ResultBox elevation={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                    mb: 1,
                  }}
                >
                  <TimerIcon
                    sx={{ color: lightTheme.secondary, fontSize: 20 }}
                  />
                  <Typography variant="h6" sx={{ color: lightTheme.secondary }}>
                    Diferencia de Tiempo
                  </Typography>
                </Box>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    background:
                      "linear-gradient(45deg, #FF6B6B 30%, #2196F3 90%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  {formatTimeDifference(Math.abs(animatedTime))}
                </Typography>
              </ResultBox>
            </Fade>
          )}
        </DialogContent>
      </div>

      <DialogActions sx={{ p: 2, bgcolor: lightTheme.background }}>
        <Button
          onClick={handleShareWhatsApp}
          variant="contained"
          sx={{
            bgcolor: lightTheme.primary,
            color: lightTheme.background,
            "&:hover": {
              bgcolor: "#333333",
            },
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: "bold",
            mr: 1,
          }}
          startIcon={<ShareIcon />}
        >
          Compartir
        </Button>
        <Button
          onClick={onClose}
          variant="contained"
          fullWidth
          sx={{
            bgcolor: lightTheme.primary,
            color: lightTheme.background,
            "&:hover": {
              bgcolor: "#333333",
            },
            py: 1,
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Cerrar
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default ComparePopup;
