import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode.react";
import { toast } from "react-hot-toast";
import {
  X,
  Bike,
  Ticket,
  CheckCircle,
  Hourglass,
  XCircle,
  CreditCard,
} from "lucide-react";

const MyTickets = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState(null);
  const [openQR, setOpenQR] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [ticketToDelete, setTicketToDelete] = useState(null);

  useEffect(() => {
    const fetchTickets = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "orders"),
          where("userId", "==", user.uid)
        );
        const querySnapshot = await getDocs(q);

        const ticketsData = querySnapshot.docs.map((doc) => {
          const docStatus = doc.data().status;
          let parsedStatus =
            docStatus === "approved"
              ? "Pagado"
              : docStatus === "in_process"
              ? "En Proceso"
              : "No Pagado";
          return {
            id: doc.id,
            status: parsedStatus,
            total: doc.data().total,
            categoryId: doc.data().categoryId,
            addons: doc.data().addons,
            userId: doc.data().userId,
            type: doc.data().type,
            itemId: doc.data().itemId,
          };
        });
        setTickets(ticketsData);
      } catch (error) {
        console.error("Error al obtener los tickets:", error);
        setError("Error al obtener los tickets. Intente nuevamente.");
      } finally {
        setLoading(false);
      }
    };

    if (user?.uid) {
      fetchTickets();
    }
  }, [user]);

  const handlePayEvent = (id) => {
    navigate(`/checkout/${id}`);
  };

  const handleQRClick = (ticket) => {
    setSelectedTicket(ticket);
    setOpenQR(true);
  };

  const handleCloseQR = () => {
    setOpenQR(false);
    setSelectedTicket(null);
  };

  const handleDeleteTicket = async () => {
    try {
      await deleteDoc(doc(db, "orders", ticketToDelete.id));
      setTickets(tickets.filter((ticket) => ticket.id !== ticketToDelete.id));
      toast.success("Ticket eliminado correctamente.");
      setShowDeleteConfirmation(false);
    } catch (error) {
      console.error("Error al eliminar el ticket:", error);
      toast.error("No se pudo eliminar el ticket. Intente nuevamente.");
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-16 h-16 border-4 border-black border-dashed rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return <div className="text-center p-4 text-lg text-red-600">{error}</div>;
  }

  if (tickets.length === 0) {
    return (
      <div className="text-center p-4 text-lg text-black">
        No tienes tickets
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-6">Mis Entradas</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {tickets.map((ticket) => (
          <div
            key={ticket.id}
            className="bg-white rounded-lg shadow-lg p-4 flex flex-col"
          >
            <div className="flex justify-between items-center mb-4">
              <Bike className="w-6 h-6 text-gray-700" />
              <Ticket className="w-6 h-6 text-gray-700" />
            </div>
            <h2 className="text-xl font-semibold mb-2">Evento</h2>
            <p className="text-gray-600">Orden ID: {ticket.id}</p>
            <p className="text-gray-800 text-lg font-bold">
              Total: ${ticket.total}
            </p>

            <div
              className={`mt-4 p-2 rounded-lg flex items-center gap-2 font-bold text-sm ${
                ticket.status === "Pagado"
                  ? "bg-green-100 text-green-700"
                  : ticket.status === "En Proceso"
                  ? "bg-yellow-100 text-yellow-700"
                  : "bg-red-100 text-red-700"
              }`}
            >
              {ticket.status === "Pagado" ? (
                <CheckCircle className="w-4 h-4" />
              ) : ticket.status === "En Proceso" ? (
                <Hourglass className="w-4 h-4" />
              ) : (
                <XCircle className="w-4 h-4" />
              )}
              {ticket.status}
            </div>

            <div className="mt-4">
              <h3 className="font-semibold text-gray-700 mb-2">Addons:</h3>
              {ticket.addons && ticket.addons.length > 0 ? (
                <ul className="text-gray-600">
                  {ticket.addons.map((addon, index) => (
                    <li
                      key={index}
                      className="border-b border-gray-200 pb-2 mb-2"
                    >
                      <p>{addon.name}</p>
                      {addon.description && (
                        <p className="text-xs">{addon.description}</p>
                      )}
                      {addon.price && (
                        <p className="text-xs">Precio: ${addon.price}</p>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-gray-500 text-sm">
                  No hay Addons disponibles
                </p>
              )}
            </div>

            {ticket.status === "Pagado" ? (
              <button
                onClick={() => handleQRClick(ticket)}
                className="mt-4 bg-black text-white py-2 px-4 rounded-lg flex items-center justify-center"
              >
                Ver QR
              </button>
            ) : (
              <div className="mt-4 flex gap-2">
                <button
                  onClick={() => handlePayEvent(ticket.id)}
                  className="bg-blue-600 text-white py-2 px-4 rounded-lg flex items-center justify-center gap-2 w-full"
                >
                  <CreditCard className="w-4 h-4" /> Pagar
                </button>
                <button
                  onClick={() => {
                    setTicketToDelete(ticket);
                    setShowDeleteConfirmation(true);
                  }}
                  className="bg-red-600 text-white py-2 px-4 rounded-lg flex items-center justify-center gap-2 w-full"
                >
                  <X className="w-4 h-4" /> Eliminar
                </button>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* QR Code Dialog */}
      {openQR && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <button
              onClick={handleCloseQR}
              className="text-gray-600 float-right"
            >
              <X className="w-6 h-6" />
            </button>
            {selectedTicket && (
              <QRCode value={`Ticket ID: ${selectedTicket.id}`} size={256} />
            )}
          </div>
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      {showDeleteConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-xs w-full">
            <h2 className="text-lg font-bold mb-4">
              Confirmación de eliminación
            </h2>
            <p className="text-gray-700 mb-4">
              ¿Estás seguro que deseas eliminar este ticket?
            </p>
            <div className="flex gap-4">
              <button
                onClick={handleDeleteTicket}
                className="bg-red-600 text-white py-2 px-4 rounded-lg w-full"
              >
                Eliminar
              </button>
              <button
                onClick={() => setShowDeleteConfirmation(false)}
                className="bg-gray-300 text-black py-2 px-4 rounded-lg w-full"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyTickets;
