import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Alert,
  Button,
  Card,
  CardContent,
  CardMedia,
  TextField,
  Rating,
  Zoom,
} from "@mui/material";
import RedeemIcon from "@mui/icons-material/Redeem";

const RedeemGiftCard = () => {
  const { uid } = useParams();
  const { user, loading: authLoading } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [giftcard, setGiftcard] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [zoomIn, setZoomIn] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(0);
  const [showReviewBox, setShowReviewBox] = useState(false);
  const navigate = useNavigate();
  const giftImageUrl =
    "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/giftcards%2Fd5e72cf2-06c6-4d82-b32b-63914788f9d2-removebg-preview.png?alt=media&token=d0d1755b-016d-4dc9-9c0e-69889ec7aab3";

  useEffect(() => {
    const fetchGiftcard = async () => {
      if (authLoading) return;

      if (!user) {
        navigate("/login");
        return;
      }

      try {
        const giftcardRef = doc(db, "Giftcards", uid);
        const giftcardDoc = await getDoc(giftcardRef);

        if (giftcardDoc.exists()) {
          const giftcardData = giftcardDoc.data();
          if (giftcardData.active && !giftcardData.redeemtime) {
            setGiftcard({ id: giftcardDoc.id, ...giftcardData });
            if (giftcardData.review) setShowReviewBox(true);
          } else {
            setErrorMessage("Este cupón ya ha sido canjeado o no está activo.");
          }
        } else {
          setErrorMessage("No se encontró el cupón solicitado.");
        }
      } catch (error) {
        console.error("Error al obtener el cupón:", error);
        setErrorMessage(
          "Error al obtener el cupón. Por favor, intente nuevamente."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchGiftcard();
  }, [user, authLoading, uid, navigate]);

  const handleRedeem = async () => {
    if (!giftcard) return;

    try {
      const giftcardRef = doc(db, "Giftcards", giftcard.id);
      await updateDoc(giftcardRef, {
        active: false,
        redeemtime: serverTimestamp(),
        redeemUID: user.uid,
        redeemedBy: {
          name: user.displayName || "Usuario",
          email: user.email,
        },
      });

      const userRef = doc(db, "Users", user.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const newBalance = (userData.balance || 0) + giftcard.amount;
        await updateDoc(userRef, {
          balance: newBalance,
        });
      }

      const transactionRef = doc(db, "coinstransactions", giftcard.id);
      await setDoc(transactionRef, {
        amount: giftcard.amount,
        comment: "Cupon",
        date: serverTimestamp(),
        receiverUID: user.uid,
        senderUID: "4lh37bi7FjWvHVvK9GIMLDy6TX53",
      });

      setSuccessMessage(
        `Has canjeado ${giftcard.amount} SpeedCoins exitosamente!`
      );
      setZoomIn(true);
    } catch (error) {
      console.error("Error al canjear el cupón:", error);
      setErrorMessage(
        "Error al canjear el cupón. Por favor, intente nuevamente."
      );
    }
  };

  const handleReviewSubmit = async () => {
    if (rating > 0 && reviewText.trim()) {
      try {
        const reviewRef = doc(
          db,
          "GiftcardReviews",
          `${giftcard.id}_${user.uid}`
        );
        await setDoc(reviewRef, {
          uid: user.uid,
          name: user.displayName || "Usuario",
          stars: rating,
          review: reviewText,
          timestamp: serverTimestamp(),
        });

        setSuccessMessage("Gracias por tu opinión!");
        setShowReviewBox(false);
      } catch (error) {
        console.error("Error al enviar la opinión:", error);
        setErrorMessage(
          "Error al enviar la opinión. Por favor, intente nuevamente."
        );
      }
    } else {
      setErrorMessage(
        "Por favor, selecciona una calificación y escribe un comentario."
      );
    }
  };

  if (authLoading || loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (errorMessage) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box mt={5} textAlign="center">
        <Typography
          variant="h4"
          gutterBottom
          align="center"
          color="primary"
          sx={{ fontWeight: "bold" }}
        >
          Canjea tu GiftCard
        </Typography>
        {successMessage ? (
          <Alert severity="success" sx={{ mt: 3, fontSize: "1.2em" }}>
            {successMessage}
          </Alert>
        ) : (
          <Zoom
            in={!zoomIn}
            style={{ transitionDelay: zoomIn ? "500ms" : "0ms" }}
          >
            <Card
              sx={{
                maxWidth: 345,
                margin: "0 auto",
                borderRadius: 3,
                boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.07)",
                },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={giftImageUrl}
                alt="Gift Box"
                sx={{ borderRadius: "8px", mt: 2 }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  gutterBottom
                  sx={{ fontWeight: "medium" }}
                >
                  {giftcard.amount} SpeedCoins <br />#{giftcard.id.slice(-6)}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRedeem}
                  startIcon={<RedeemIcon />}
                  sx={{
                    mt: 3,
                    fontSize: "1em",
                    padding: "12px 24px",
                    borderRadius: "30px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                >
                  Canjear GiftCard
                </Button>
              </CardContent>
            </Card>
          </Zoom>
        )}
        {showReviewBox && (
          <Box
            mt={4}
            p={2}
            sx={{
              backgroundColor: "#f4f6f8",
              borderRadius: "12px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
              Nos encantaría recibir su opinión para seguir mejorando. Sabemos
              lo importante que es escuchar sus comentarios.
            </Typography>
            <Rating
              name="rating"
              value={rating}
              onChange={(event, newValue) => setRating(newValue)}
              sx={{ fontSize: "2em" }}
            />
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="Escribe tu comentario aquí"
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
              sx={{
                mt: 2,
                backgroundColor: "#fff",
                borderRadius: "8px",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
              }}
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReviewSubmit}
              sx={{
                mt: 2,
                fontSize: "1em",
                padding: "10px 20px",
                borderRadius: "25px",
              }}
            >
              Enviar opinión
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default RedeemGiftCard;
