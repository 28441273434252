// Revenue.js
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

const Revenue = () => {
  const [totalRevenue, setTotalRevenue] = useState(0);

  useEffect(() => {
    const fetchTotalRevenue = async () => {
      try {
        const approvedOrdersQuery = query(
          collection(db, "orders"),
          where("status", "==", "approved")
        );
        const ordersSnapshot = await getDocs(approvedOrdersQuery);
        let revenue = 0;
        ordersSnapshot.forEach((doc) => {
          revenue += doc.data().total;
        });
        setTotalRevenue(revenue);
      } catch (error) {
        console.error("Error fetching revenue:", error);
      }
    };

    fetchTotalRevenue();
  }, []);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition">
      <h3 className="text-xl font-bold text-gray-700">Revenue</h3>
      <p className="text-2xl font-semibold text-purple-600 mt-2">
        ${totalRevenue}
      </p>
    </div>
  );
};

export default Revenue;
