import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Collapse,
  List,
  ListItemButton,
  ListItem,
  ListItemText,
} from "@mui/material";
import { FieldArray, Form, Formik } from "formik";
import { addonSchema } from "./EventFormSchemas";
import CloseIcon from "@mui/icons-material/Close";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import FormInput from "../Forms/FormInput";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";

function EventAddons({ addons, setFieldValue }) {
  const [openModal, setOpenModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [eventIncludedAddon, setEventIncludedAddon] = useState(false);

  const handleOpen = () => setOpenModal((prev) => !prev);

  const onSubmit = (values) => {
    setFieldValue("addons", [...addons, values]);
    setOpenModal(false);
  };

  const handleImageChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue("image", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteAddon = (addon) => {
    setFieldValue(
      "addons",
      addons.filter((a) => a !== addon)
    );
  };

  return (
    <Box>
      <Box sx={{ bgcolor: "#F1F1F1", borderRadius: 2, p: 2 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography>Addons</Typography>
          <Button variant="outlined" onClick={handleOpen}>
            Agregar Addon
          </Button>
        </Stack>
        {addons.length > 0 ? (
          <Box>
            <Divider sx={{ my: 1 }} />
            <List>
              {addons.map((a, addonIndex) => (
                <AddedAddonListItem
                  key={a.id}
                  addon={a}
                  handleDelete={() => handleDeleteAddon(a)}
                />
              ))}
            </List>
          </Box>
        ) : null}
      </Box>
      <Modal
        open={openModal}
        onClose={handleOpen}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            bgcolor: "white",
            p: 2,
            borderRadius: 4,
            m: 2,
            width: "100%",
            maxWidth: 600,
            overflowY: "auto",
            maxHeight: "95%",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6" component="h2">
              Nuevo Addon
            </Typography>
            <IconButton onClick={handleOpen}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Formik
            initialValues={{
              name: "",
              description: "",
              price: 0,
              image: "",
              required: false,
              variants: [],
            }}
            onSubmit={onSubmit}
            validationSchema={addonSchema}
          >
            {({ values, handleBlur, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <FormInput fullWidth name="name" label="Nombre" />
                  <FormInput fullWidth name="description" label="Descripción" />
                  <FormInput
                    fullWidth
                    disabled={eventIncludedAddon}
                    name="price"
                    type="number"
                    label="Precio"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="required"
                        checked={values.required}
                        onChange={(e) => {
                          if (!eventIncludedAddon) {
                            setFieldValue("required", e.target.checked);
                          }
                        }}
                        onBlur={handleBlur}
                      />
                    }
                    label="Addon obligatorio"
                  />
                  <Box>
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<PhotoCameraIcon />}
                    >
                      Imagen
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={(event) =>
                          handleImageChange(event, setFieldValue)
                        }
                      />
                    </Button>
                    {imagePreview && (
                      <Stack justifyContent="center" mt={2} spacing={1}>
                        <img
                          src={imagePreview}
                          alt="Vista previa del addon"
                          style={{
                            objectFit: "contain",
                            maxHeight: 250,
                            maxWidth: "100%",
                            borderRadius: "8px",
                          }}
                        />
                        <Button
                          fullWidth
                          variant="outlined"
                          color="warning"
                          onClick={() => {
                            setFieldValue("image", "");
                            setImagePreview("");
                          }}
                        >
                          Borrar foto
                        </Button>
                      </Stack>
                    )}
                  </Box>

                  <FieldArray name="variants">
                    {({ push, remove }) => (
                      <Stack
                        spacing={1}
                        sx={{ bgcolor: "#F1F1F1", p: 1, borderRadius: 2 }}
                      >
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography variant="h6">Variantes</Typography>
                          <Button
                            type="button"
                            variant="contained"
                            onClick={() =>
                              push({
                                name: "",
                                options: [],
                              })
                            }
                          >
                            Agregar variante
                          </Button>
                        </Stack>
                        <Stack spacing={1}>
                          {values.variants.map((variant, index) => (
                            <VariantsListItem
                              key={variant.name}
                              variant={variant}
                              index={index}
                              remove={remove}
                            />
                          ))}
                        </Stack>
                      </Stack>
                    )}
                  </FieldArray>

                  <Divider />
                  <Tooltip title="Esto podría ser, por ejemplo, una camiseta conmemorativa del evento">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="eventIncludedAddon"
                          checked={eventIncludedAddon}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setEventIncludedAddon(true);
                              setFieldValue("required", e.target.checked);
                              setFieldValue("price", 0);
                            } else {
                              setEventIncludedAddon(false);
                            }
                          }}
                          onBlur={handleBlur}
                        />
                      }
                      label="Addon incluido por el evento"
                    />
                  </Tooltip>
                  <Button variant="contained" type="submit">
                    Agregar Addon
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Modal>
    </Box>
  );
}

export default EventAddons;

const VariantsListItem = ({ variant, index, remove }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Box sx={{ px: 1, py: 2, border: "1px solid #909090", borderRadius: 2 }}>
      <Stack key={index} spacing={1}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FormInput
            fullWidth
            label="Nombre de la variante"
            name={`variants[${index}].name`}
            placeholder="Nombre de la variante"
          />
          <IconButton color="warning" onClick={() => remove(index)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
        <FieldArray name={`variants[${index}].options`}>
          {({ push, remove }) => (
            <Stack spacing={1}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography>Opciones</Typography>
                <Button
                  variant="outlined"
                  onClick={handleOpen}
                  endIcon={open ? <ExpandLess /> : <ExpandMore />}
                >
                  Ver
                </Button>
              </Stack>

              <Collapse in={open} timeout="auto" unmountOnExit>
                <Stack spacing={1}>
                  {variant.options.map((option, optIndex) => (
                    <Stack direction="row" key={optIndex}>
                      <FormInput
                        label={`Opción #${optIndex + 1}`}
                        fullWidth
                        size="small"
                        name={`variants[${index}].options[${optIndex}].name`}
                        placeholder="Escribe la opción aquí"
                      />
                      <IconButton onClick={() => remove(optIndex)}>
                        <DeleteIcon />
                      </IconButton>
                    </Stack>
                  ))}
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() =>
                      push({
                        name: "",
                        image: "",
                      })
                    }
                  >
                    Agregar opción
                  </Button>
                </Stack>
              </Collapse>
            </Stack>
          )}
        </FieldArray>
      </Stack>
    </Box>
  );
};

const AddedAddonListItem = ({ addon, handleDelete }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen((prev) => !prev);

  return (
    <React.Fragment>
      <ListItemButton
        onClick={handleOpen}
        sx={{ border: "1px solid #a0a0a0", borderRadius: 2 }}
      >
        <ListItemText primary={addon.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense disablePadding sx={{ bgcolor: "#dcdcdc" }}>
          {addon.variants.map((v, varIndex) => (
            <React.Fragment>
              <ListItem sx={{ pl: 3 }}>
                <ListItemText primary={`Variante ${varIndex + 1}: ${v.name}`} />
              </ListItem>
              <List dense disablePadding>
                {v.options.map((o, opIndex) => (
                  <ListItem key={o.name} sx={{ pl: 6 }}>
                    <ListItemText
                      primary={`Opción ${opIndex + 1}: ${o.name}`}
                    />
                  </ListItem>
                ))}
              </List>
            </React.Fragment>
          ))}
        </List>
        <Button
          fullWidth
          variant="outlined"
          color="warning"
          onClick={handleDelete}
        >
          Eliminar
        </Button>
      </Collapse>
    </React.Fragment>
  );
};
