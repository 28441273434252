import React from "react";
import { Box, Typography, Avatar } from "@mui/material";

const Tecnologias = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: "2rem",
        backgroundColor: "#ffffff", // Fondo blanco
        borderRadius: 2,
        color: "#000000", // Texto negro
      }}
    >
      <Typography
        variant="h2"
        gutterBottom
        sx={{
          fontWeight: 700, // Letra más gruesa
          fontSize: {
            xs: "2rem", // Tamaño responsivo para pantallas pequeñas
            sm: "3rem", // Tamaño para pantallas medianas
            md: "3.5rem", // Tamaño para pantallas grandes
          },
        }}
      >
        Tecnologías Utilizadas
      </Typography>
      <Typography
        variant="body1"
        gutterBottom
        sx={{
          fontWeight: 500, // Letra más gruesa para el texto
          fontSize: {
            xs: "1rem", // Responsivo en pantallas pequeñas
            sm: "1.25rem", // Ajuste para pantallas medianas
            md: "1.5rem", // Ajuste para pantallas grandes
          },
          lineHeight: 1.6, // Espaciado entre líneas para mejorar legibilidad
        }}
      >
        Este proyecto no seria posible sin estos gigantes de la industria.
      </Typography>

      {/* Foto cuadrada con orillas redondeadas */}
      <Avatar
        alt="Tecnologías Logo"
        src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Prices%2Ftecnologias.png?alt=media&token=e9e15e65-ab6c-475e-9e84-f48f07e0bb00"
        sx={{
          width: {
            xs: 200, // Ajuste para pantallas pequeñas
            sm: 400, // Ajuste para pantallas medianas
            md: 720, // Ajuste para pantallas grandes
          },
          height: {
            xs: 200, // Ajuste para pantallas pequeñas
            sm: 400, // Ajuste para pantallas medianas
            md: 720, // Ajuste para pantallas grandes
          },
          borderRadius: "15%", // Esquinas redondeadas
          marginTop: "1.5rem",
        }}
      />
    </Box>
  );
};

export default Tecnologias;
