import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc, collection, addDoc } from "firebase/firestore";
import { useUserAuth } from "../../context/userAuthContext"; // Assuming user context for user ID
import {
  Tag,
  Box,
  DollarSign,
  Package,
  Layers,
  ArrowLeft,
  Plus,
  Minus,
} from "lucide-react";

const ProductDetail = () => {
  const { uid } = useParams();
  const navigate = useNavigate();
  const { user } = useUserAuth(); // Assuming user context for logged-in user
  const [product, setProduct] = useState(null);
  const [brandName, setBrandName] = useState("");
  const [modelName, setModelName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subcategoryName, setSubcategoryName] = useState("");
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const productRef = doc(db, "ShopItems", uid);
        const productSnap = await getDoc(productRef);
        if (productSnap.exists()) {
          const productData = productSnap.data();
          setProduct(productData);

          if (productData.brand) {
            const brandRef = doc(db, "ShopBrand", productData.brand);
            const brandSnap = await getDoc(brandRef);
            if (brandSnap.exists()) {
              setBrandName(brandSnap.data().name);

              if (productData.model) {
                const modelRef = doc(brandRef, "Models", productData.model);
                const modelSnap = await getDoc(modelRef);
                if (modelSnap.exists()) {
                  setModelName(modelSnap.data().name);
                }
              }
            }
          }

          if (productData.category) {
            const categoryRef = doc(db, "ShopCategories", productData.category);
            const categorySnap = await getDoc(categoryRef);
            if (categorySnap.exists()) {
              setCategoryName(categorySnap.data().name);

              if (productData.subcategory) {
                const subcategoryRef = doc(
                  categoryRef,
                  "subcategory",
                  productData.subcategory
                );
                const subcategorySnap = await getDoc(subcategoryRef);
                if (subcategorySnap.exists()) {
                  setSubcategoryName(subcategorySnap.data().name);
                }
              }
            }
          }
        } else {
          console.log("Product not found");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [uid]);

  const handleBuyNow = async () => {
    try {
      // Create the order data structure
      const orderData = {
        addons: [], // Assuming no additional items for now
        categoryId: product.category, // Category ID from product details
        itemId: uid, // Product ID
        status: "in_process", // Initial status
        total: product.cost * quantity, // Total cost based on quantity
        type: "product", // Order type
        title: product.title, // Product title
        userId: user.uid, // Logged-in user ID
      };

      // Add the order to Firestore
      const orderRef = await addDoc(collection(db, "orders"), orderData);

      // Redirect to checkout with the order ID
      navigate(`/checkout/${orderRef.id}`);
    } catch (error) {
      console.error("Error creating order:", error);
      alert("There was an error processing your order. Please try again.");
    }
  };

  if (loading) {
    return <div className="text-center py-10">Loading...</div>;
  }

  if (!product) {
    return <div className="text-center py-10">Product not found</div>;
  }

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white rounded shadow-lg mt-6 relative">
      {/* Go Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="absolute top-4 left-4 flex items-center text-gray-600 hover:text-blue-600 transition"
      >
        <ArrowLeft className="w-5 h-5 mr-1" />
        Go Back
      </button>

      <div className="flex flex-col md:flex-row gap-6 mt-10">
        {/* Product Image */}
        <div className="flex-shrink-0 w-full md:w-80 h-80">
          <img
            src={product.images?.[0] || "/placeholder-image.png"}
            alt={product.title}
            className="w-full h-full object-cover rounded-lg shadow"
          />
        </div>

        {/* Product Details */}
        <div className="flex-grow">
          <h1 className="text-3xl font-bold text-gray-800 mb-2">
            {product.title}
          </h1>
          <p className="text-lg text-gray-700 mb-4">{product.description}</p>

          <div className="flex flex-col space-y-2 text-gray-600 text-sm">
            <p className="flex items-center">
              <Tag className="w-4 h-4 mr-1 text-gray-500" />
              Category:{" "}
              <span className="ml-1 font-semibold">{categoryName}</span>
            </p>
            <p className="flex items-center">
              <Layers className="w-4 h-4 mr-1 text-gray-500" />
              Subcategory:{" "}
              <span className="ml-1 font-semibold">{subcategoryName}</span>
            </p>
            <p className="flex items-center">
              <Box className="w-4 h-4 mr-1 text-gray-500" />
              Brand: <span className="ml-1 font-semibold">{brandName}</span>
            </p>
            <p className="flex items-center">
              <Package className="w-4 h-4 mr-1 text-gray-500" />
              Model: <span className="ml-1 font-semibold">{modelName}</span>
            </p>
            <p className="flex items-center">
              <DollarSign className="w-4 h-4 mr-1 text-gray-500" />
              Price: <span className="ml-1 font-semibold">${product.cost}</span>
            </p>
            <p className="flex items-center">
              <Box className="w-4 h-4 mr-1 text-gray-500" />
              Stock: <span className="ml-1 font-semibold">{product.stock}</span>
            </p>
          </div>

          {/* Quantity Selector */}
          <div className="flex items-center mt-6">
            <button
              onClick={() => setQuantity(Math.max(1, quantity - 1))}
              className="bg-gray-200 p-2 rounded hover:bg-gray-300 transition"
            >
              <Minus className="w-4 h-4 text-gray-600" />
            </button>
            <span className="mx-4 text-lg font-semibold">{quantity}</span>
            <button
              onClick={() => setQuantity(quantity + 1)}
              className="bg-gray-200 p-2 rounded hover:bg-gray-300 transition"
            >
              <Plus className="w-4 h-4 text-gray-600" />
            </button>
          </div>

          {/* Action Buttons */}
          <div className="flex gap-4 mt-6">
            <button
              onClick={handleBuyNow}
              className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition"
            >
              Buy Now
            </button>
            <button
              onClick={() => navigate(-1)}
              className="border border-gray-300 px-4 py-2 rounded-lg hover:bg-gray-100 transition"
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
