import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Typography, Box, Card, CardMedia, Button } from "@mui/material";
import { styled } from "@mui/system";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Card estilo mini banner promocional en blanco con bordes redondeados
const MiniBannerCard = styled(Card)({
  position: "relative",
  borderRadius: 16,
  overflow: "hidden",
  backgroundColor: "#ffffff", // Fondo blanco
  maxWidth: 250,
  height: 350,
  transition: "transform 0.3s ease",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

// Badge de tipo de evento en la esquina superior derecha con color naranja
const TypeBadge = styled(Box)({
  position: "absolute",
  top: 12,
  right: 12,
  backgroundColor: "#FF5722", // Naranja
  color: "#fff",
  padding: "4px 10px",
  fontSize: "0.75rem",
  borderRadius: 12,
  fontWeight: "bold",
  textTransform: "uppercase",
  zIndex: 2,
});

// Contenedor de contenido debajo de la imagen
const CardContentContainer = styled(Box)({
  padding: "16px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

// Botón de inscripción en negro con letras blancas
const MiniSubscribeButton = styled(Button)({
  textTransform: "none",
  fontWeight: "bold",
  backgroundColor: "#000",
  color: "#fff",
  padding: "6px 24px",
  fontSize: "0.8rem",
  borderRadius: 16,
  marginTop: 12,
  "&:hover": {
    backgroundColor: "#333",
  },
});

const CustomEvents = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const creatorUID = "4lh37bi7FjWvHVvK9GIMLDy6TX53"; // Filtrar eventos por creatorUID

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsCollection = collection(db, "events");
        const eventsQuery = query(
          eventsCollection,
          where("creatorUID", "==", creatorUID)
        );
        const eventsSnapshot = await getDocs(eventsQuery);
        const eventsData = eventsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          dateTime: doc.data().dateTime ? new Date(doc.data().dateTime) : null,
        }));
        const upcomingEvents = eventsData
          .filter((event) => event.dateTime && event.dateTime > new Date())
          .sort((a, b) => a.dateTime - b.dateTime);
        setEvents(upcomingEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 3,
        width: "100vw",
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          fontSize: "1.4rem",
          mb: 3,
          textAlign: "center",
        }}
      >
        Próximas Clínicas!
      </Typography>
      <Slider {...sliderSettings} style={{ width: "100%", margin: "0 -16px" }}>
        {events.map((event) => (
          <MiniBannerCard
            key={event.id}
            onClick={() => handleEventClick(event.id)}
          >
            <CardMedia
              component="img"
              image={event.photo}
              alt={`Imagen del evento ${event.eventName}`}
              sx={{
                width: "100%",
                height: 200, // Altura fija para mostrar imagen completa
                objectFit: "cover",
              }}
            />
            <TypeBadge>{event.type || "Evento"}</TypeBadge>
            <CardContentContainer>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  mb: 1,
                  maxWidth: "90%", // Limita el tamaño del título
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {event.eventName}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <AccessTimeIcon sx={{ mr: 1, fontSize: "1rem" }} />
                <Typography variant="body2" sx={{ fontSize: "0.9rem" }}>
                  {event.dateTime.toLocaleDateString("es-ES", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </Typography>
              </Box>
              <MiniSubscribeButton
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleEventClick(event.id);
                }}
              >
                Inscribirme
              </MiniSubscribeButton>
            </CardContentContainer>
          </MiniBannerCard>
        ))}
      </Slider>
    </Box>
  );
};

export default CustomEvents;
