import React, { useEffect } from "react";
import { initMercadoPago, Payment } from "@mercadopago/sdk-react";

const PaymentBrick = () => {
  useEffect(() => {
    // Inicializa MercadoPago con tu Public Key
    initMercadoPago("PUBLIC_KEY"); // Reemplaza con tu public key

    const renderPaymentBrick = async () => {
      try {
        // Solicitar el preferenceId del backend
        const response = await fetch(
          "https://changeable-nosy-accelerator.glitch.me/create_preference",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        const preferenceId = data.id; // Usamos el preferenceId

        const bricksBuilder = await window.MercadoPagoBricks();

        const settings = {
          initialization: {
            preferenceId: preferenceId, // El preference ID que has creado en el backend
          },
          customization: {
            paymentMethods: {
              atm: "all",
              ticket: "all",
              creditCard: "all",
              debitCard: "all",
              mercadoPago: "all",
            },
          },
          callbacks: {
            onReady: () => {
              console.log("Payment Brick listo para usar");
            },
            onSubmit: ({ formData }) => {
              return new Promise((resolve, reject) => {
                fetch("/process_payment", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(formData),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    resolve();
                    // Aquí manejas el resultado del pago
                    console.log("Resultado del pago:", data);
                  })
                  .catch((error) => {
                    reject();
                    console.error("Error al procesar el pago:", error);
                  });
              });
            },
            onError: (error) => {
              console.error("Error en el Payment Brick:", error);
            },
          },
        };

        window.paymentBrickController = await bricksBuilder.create(
          "payment",
          "paymentBrick_container",
          settings
        );
      } catch (error) {
        console.error("Error al cargar el Payment Brick:", error);
      }
    };

    renderPaymentBrick();

    // Limpiar el Payment Brick al desmontar el componente
    return () => {
      if (window.paymentBrickController) {
        window.paymentBrickController.unmount();
      }
    };
  }, []);

  return <div id="paymentBrick_container"></div>;
};

export default PaymentBrick;
