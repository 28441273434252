import React from "react";
import { Instagram, MessageCircle } from "lucide-react";
import Combo from "./Combo";

const Mess = () => {
  return (
    <div className="min-h-screen bg-white flex flex-col items-center justify-center p-4">
      {/* Background Header */}
      <div
        className="relative w-full h-64 bg-cover bg-center mb-16 rounded-lg shadow-lg overflow-hidden"
        style={{
          backgroundImage:
            "url('https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/432236435_17925124031847480_3198959492339373928_n.jpg?alt=media&token=2b59463b-a039-4555-b7bd-42bfd585d0a5')",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-30"></div>

        {/* Circular Logo */}
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/organization_logos%2FIabDEhRIaBfNvjltADf6PbZiN2u2%2F312496735_523703035810658_8392741796845066512_n.jpg?alt=media&token=1366300c-365b-42ab-bf95-6c9161bf909c"
            alt="Mess Logo"
            className="w-32 h-32 rounded-full object-cover border-4 border-white shadow-lg"
          />
        </div>
      </div>

      {/* Main Info Section */}
      <main className="text-center max-w-2xl space-y-6 text-gray-800">
        <h1 className="text-4xl md:text-5xl font-bold text-black">
          Mess Paldesestres
        </h1>
        <p className="text-lg md:text-xl text-gray-700">
          MTB Enduro Series Sonora! Primer serial de enduro en Sonora!
        </p>
        <p className="text-base md:text-lg text-gray-600">
          Explora nuevos caminos, supera tus límites y compite con los mejores.
          Nuestro evento es para ciclistas de todos los niveles, con
          oportunidades para mejorar habilidades y disfrutar del deporte que
          amamos.
        </p>
      </main>

      {/* Social Media and Contact Buttons */}
      <div className="mt-8 flex flex-col items-center space-y-4">
        <a
          href="https://www.instagram.com/messpaldesestres"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-6 py-3 bg-black text-white font-medium text-lg rounded-lg shadow-md hover:bg-gray-800 transition"
        >
          <Instagram className="w-6 h-6 mr-2" />
          Instagram
        </a>
        <a
          href="https://wa.me/+526622995258" // Cambia el número por el contacto de WhatsApp deseado
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center px-6 py-3 bg-black text-white font-medium text-lg rounded-lg shadow-md hover:bg-gray-800 transition"
        >
          <MessageCircle className="w-6 h-6 mr-2" />
          WhatsApp
        </a>
      </div>

      {/* Combo Section */}
      <Combo />

      {/* Footer */}
      <footer className="mt-16 text-center text-gray-500 text-sm">
        <p>© {new Date().getFullYear()} Mess. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default Mess;
