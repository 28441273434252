import React from "react";
import { CheckCircle } from "lucide-react";

const SummaryStep = ({
  event,
  selectedCategory,
  selectedAddons,
  addonSizes,
  totalPrice,
}) => (
  <div className="flex flex-col items-center space-y-6">
    {/* Título de Resumen */}
    <h2 className="text-2xl sm:text-3xl font-bold flex items-center justify-center space-x-2 text-center mb-4">
      <CheckCircle className="w-6 h-6 sm:w-8 sm:h-8" />
      <span>Resumen de tu Pedido</span>
    </h2>

    <div className="relative bg-white shadow-md rounded-lg overflow-hidden p-6 sm:p-8 border border-gray-200">
      {/* Nombre del evento */}
      <h3 className="text-lg sm:text-xl font-bold uppercase tracking-wider text-center mb-4">
        {event.eventName}
      </h3>
      <div className="border-t border-dashed border-gray-400 my-4"></div>

      {/* Lista de detalles */}
      <ul>
        {/* Detalle del evento */}
        <li className="flex justify-between items-center mb-4">
          <div className="flex-1 pr-4">
            <p className="text-md sm:text-lg font-semibold text-gray-700">
              {event.eventName}
            </p>
            <p className="text-sm sm:text-md text-gray-500">
              {selectedCategory?.name}
            </p>
          </div>
          <div className="flex items-center">
            {event.price === 0 ? (
              <span className="px-2 py-1 bg-green-100 text-green-700 font-semibold rounded text-xs sm:text-sm">
                Gratis
              </span>
            ) : (
              <span className="px-2 py-1 bg-blue-100 text-blue-700 font-semibold rounded text-xs sm:text-sm">
                ${event.price}
              </span>
            )}
          </div>
        </li>

        <div className="border-t border-dashed border-gray-400 my-4"></div>

        {/* Detalle de Add-ons */}
        {selectedAddons.map((addon) => (
          <li key={addon.id} className="flex justify-between items-center mb-4">
            <div className="flex-1 pr-4">
              <p className="text-md sm:text-lg font-semibold text-gray-700">
                {addon.name}
              </p>
              {addonSizes[addon.id] && (
                <p className="text-sm text-gray-500">
                  Tamaño: {addonSizes[addon.id]}
                </p>
              )}
            </div>
            <div className="flex items-center space-x-2">
              {addon.discount ? (
                <div className="text-right">
                  <p className="text-sm text-gray-500 line-through">
                    ${addon.price}
                  </p>
                  <span className="px-2 py-1 bg-red-100 text-red-700 font-semibold rounded text-xs sm:text-sm">
                    ${addon.price - addon.discount}
                  </span>
                </div>
              ) : (
                <span className="px-2 py-1 bg-gray-100 text-gray-700 font-semibold rounded text-xs sm:text-sm">
                  ${addon.price}
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>

      <div className="border-t border-dashed border-gray-400 my-4"></div>

      {/* Total */}
      <div className="flex justify-between items-center">
        <p className="text-lg sm:text-xl font-bold text-gray-700">
          Total a Pagar:
        </p>
        {totalPrice === 0 ? (
          <span className="px-3 py-1 bg-green-100 text-green-700 font-semibold rounded-lg text-lg">
            Gratis
          </span>
        ) : (
          <span className="px-3 py-1 bg-blue-100 text-blue-700 font-semibold rounded-lg text-lg">
            ${totalPrice}
          </span>
        )}
      </div>
    </div>
  </div>
);

export default SummaryStep;
