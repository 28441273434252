import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ProfileStats from "./ProfileStats";
import ProfileBikes from "./ProfileBikes";
import ProfileRides from "./ProfileRides";
import RiderStats from "./RiderStats";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BikeIcon from "@mui/icons-material/DirectionsBike";

const Profile = () => {
  const { uid } = useParams(); // Obtiene el UID del usuario desde la URL.
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (index) => {
    setTabIndex(index);
  };

  return (
    <div className="max-w-3xl mx-auto px-4 py-6">
      <ProfileStats uid={uid} />
      <div className="mt-4 bg-gray-100 rounded-t-lg shadow-md">
        <div className="flex justify-around border-b border-gray-300">
          <TabButton
            index={0}
            tabIndex={tabIndex}
            onClick={handleTabChange}
            icon={<BikeIcon className="text-lg" />}
            label="Bikes"
          />
          <TabButton
            index={1}
            tabIndex={tabIndex}
            onClick={handleTabChange}
            icon={<DirectionsBikeIcon className="text-lg" />}
            label="Rides"
          />
          <TabButton
            index={2}
            tabIndex={tabIndex}
            onClick={handleTabChange}
            icon={<AssessmentIcon className="text-lg" />}
            label="Rider Stats"
          />
        </div>
        <div className="p-4">
          <CustomTabPanel value={tabIndex} index={0}>
            <ProfileBikes uid={uid} />
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={1}>
            <ProfileRides uid={uid} />
          </CustomTabPanel>
          <CustomTabPanel value={tabIndex} index={2}>
            <RiderStats uid={uid} />
          </CustomTabPanel>
        </div>
      </div>
    </div>
  );
};

const TabButton = ({ index, tabIndex, onClick, icon, label }) => (
  <button
    onClick={() => onClick(index)}
    className={`flex flex-col items-center py-3 w-full transition-colors duration-200 ${
      tabIndex === index
        ? "text-black border-b-2 border-black bg-gray-200 font-semibold"
        : "text-gray-500 hover:text-black"
    }`}
  >
    {icon}
    <span className="text-xs mt-1">{label}</span>
  </button>
);

function CustomTabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index} className="p-3">
      {value === index && <div>{children}</div>}
    </div>
  );
}

export default Profile;
