import React from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import PedalBikeIcon from "@mui/icons-material/PedalBike";
import ParkIcon from "@mui/icons-material/Park";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import NoteIcon from "@mui/icons-material/Note";
import TapAndPlayIcon from "@mui/icons-material/TapAndPlay";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { useNavigate } from "react-router-dom";
import { ReceiptIcon } from "lucide-react";

const links = [
  {
    label: "Corredores",
    icon: <PedalBikeIcon />,
    path: "/corredores",
  },
  {
    label: "Sedes",
    icon: <ParkIcon />,
    path: "/sedes",
  },
  {
    label: "Revenue",
    icon: <AttachMoneyIcon />,
    path: "/revenue",
  },
  {
    label: "Recibos",
    icon: <ReceiptIcon />,
    path: "/Recibos",
  },
  {
    label: "Cupones",
    icon: <LoyaltyIcon />,
    path: "/organizationcuopons",
  },
  {
    label: "Costos",
    icon: <PointOfSaleIcon />,
    path: "/costos",
  },
  {
    label: "Receptores",
    icon: <TapAndPlayIcon />,
    path: "/receivers/:uid",
  },
  {
    label: "Observaciones",
    icon: <NoteIcon />,
    path: "/observaciones",
  },
];

const NavigationButtons = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={2}>
        {links.map((link, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
            <Button
              fullWidth
              variant="contained"
              startIcon={link.icon}
              sx={{ backgroundColor: "#000", color: "#fff", p: 2 }}
              onClick={() => navigate(link.path)}
            >
              <Typography variant="button">{link.label}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default NavigationButtons;
