import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Trash } from "lucide-react";

export const OrderStatus = Object.freeze({
  approved: "approved",
  in_process: "in_process",
});

const OrderControl = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchUserID, setSearchUserID] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [searchType, setSearchType] = useState("");
  const [availableEvents, setAvailableEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [profit, setProfit] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "orders"), (snapshot) => {
      handleSearch(snapshot);
    });

    return () => unsubscribe();
  }, [searchUserID, searchStatus, searchType, selectedEvent]);

  const handleSearch = async (snapshot) => {
    setLoading(true);
    try {
      const ordersData = await Promise.all(
        snapshot.docs.map(async (orderDoc) => {
          const orderData = { id: orderDoc.id, ...orderDoc.data() };

          // Retrieve category and item names for display purposes
          const categoryRef = doc(
            db,
            "events",
            orderData.itemId,
            "categories",
            orderData.categoryId
          );
          const categorySnap = await getDoc(categoryRef);
          orderData.categoryName = categorySnap.exists()
            ? categorySnap.data().name
            : "No disponible";

          const itemRef = doc(db, "events", orderData.itemId);
          const itemSnap = await getDoc(itemRef);
          orderData.itemName = itemSnap.exists()
            ? itemSnap.data().eventName
            : "Evento no disponible";

          // Obtener el email del usuario desde Users/(UID)/email
          const userRef = doc(db, "Users", orderData.userId);
          const userSnap = await getDoc(userRef);
          orderData.userEmail = userSnap.exists()
            ? userSnap.data().email
            : "Correo no disponible";

          return orderData;
        })
      );

      const filteredOrders = ordersData.filter((order) => {
        const userIDMatch = searchUserID
          ? order.userId.includes(searchUserID) || order.userEmail.includes(searchUserID)
          : true;
        const statusMatch = searchStatus ? order.status === searchStatus : true;
        const typeMatch = searchType ? order.type === searchType : true;
        const eventMatch = selectedEvent ? order.itemId === selectedEvent : true;

        return userIDMatch && statusMatch && typeMatch && eventMatch;
      });

      setOrders(filteredOrders);

      // Calcular el profit si un evento está seleccionado
      if (selectedEvent) {
        const eventProfit = filteredOrders.reduce(
          (sum, order) => sum + (order.total || 0),
          0
        );
        setProfit(eventProfit);
      } else {
        setProfit(0);
      }

      // Obtener lista de eventos disponibles con sus nombres
      const eventsMap = new Map();
      ordersData.forEach((order) => {
        if (!eventsMap.has(order.itemId)) {
          eventsMap.set(order.itemId, order.itemName);
        }
      });
      setAvailableEvents(Array.from(eventsMap.entries()).map(([id, name]) => ({ id, name })));
      
    } catch (error) {
      console.error("Error al buscar órdenes:", error);
    } finally {
      setLoading(false);
    }
  };

  const toggleSelectOrder = (orderId) => {
    setSelectedOrders((prevSelected) =>
      prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId]
    );
  };

  const handleDeleteSelected = async () => {
    if (
      window.confirm(
        `¿Estás seguro de eliminar las siguientes órdenes?\n${selectedOrders.join(
          ", "
        )}`
      )
    ) {
      try {
        setLoading(true);
        await Promise.all(
          selectedOrders.map((orderId) =>
            orderId ? deleteDoc(doc(db, "orders", orderId)) : null
          )
        );
        setSuccessMessage("Órdenes eliminadas correctamente.");
        setSelectedOrders([]);
        setTimeout(() => setSuccessMessage(""), 3000); // Limpiar mensaje después de 3 segundos
      } catch (error) {
        console.error("Error al eliminar órdenes:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="p-2">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-semibold text-gray-700">
          Gestionar Órdenes
        </h1>
        <button
          onClick={() => {
            setSearchUserID("");
            setSearchStatus("");
            setSearchType("");
            setSelectedEvent("");
          }}
          className="text-red-500 hover:text-red-700"
          title="Borrar filtros"
        >
          <Trash className="w-5 h-5" />
        </button>
      </div>

      {/* Success Message */}
      {successMessage && (
        <div className="mb-4 text-green-600 bg-green-100 p-2 rounded-md">
          {successMessage}
        </div>
      )}

      {/* Profit Display */}
      {selectedEvent && (
        <div className="mb-4 text-blue-600 bg-blue-100 p-2 rounded-md">
          Ganancias del evento seleccionado: ${profit}
        </div>
      )}

      {/* Filters */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
        <input
          type="text"
          placeholder="Buscar por ID de Usuario o Email"
          className="border border-gray-300 rounded-md p-2"
          value={searchUserID}
          onChange={(e) => setSearchUserID(e.target.value)}
        />
        <select
          className="border border-gray-300 rounded-md p-2"
          value={searchStatus}
          onChange={(e) => setSearchStatus(e.target.value)}
        >
          <option value="">Todos los estados</option>
          <option value={OrderStatus.approved}>Aprobado</option>
          <option value={OrderStatus.in_process}>En Proceso</option>
        </select>
        <select
          className="border border-gray-300 rounded-md p-2"
          value={searchType}
          onChange={(e) => setSearchType(e.target.value)}
        >
          <option value="">Todos los tipos</option>
          <option value="event">Evento</option>
          <option value="product">Producto</option>
        </select>
        <select
          className="border border-gray-300 rounded-md p-2"
          value={selectedEvent}
          onChange={(e) => setSelectedEvent(e.target.value)}
        >
          <option value="">Todos los eventos</option>
          {availableEvents.map((event) => (
            <option key={event.id} value={event.id}>
              {event.name}
            </option>
          ))}
        </select>
        <button
          onClick={() => handleSearch()}
          className="bg-blue-600 text-white rounded-md p-2 hover:bg-blue-700 transition"
          disabled={loading}
        >
          Buscar
        </button>
      </div>

      {/* Delete Selected Orders */}
      {selectedOrders.length > 0 && (
        <div className="mb-4">
          <button
            onClick={handleDeleteSelected}
            className="bg-red-600 text-white rounded-md p-2 hover:bg-red-700 transition"
            disabled={loading}
          >
            Eliminar Órdenes Seleccionadas
          </button>
        </div>
      )}

      {/* Orders Table */}
      <div className="overflow-auto rounded-lg shadow-md">
        {loading ? (
          <div className="flex justify-center p-8">
            <div className="loader border-t-4 border-blue-500 w-10 h-10 rounded-full animate-spin"></div>
          </div>
        ) : (
          <table className="w-full bg-white">
            <thead>
              <tr className="bg-gray-200 text-gray-600 text-sm leading-normal">
                <th className="py-3 px-6 text-left">
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      setSelectedOrders(
                        e.target.checked ? orders.map((order) => order.id) : []
                      )
                    }
                    checked={
                      selectedOrders.length > 0 &&
                      selectedOrders.length === orders.length
                    }
                  />
                </th>
                <th className="py-3 px-6 text-left">ID de Orden</th>
                <th className="py-3 px-6 text-left">Usuario</th>
                <th className="py-3 px-6 text-left">Email</th>
                <th className="py-3 px-6 text-left">Evento</th>
                <th className="py-3 px-6 text-left">Categoría</th>
                <th className="py-3 px-6 text-left">Estado</th>
                <th className="py-3 px-6 text-left">Total</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-sm font-light">
              {orders.map((order) => (
                <tr
                  key={order.id}
                  className="border-b border-gray-200 hover:bg-gray-100 cursor-pointer"
                  onClick={() => navigate(order.id)}
                >
                  <td className="py-2 px-4">
                    <input
                      type="checkbox"
                      checked={selectedOrders.includes(order.id)}
                      onChange={() => toggleSelectOrder(order.id)}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </td>
                  <td className="py-2 px-4">{order.id}</td>
                  <td className="py-2 px-4">{order.userId}</td>
                  <td className="py-2 px-4">{order.userEmail}</td>
                  <td className="py-2 px-4">{order.itemName}</td>
                  <td className="py-2 px-4">{order.categoryName}</td>
                  <td className="py-2 px-4">
                    <span
                      className={`px-3 py-1 rounded-full text-xs ${
                        order.status === "approved"
                          ? "bg-green-100 text-green-700"
                          : "bg-blue-100 text-blue-700"
                      }`}
                    >
                      {order.status === "approved" ? "Aprobado" : "En Proceso"}
                    </span>
                  </td>
                  <td className="py-3 px-6 font-bold">${order.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default OrderControl;
