import React from "react";
import { ChevronDown } from "lucide-react";

const CategoryList = ({
  categories,
  subcategories,
  onSelectCategory,
  onSelectSubcategory,
  selectedCategory,
  selectedSubcategory,
}) => {
  return (
    <div className="bg-white p-4 rounded shadow-md w-full lg:w-1/4">
      <h2 className="text-xl font-semibold mb-4">Categories</h2>

      <div className="flex flex-wrap gap-2 mb-4">
        {categories.slice(0, 5).map((category) => (
          <button
            key={category.id}
            onClick={() => onSelectCategory(category)}
            className={`px-4 py-2 rounded-lg text-sm font-medium ${
              selectedCategory.id === category.id
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-800"
            } hover:bg-blue-500 hover:text-white transition`}
          >
            {category.name}
          </button>
        ))}

        {categories.length > 5 && (
          <div className="relative">
            <button className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg text-sm font-medium hover:bg-blue-500 hover:text-white transition flex items-center">
              More <ChevronDown className="ml-2 w-4 h-4" />
            </button>
            <div className="absolute mt-1 bg-white border border-gray-200 rounded shadow-lg max-h-48 overflow-auto z-10">
              {categories.slice(5).map((category) => (
                <button
                  key={category.id}
                  onClick={() => onSelectCategory(category)}
                  className={`block px-4 py-2 text-sm w-full text-left ${
                    selectedCategory.id === category.id
                      ? "bg-blue-500 text-white"
                      : "text-gray-800"
                  } hover:bg-blue-500 hover:text-white transition`}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {subcategories.length > 0 && (
        <div>
          <h3 className="text-lg font-medium mb-2">Subcategories</h3>
          <div className="flex overflow-x-auto space-x-2">
            {subcategories.map((subcategory) => (
              <button
                key={subcategory.id}
                onClick={() => onSelectSubcategory(subcategory)}
                className={`px-4 py-2 whitespace-nowrap rounded-lg text-sm font-medium ${
                  selectedSubcategory.id === subcategory.id
                    ? "bg-blue-600 text-white"
                    : "bg-gray-200 text-gray-800"
                } hover:bg-blue-500 hover:text-white transition`}
              >
                {subcategory.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryList;
