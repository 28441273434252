import React, { useState } from "react";
import { ShoppingCart, Trash, Plus, Minus, DollarSign, X } from "lucide-react";
import { useNavigate } from "react-router-dom";

const Cart = ({
  cartItems = [],
  onAddToCart,
  onRemoveFromCart,
  onClearCart,
}) => {
  const navigate = useNavigate();
  const [isCartOpen, setIsCartOpen] = useState(false);

  // Calculate total items and total cost
  const itemCount = cartItems.reduce((total, item) => total + item.quantity, 0);
  const totalCost = cartItems.reduce(
    (total, item) => total + item.quantity * item.cost,
    0
  );

  return (
    <div className="fixed bottom-[90px] right-6 z-50">
      <button
        onClick={() => setIsCartOpen(!isCartOpen)}
        className="bg-blue-600 text-white rounded-full w-14 h-14 flex items-center justify-center shadow-lg hover:bg-blue-700 transition relative"
      >
        <ShoppingCart className="w-6 h-6" />
        {itemCount > 0 && (
          <span className="absolute top-0 right-0 bg-red-600 text-white rounded-full text-xs font-semibold w-5 h-5 flex items-center justify-center">
            {itemCount}
          </span>
        )}
      </button>

      {/* Cart Summary Popup */}
      {isCartOpen && (
        <div className="absolute bottom-20 right-0 w-80 bg-white rounded-lg shadow-lg p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-bold">Your Cart</h3>
            <button onClick={() => setIsCartOpen(false)}>
              <X className="w-5 h-5 text-gray-600" />
            </button>
          </div>

          {cartItems.length > 0 ? (
            <div>
              {cartItems.map((item) => (
                <div key={item.id} className="flex items-center mb-4">
                  <img
                    src={item.images[0]}
                    alt={item.title}
                    className="w-12 h-12 rounded mr-3"
                  />
                  <div className="flex-grow">
                    <h4 className="font-semibold">{item.title}</h4>
                    <p className="text-gray-600 text-sm">Price: ${item.cost}</p>
                    <div className="flex items-center mt-1">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (onRemoveFromCart) onRemoveFromCart(item.id);
                        }}
                        className="p-1 rounded bg-gray-200 hover:bg-gray-300 transition"
                      >
                        <Minus className="w-4 h-4" />
                      </button>
                      <span className="mx-2">{item.quantity}</span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          if (onAddToCart) onAddToCart(item);
                        }}
                        className="p-1 rounded bg-gray-200 hover:bg-gray-300 transition"
                      >
                        <Plus className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onRemoveFromCart) onRemoveFromCart(item.id, true);
                    }}
                    className="p-1 text-red-600 hover:text-red-700 transition"
                    title="Remove item"
                  >
                    <Trash className="w-5 h-5" />
                  </button>
                </div>
              ))}
              <div className="border-t pt-3 mt-3">
                <div className="flex justify-between items-center">
                  <span className="font-semibold text-lg">Total:</span>
                  <span className="text-lg font-bold text-gray-800">
                    <DollarSign className="inline-block w-4 h-4 mr-1" />$
                    {totalCost.toFixed(2)}
                  </span>
                </div>
                <button
                  onClick={() => navigate("/checkout")}
                  className="bg-green-600 w-full text-white mt-4 py-2 rounded hover:bg-green-700 transition"
                >
                  Checkout
                </button>
                <button
                  onClick={() => onClearCart && onClearCart()}
                  className="w-full text-red-600 mt-2 py-2 rounded hover:bg-red-100 transition"
                >
                  Clear Cart
                </button>
              </div>
            </div>
          ) : (
            <p className="text-center text-gray-600">Your cart is empty.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Cart;
