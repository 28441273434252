import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Avatar } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PlaceIcon from "@mui/icons-material/Place";
import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import { differenceInSeconds } from "date-fns";

// Definir las sedes y fechas
const sedes = [
  { ciudad: "Álamos", fecha: new Date("2024-10-26T00:00:00") },
  { ciudad: "Hermosillo", fecha: new Date("2024-11-23T00:00:00") },
  { ciudad: "Obregón", fecha: new Date("2025-01-18T00:00:00") },
  { ciudad: "Esmeralda", fecha: new Date("2025-03-08T00:00:00") },
  { ciudad: "Capital Flow", fecha: new Date("2025-03-29T00:00:00") },
];

const formatCountdown = (seconds) => {
  const days = Math.floor(seconds / (24 * 60 * 60));
  const hours = Math.floor((seconds % (24 * 60 * 60)) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return { days, hours, minutes, remainingSeconds };
};

const Evento = () => {
  const [countdowns, setCountdowns] = useState([]);

  useEffect(() => {
    const calculateCountdowns = () => {
      const now = new Date();
      const updatedCountdowns = sedes.map((sede) => {
        const seconds = differenceInSeconds(sede.fecha, now);
        const countdown = formatCountdown(seconds);
        return { ...sede, ...countdown };
      });
      setCountdowns(updatedCountdowns);
    };

    calculateCountdowns();
    const intervalId = setInterval(calculateCountdowns, 1000); // Actualizar cada segundo
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        padding: "1rem", // Espaciado más pequeño
        backgroundColor: "#141414", // Fondo negro
        borderRadius: 2,
        color: "#ffffff", // Texto blanco
        width: "80%",
        margin: "0 auto", // Centrado
        maxWidth: "900px", // Limitar el ancho
      }}
    >
      {/* Logo de los organizadores y nombre */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Avatar
          alt="Logo de los Organizadores"
          src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Prices%2F312496735_523703035810658_8392741796845066512_n.jpg?alt=media&token=5d85071b-ca1f-4dac-8c34-51f34b8c3897"
          sx={{
            width: 80,
            height: 80,
            borderRadius: "10px",
            marginRight: "1rem",
          }}
        />
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#f0f0f0" }}>
          Organizador: Mess Pal Desestres
        </Typography>
      </Box>

      {/* Imagen de portada del evento */}
      <Box
        component="img"
        src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Prices%2F451986342_442570301935704_2481461073964117583_n.jpg?alt=media&token=9a291e9f-d388-4fec-9860-84bbbfb3a70a"
        alt="Portada del Evento"
        sx={{
          width: "25%", // Imagen más pequeña para mejor ajuste
          height: "auto",
          borderRadius: "10px",
          marginBottom: "1rem",
        }}
      />

      {/* Fecha del evento */}
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: 1,
          fontSize: { xs: "1.2rem", md: "1.5rem" }, // Responsivo
        }}
      >
        <CalendarTodayIcon sx={{ fontSize: 20, color: "#f50057" }} />
        26 de Octubre del 2024
      </Typography>

      {/* Título del evento */}
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          gap: 1,
          fontSize: { xs: "1.2rem", md: "1.5rem" }, // Responsivo
        }}
      >
        <DirectionsBikeIcon sx={{ fontSize: 20, color: "#4caf50" }} />
        Mess pal, desestres
      </Typography>

      {/* Lugar del evento */}

      {/* Sedes, fechas y countdown */}
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        Próximas Sedes
      </Typography>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        {countdowns.map((sede, index) => (
          <Grid item xs={6} sm={4} md={2} key={index}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#f0f0f0", fontSize: "1rem" }}
            >
              {sede.ciudad}
            </Typography>
            <Typography variant="body2" sx={{ color: "#cccccc" }}>
              {sede.fecha.toLocaleDateString("es-ES", {
                month: "long",
                day: "numeric",
              })}
            </Typography>
            <Typography variant="body2" sx={{ color: "#f0f0f0" }}>
              {sede.days} días, {sede.hours} horas, {sede.minutes} minutos y{" "}
              {sede.remainingSeconds} segundos restantes
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Evento;
