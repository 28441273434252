import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, CircularProgress, Box } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Analytics from "./Analytics";
import NavigationButtons from "./NavigationButtons";

const OrganizationPanel = () => {
  const { uid } = useParams();
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [localTime, setLocalTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const docRef = doc(db, "Organizations", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setOrganization(docSnap.data());
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching organization:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganization();
  }, [uid]);

  useEffect(() => {
    const timer = setInterval(() => {
      setLocalTime(new Date().toLocaleTimeString());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      {organization && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img
                src={organization.logo}
                alt={organization.organizationName}
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  objectFit: "cover",
                  marginRight: "10px",
                }}
              />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {organization.organizationName}
              </Typography>
            </Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              {localTime}
            </Typography>
          </Box>

          <Analytics />
          <NavigationButtons />
        </>
      )}
    </Container>
  );
};

export default OrganizationPanel;
