import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import CategoryStep from "./Stepper/CategoryStep";
import AddonsStep from "./Stepper/AddonsStep";
import SummaryStep from "./Stepper/SummaryStep";
import WarningDialog from "./Stepper/WarningDialog"; // Importar WarningDialog
import { eventsApi } from "../../lib/client";
import toast from "react-hot-toast";

const steps = ["Categoría", "Add-ons", "Final"];

const PayEvent = () => {
  const { id } = useParams();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [event, setEvent] = useState(null);
  const [categories, setCategories] = useState([]);
  const [addons, setAddons] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [addonSizes, setAddonSizes] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nfcWarning, setNfcWarning] = useState(false);
  const [nfcAddon, setNfcAddon] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [eventDoc, addonsSnapshot, categoriesSnapshot] =
          await Promise.all([
            getDoc(doc(db, "events", id)),
            getDocs(collection(db, "events", id, "addons")),
            getDocs(collection(db, "events", id, "categories")),
          ]);

        if (!eventDoc.exists()) throw new Error("Evento no encontrado");

        const eventData = { id: eventDoc.id, ...eventDoc.data() };
        const firebaseAddons = addonsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          required: doc.data().required || false,
          image: doc.data().image || null,
        }));

        setEvent(eventData);
        setAddons(firebaseAddons);
        setCategories(
          categoriesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
        );

        const pulseraAddon = firebaseAddons.find(
          (addon) => addon.name === "Pulsera"
        );
        if (pulseraAddon) {
          setSelectedAddons([pulseraAddon]);
          setNfcAddon(pulseraAddon);
        }
      } catch (error) {
        setError(error.message || "Error al cargar los datos");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (activeStep === 1 && nfcAddon) setNfcWarning(true);
  }, [activeStep, nfcAddon]);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleAddonChange = (addon, isChecked) => {
    setSelectedAddons((prev) => {
      const pulseraSelected = prev.some((a) => a.name === "Pulsera");
      if (isChecked) {
        if (addon.name === "Pulsera" && pulseraSelected) {
          alert("Solo puedes agregar una pulsera a la orden.");
          return prev;
        }
        if (addon.name === "Pulsera") setNfcWarning(true);
        return [...prev, addon];
      } else {
        return prev.filter((a) => a.id !== addon.id);
      }
    });
    if (!isChecked) {
      const newSizes = { ...addonSizes };
      delete newSizes[addon.id];
      setAddonSizes(newSizes);
    }
  };

  const handleSizeChange = (addonId, size) =>
    setAddonSizes((prev) => ({ ...prev, [addonId]: size }));
  const getTotalPrice = () =>
    (event?.price || 0) +
    selectedAddons.reduce(
      (total, addon) =>
        total + (addon.discount ? addon.price - addon.discount : addon.price),
      0
    );

  const handlePayment = async () => {
    try {
      if (event.price > 0) {
        const orderData = {
          userId: user.uid,
          itemId: event.id,
          categoryId: selectedCategory,
          status: "in_process",
          total: getTotalPrice(),
          type: "event",
          addons: selectedAddons.map((addon) => ({
            id: addon.id,
            name: addon.name,
            price: addon.discount ? addon.price - addon.discount : addon.price,
            description: addon.description,
            required: addon.required,
            variants: addon.variants || [],
          })),
          createdAt: serverTimestamp(), // Agregar timestamp para saber cuándo se creó la orden
        };
        const orderRef = await addDoc(collection(db, "orders"), orderData);
        navigate(`/checkout/${orderRef.id}`);
      } else {
        await eventsApi.addParticipantToEvent(
          event.id,
          user.uid,
          selectedCategory
        );
        toast.success("Te has unido al evento correctamente");
        setTimeout(() => navigate(`/event/${event.id}`), 1000);
      }
    } catch (error) {
      setError("Error al procesar el pago");
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="w-16 h-16 border-4 border-black border-dashed rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center p-6">
        <p className="text-lg font-semibold text-red-600 mb-4">{error}</p>
        <button
          onClick={() => navigate(-1)}
          className="bg-black text-white py-2 px-4 rounded"
        >
          Volver
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-lg mx-auto p-4">
      <h2 className="text-center text-2xl font-bold mt-4 mb-6">
        {event.eventName}
      </h2>

      <div className="bg-white shadow-md rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
          {steps.map((label, index) => (
            <div key={index} className="flex items-center space-x-2">
              <div
                className={`w-8 h-8 flex items-center justify-center rounded-full ${
                  index <= activeStep
                    ? "bg-black text-white"
                    : "bg-gray-200 text-gray-500"
                }`}
              >
                {index + 1}
              </div>
              <span
                className={`text-sm font-semibold ${
                  index === activeStep ? "text-black" : "text-gray-500"
                }`}
              >
                {label}
              </span>
            </div>
          ))}
        </div>

        <div className="mt-4">
          {activeStep === 0 && (
            <CategoryStep
              categories={categories}
              selectedCategory={selectedCategory}
              onCategoryChange={setSelectedCategory}
            />
          )}
          {activeStep === 1 && (
            <AddonsStep
              addons={addons}
              selectedAddons={selectedAddons}
              addonSizes={addonSizes}
              onAddonChange={handleAddonChange}
              onSizeChange={handleSizeChange}
            />
          )}
          {activeStep === 2 && (
            <SummaryStep
              event={event}
              selectedCategory={categories.find(
                (c) => c.id === selectedCategory
              )}
              selectedAddons={selectedAddons}
              addonSizes={addonSizes}
              totalPrice={getTotalPrice()}
            />
          )}
        </div>

        <div className="mt-6 flex justify-between">
          {activeStep !== 0 && (
            <button
              onClick={handleBack}
              className="flex items-center bg-gray-200 py-2 px-4 rounded text-black"
            >
              Anterior
            </button>
          )}
          <button
            onClick={
              activeStep === steps.length - 1 ? handlePayment : handleNext
            }
            disabled={activeStep === 0 && !selectedCategory}
            className={`${
              activeStep === 0 && !selectedCategory ? "bg-gray-400" : "bg-black"
            } text-white py-2 px-4 rounded flex items-center`}
          >
            {activeStep === steps.length - 1 && event.price !== 0
              ? `Pagar $${getTotalPrice()}`
              : activeStep === steps.length - 1 && event.price === 0
              ? "Unirme"
              : "Siguiente"}
          </button>
        </div>
      </div>

      {activeStep === 1 && nfcAddon && (
        <WarningDialog
          open={nfcWarning}
          onClose={() => setNfcWarning(false)}
          onAddonChange={handleAddonChange}
          nfcAddon={nfcAddon}
        />
      )}
    </div>
  );
};

export default PayEvent;
