// App.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Welcome from "./components/Welcome";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import PhoneSignUp from "./components/PhoneSignUp";
import ProtectedRoute from "./components/ProtectedRoute";
import NotAuthorizedScreen from "./screens/NotAuthorizedScreen.jsx";
/////// Creacion de Eventos //////
import CreateEvent from "./components/Events/CreateEvent";
import EventList from "./components/Events/EventList";
import EventDetail from "./components/Events/EventDetail";
import PayEvent from "./components/Events/PayEvent.js";
/////// Leaderboards //////
import Leaderboards from "./components/Leaderboards/Leaderboards";
import UserMenu from "./components/Users/UserMenu";
import NavigationBar from "./Navbar";
import ProfileInfo from "./components/ProfileInfo"; // Importa tu componente ProfileInfo
import UserForm from "./components/Users/UserForm.js";
import BikeGarage from "./components/Users/BikeGarage";
import AddBike from "./components/Users/AddBike";
import Receiver from "./components/AdminPanel/Receivers/Receiver";
import FinishReceiver from "./components/AdminPanel/Receivers/FinishReceiver";
////// CamReceiver ////////
import CamReceiver from "./components/AdminPanel/Receivers/CamReceiver";
import NfcCam from "./components/AdminPanel/Receivers/NfcCam";
import MapCam from "./components/AdminPanel/Receivers/MapCam";
/////// LiveStats //////
import LiveStats from "./components/Leaderboards/LiveStats";
import ActiveRiders from "./components/AdminPanel/ActiveRiders";
import LiveMap from "./components/RiderLocation/LiveMap";
import LiveRider from "./components/Leaderboards/LiveRider";
import Teams from "./components/Teams/Teams";
import CreateTeam from "./components/Teams/CreateTeam";
import RiderLocation from "./components/RiderLocation/RiderLocation";
////// Admin //////
import ControlPanel from "./components/AdminPanel/ControlPanel";
import TagControl from "./components/AdminPanel/Tags/TagControl.jsx";
import UsersControl from "./components/AdminPanel/UsersControl/UsersControl";
import Enduro from "./components/Leaderboards/Enduro";
import Stats from "./components/Leaderboards/Stats";
import OfflineStartReceiver from "./components/AdminPanel/Receivers/OfflineStartReceiver";
import { UserAuthContextProvider } from "./context/userAuthContext";
import ManualStart from "./components/AdminPanel/Receivers/ManualStart";
import ManualLiveRider from "./components/Leaderboards/ManualLiveRider";
import CapitanLeaderboard from "./components/Leaderboards/CapitanLeaderboard";
import UploadRiders from "./utils/UploadRidersScreen";
////////////// WorkShops /////////////////
import CreateWorkShop from "./components/WorkShops/CreateWorkShop";
import WorkShop from "./components/WorkShops/WorkShop";
import AllWorkShops from "./components/WorkShops/AllWorkShops";
import StateCreator from "./components/AdminPanel/StateCreator";
import Prueba from "./components/WorkShops/Prueba";
import OnService from "./components/WorkShops/OnService";
import AdminWorkShop from "./components/WorkShops/AdminWorkShop";
import MyWorkShop from "./components/WorkShops/MyWorkShop";
import Service from "./components/WorkShops/Service";
//////////// Mrketplace //////////////
import Parks from "./components/AdminPanel/Parks/Parks";
import Marketplace from "./components/MarketPlace/MarketPlace";
import MarketDashboard from "./components/MarketPlace/MarketDashboard";
import Product from "./components/MarketPlace/Product/Product.js";
import CreateProduct from "./components/MarketPlace/CreateProduct.js";
// Ride //
import RideInfo from "./components/Rides/RideInfo";
import CreateRide from "./components/Rides/CreateRide";
import Profile from "./components/Profile/Profile";

import MyRides from "./components/Rides/MyRides.js";
/// Friends ///
import SearchFriends from "./components/Friends/SearchFriends";
import FriendRequests from "./components/Users/FriendRequests";
// Search ///
import SearchPanel from "./components/Search/SearchPanel";
// Parks
import AllParks from "./components/Parks/AllParks";
import ParkInfo from "./components/Parks/ParkInfo";
import CreatePark from "./components/Parks/CreatePark";
import EditPark from "./components/Parks/EditPark";
// Teams ///
import TeamManager from "./components/Teams/TeamManager";
import TeamPage from "./components/Teams/TeamPage";
import TeamAdmin from "./components/Teams/TeamAdmin";
import MyParks from "./components/Parks/MyParks";
import ParkDashboard from "./components/Parks/ParkDashboard";
import CreateTrack from "./components/Parks/CreateTrack";
// Active
import RunStats from "./components/Leaderboards/RunStats";
// CheckOut

import CheckOut from "./components/CheckOut/CheckOut.js";
import Success from "./components/CheckOut/Success";
import Failure from "./components/CheckOut/Failure";
import Pending from "./components/CheckOut/Pending";
import EventManagerDashboard from "./components/Events/EventManagerDashboard";
import MyEventsContainer from "./components/Events/MyEventsContainer.js";
import EditEvent from "./components/Events/EditEvent.jsx";

// Store

import CreateStore from "./components/Stores/CreateStore.js";
import AllStores from "./components/Stores/AllStores.js";

import MobileNavbar from "./MobileNavbar.js";
import EventHome from "./components/Events/EventHome.js";
import ReceiverManager from "./components/AdminPanel/Receivers/ReceiverManager.js";
import NewUser from "./components/Users/NewUser.js";

import CheckNewUser from "./CheckNewUser.js";
import ReceiverInfo from "./components/AdminPanel/Receivers/ReceiverInfo.js";
import MyTickets from "./components/Users/MyTickets.js";
import Wallet from "./components/SpeedCoins/Wallet.js";
import TransactionDetails from "./components/SpeedCoins/TransactionDetails.js";
import MyFriends from "./components/Users/MyFriends.js";
import Notifications from "./Notifications.js";
import SendNotification from "./components/SendNotification.js";
import AnalyticsTracker from "./AnalyticsTracker.js";

import FloatingSupportButton from "./components/Support/FloatingSupportButton.js";
import CreateReceiver from "./components/AdminPanel/Receivers/CreateReceiver.js";
import AllReceivers from "./components/AdminPanel/Receivers/AllReceivers.js";
import MyReceivers from "./components/AdminPanel/Receivers/MyReceiver.js";
import EditReceiver from "./components/AdminPanel/Receivers/EditReceiver.js";
import Documentation from "./components/Documentacion/Documentation.js";
import ReceiverSimulator from "./components/AdminPanel/Receivers/ReceiverSimulator.js";
import LeaderboardMenu from "./components/Leaderboards/LeaderboardMenu.js";
import RunManager from "./components/Runs/RunManager.js";
import EmailSender from "./components/Mail/EmailSender.js";
import SignalTest from "./components/LoRa/SignalTest.js";
import Prices from "./components/Prices/Prices";
import Pay from "./components/CheckOut/Pay.js";
import CreateOrganization from "./components/Manager/CreateOrganization.js";
import MyOrganizations from "./components/Manager/MyOrganizations.js";
import OrganizationPanel from "./components/Manager/OrganizationPanel.js";
import Sedes from "./components/Manager/Sedes.js";
import OrganizationProfile from "./components/Manager/OrganizationProfile.js";
import SpeedCoinsCap from "./components/SpeedCoins/SpeedCoinCap.js";
import SpeedGiftCard from "./components/SpeedCoins/SpeedGiftCard.js";
import MyGiftCards from "./components/SpeedCoins/MyGiftsCards.js";
import RedeemGiftCard from "./components/SpeedCoins/RedeemGiftCard.js";
import MergeLateRuns from "./components/AdminPanel/MergeLateRuns.js";
import Reviews from "./components/SpeedCoins/Reviews.js";
import UploadStory from "./components/Stories/UploadStory.js";
import FlowHunters from "./components/CustomPage/FlowHunters.js";
import Mess from "./components/CustomPage/Mess.js";
import TagActions from "./components/AdminPanel/Tags/TagActions.jsx";
import FinishEvent from "./components/AdminPanel/Events/FinishEvent.jsx";
import EventLeaderboard from "./components/Leaderboards/Event/EventLeaderboard.jsx";
import NewRides from "./components/Rides/NewRides.js";
import OrderControl from "./components/AdminPanel/Orders/OrderControl";
import Shop from "./components/Shop/Shop.js";
import CreateShopProduct from "./components/Shop/CreateShopProduct.js";
import ProductDetail from "./components/Shop/ProductDetail.js";
import CsvUploader from "./components/AdminPanel/CsvUploader/CsvUploader.jsx";
import ContainerHome from "./components/AdminPanel/ContainerHome.jsx";
import FullEventLeaderboard from "./components/Leaderboards/Event/FullEventLeaderboard.js";
import SingleOrderControl from "./components/AdminPanel/Orders/SingleOrderControl.jsx";

function App() {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdDPHkqAAAAAD-2ggdqgPK3bk-jtGYFnOJMgo_w">
      <UserAuthContextProvider>
        <AnalyticsTracker />
        <Notifications />
        <CheckNewUser />
        <NavigationBar />
        <Routes>
          <Route
            path="/home"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route path="/rodadas" element={<NewRides />} />
          <Route path="/mess" element={<Mess />} />
          <Route path="/flow" element={<FlowHunters />} />
          <Route path="/uploadstory" element={<UploadStory />} />
          <Route path="/Mergelate" element={<MergeLateRuns />} />
          <Route path="/organizacion/:uid" element={<OrganizationProfile />} />
          <Route path="/sedes/:uid" element={<Sedes />} />
          <Route path="/comite/:uid" element={<OrganizationPanel />} />
          <Route path="/miscomites" element={<MyOrganizations />} />
          <Route path="/crearcomite" element={<CreateOrganization />} />
          <Route path="/pay" element={<Pay />} />
          <Route path="/prices" element={<Prices />} />
          <Route path="/signaltest" element={<SignalTest />} />
          <Route path="/emailsender" element={<EmailSender />} />
          <Route path="/runmanager" element={<RunManager />} />
          <Route path="/simulador" element={<ReceiverSimulator />} />
          <Route path="/documentacion" element={<Documentation />} />
          <Route path="/receiverconfig/:uid" element={<EditReceiver />} />
          <Route path="/receiverconfig" element={<EditReceiver />} />
          <Route path="/misreceivers" element={<MyReceivers />} />
          <Route path="/searchreceiver" element={<AllReceivers />} />
          <Route path="/createreceiver" element={<CreateReceiver />} />
          <Route path="/sendNotification" element={<SendNotification />} />
          <Route path="/MyFriends/:uid" element={<MyFriends />} />
          <Route path="/transaction/:uid" element={<TransactionDetails />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/tickets" element={<MyTickets />} />
          <Route path="/receiver/:uid" element={<ReceiverInfo />} />
          <Route path="/events" element={<EventHome />} />
          <Route path="/notauthorized" element={<NotAuthorizedScreen />} />
          <Route path="/myrides" element={<MyRides />} />
          <Route path="/createride/:uid" element={<CreateRide />} />
          <Route path="/createride" element={<CreateRide />} />
          <Route path="/run/:uid" element={<RunStats />} />
          <Route path="/createproduct" element={<CreateProduct />} />
          <Route path="/createtrack/:uid" element={<CreateTrack />} />
          <Route path="/myparks" element={<MyParks />} />
          <Route path="/parkpanel/:uid" element={<ParkDashboard />} />
          <Route path="/editpark/:uid" element={<EditPark />} />
          <Route path="/createpark" element={<CreatePark />} />
          <Route path="/teamadmin/:uid" element={<TeamAdmin />} />
          <Route path="/team/:uid" element={<TeamPage />} />
          <Route path="/myteams" element={<TeamManager />} />
          <Route path="/parks" element={<AllParks />} />
          <Route path="/search" element={<SearchPanel />} />
          <Route path="/friends" element={<SearchFriends />} />
          <Route path="/checkout/:uid" element={<CheckOut />} />
          <Route path="/ride/:rideId" element={<RideInfo />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/product/:uid" element={<Product />} />
          <Route path="/myproducts" element={<MarketDashboard />} />
          <Route
            path="/myworkshop"
            element={
              <ProtectedRoute role="workshopmanager">
                <AdminWorkShop />
              </ProtectedRoute>
            }
          />
          <Route path="/service" element={<OnService />} />
          <Route path="/service/:serviceId" element={<Service />} />
          <Route path="/statecreator" element={<StateCreator />} />
          <Route
            path="geekdashboard"
            element={
              <ProtectedRoute role="admin">
                <ControlPanel />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<ContainerHome />} />
            <Route path="event-list" element={<EventList />} />
            <Route path="finish-event/:id" element={<FinishEvent />} />
            <Route path="csv-uploader" element={<CsvUploader />} />
            <Route path="tagcontrol" element={<TagControl />} />
            <Route path="ordercontrol" element={<OrderControl />} />
            <Route path="ordercontrol/:id" element={<SingleOrderControl />} />
            <Route path="tagcontrol/:id" element={<TagActions />} />
          </Route>
          <Route
            path="/reviews"
            element={
              <ProtectedRoute role="admin">
                <Reviews />
              </ProtectedRoute>
            }
          />
          <Route path="/giftcard/:uid" element={<RedeemGiftCard />} />
          <Route path="/mygiftcards" element={<MyGiftCards />} />
          <Route path="/speedcap" element={<SpeedCoinsCap />} />
          <Route path="/speedgift" element={<SpeedGiftCard />} />
          <Route path="/stores" element={<AllStores />} />
          <Route path="/createstore" element={<CreateStore />} />
          <Route path="/success/:uid" element={<Success />} />
          <Route path="/failure" element={<Failure />} />
          <Route path="/pending" element={<Pending />} />
          <Route path="/payevent/:id" element={<PayEvent />} />
          <Route path="/event/:id" element={<EventDetail />} />
          <Route
            path="/my-events"
            element={
              <ProtectedRoute role="eventmanager">
                <MyEventsContainer />
              </ProtectedRoute>
            }
          >
            <Route path="" element={<EventManagerDashboard />} />
            <Route path=":id" element={<EditEvent />} />
            <Route path="create-event" element={<CreateEvent />} />
          </Route>
          <Route path="/parksleaderboards" element={<Leaderboards />} />
          {/* <Route path="/leaderboard/:uid" element={<EventLeaderboardLogic />} /> */}
          <Route path="/leaderboard/:uid" element={<EventLeaderboard />} />
          <Route
            path="/leaderboardfull/:uid"
            element={<FullEventLeaderboard />}
          />
          <Route path="/Leaderboards" element={<LeaderboardMenu />} />
          <Route path="/NuevoTaller" element={<CreateWorkShop />} />
          <Route path="/myworkshop/:workshopId" element={<MyWorkShop />} />
          <Route path="/profile/:nfcIdentifier" element={<ProfileInfo />} />
          <Route path="/workshop/:id" element={<WorkShop />} />
          <Route path="/workshops" element={<AllWorkShops />} />
          <Route
            path="/createworkshop/:workshopId"
            element={<CreateWorkShop />}
          />
          <Route path="/friendsrequests" element={<FriendRequests />} />
          <Route path="/ReceiverManager" element={<ReceiverManager />} />
          <Route path="/" element={<Welcome />} />
          <Route path="/user/:uid" element={<Profile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/phonesignup" element={<PhoneSignUp />} />
          <Route path="/addProfile" element={<UserForm />} />
          <Route path="/profile" element={<UserMenu />} />
          {/* <Route path="/MyTags" element={<MyTags />} /> */}
          <Route path="/MyBikes" element={<BikeGarage />} />
          <Route path="/parkss" element={<Parks />} />
          <Route path="/park/:uid" element={<ParkInfo />} />
          <Route path="/addBike" element={<AddBike />} />
          <Route path="/StartNFC" element={<Receiver />} />
          <Route path="/FinishNFC" element={<FinishReceiver />} />
          <Route path="/FinishCam" element={<CamReceiver />} />
          <Route path="/NFCcam" element={<NfcCam />} />
          <Route path="/MapCam" element={<MapCam />} />
          <Route path="/activeriders" element={<ActiveRiders />} />
          <Route path="/livemap" element={<LiveMap />} />
          <Route path="/teams" element={<Teams />} />
          <Route path="/createteam" element={<CreateTeam />} />
          <Route path="/userscontrol" element={<UsersControl />} />
          <Route path="/enduro" element={<Enduro />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/liverider" element={<LiveRider />} />
          <Route path="/LiveStats" element={<LiveStats />} />
          <Route path="/OfflineStart" element={<OfflineStartReceiver />} />
          <Route path="/ManualStart" element={<ManualStart />} />
          <Route path="/ManualLiveRider" element={<ManualLiveRider />} />
          <Route path="/uploadTags" element={<UploadRiders />} />
          <Route path="/prueba" element={<Prueba />} />
          <Route path="/livelocation" element={<RiderLocation />} />
          <Route path="/capitanleaderboard" element={<CapitanLeaderboard />} />
          <Route path="/welcomeaboard" element={<NewUser />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/newshopitem" element={<CreateShopProduct />} />
          <Route path="/shop/product/:uid" element={<ProductDetail />} />
        </Routes>
        <MobileNavbar />
        <FloatingSupportButton />
      </UserAuthContextProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
