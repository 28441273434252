import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  CircularProgress,
  Box,
  Grid,
  Paper,
  Button,
} from "@mui/material";
import {
  doc,
  onSnapshot,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

const OrganizationProfile = () => {
  const { uid } = useParams();
  const { user } = useUserAuth();
  const [organization, setOrganization] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    const unsubscribeOrg = onSnapshot(
      doc(db, "Organizations", uid),
      (docSnap) => {
        if (docSnap.exists()) {
          const orgData = docSnap.data();
          setOrganization(orgData);
          setIsFollowing(
            orgData.Followers && orgData.Followers.includes(user?.uid)
          );
        } else {
          console.error("No such document!");
        }
      }
    );

    const fetchEvents = async () => {
      try {
        const q = query(
          collection(db, "events"),
          where("organization", "==", uid)
        );
        const querySnapshot = await getDocs(q);
        const fetchedEvents = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEvents(fetchedEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
    setLoading(false);

    return () => unsubscribeOrg();
  }, [uid, user?.uid]);

  const handleFollow = async () => {
    if (!user) {
      console.error("User not logged in");
      return;
    }
    try {
      const orgRef = doc(db, "Organizations", uid);
      await updateDoc(orgRef, {
        Followers: arrayUnion(user.uid),
      });
      setIsFollowing(true);
    } catch (error) {
      console.error("Error following organization:", error);
    }
  };

  const handleUnfollow = async () => {
    if (!user) {
      console.error("User not logged in");
      return;
    }
    try {
      const orgRef = doc(db, "Organizations", uid);
      await updateDoc(orgRef, {
        Followers: arrayRemove(user.uid),
      });
      setIsFollowing(false);
    } catch (error) {
      console.error("Error unfollowing organization:", error);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      {organization && (
        <Box sx={{ mt: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              mb: 4,
            }}
          >
            <Box
              component="img"
              src={organization.cover}
              alt="Cover Image"
              sx={{
                width: "100%",
                height: "250px",
                objectFit: "cover",
                borderRadius: "15px",
                mb: 2,
              }}
            />
            <Box
              component="img"
              src={organization.logo}
              alt={organization.organizationName}
              sx={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                objectFit: "cover",
                mb: 2,
              }}
            />
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {organization.organizationName}
            </Typography>
            <Typography
              variant="body1"
              sx={{ mt: 1, display: "flex", alignItems: "center" }}
            >
              <LocationOnIcon sx={{ mr: 1 }} /> País: {organization.country}
            </Typography>
            <Typography
              variant="body1"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <LocationOnIcon sx={{ mr: 1 }} /> Estado: {organization.state}
            </Typography>
            {organization.instagram && (
              <Typography variant="body1" sx={{ mt: 1 }}>
                Instagram: {organization.instagram}
              </Typography>
            )}
            {organization.facebook && (
              <Typography variant="body1" sx={{ mt: 1 }}>
                Facebook: {organization.facebook}
              </Typography>
            )}
            {organization.email && (
              <Typography
                variant="body1"
                sx={{ mt: 1, display: "flex", alignItems: "center" }}
              >
                <EmailIcon sx={{ mr: 1 }} /> Email: {organization.email}
              </Typography>
            )}
            {organization.phone && (
              <Typography
                variant="body1"
                sx={{ mt: 1, display: "flex", alignItems: "center" }}
              >
                <PhoneIcon sx={{ mr: 1 }} /> Teléfono: {organization.phone}
              </Typography>
            )}
            <Typography variant="body1" sx={{ mt: 2, fontWeight: "bold" }}>
              Seguidores:{" "}
              {organization.Followers ? organization.Followers.length : 0}
            </Typography>
            {user &&
              (isFollowing ? (
                <Button
                  variant="outlined"
                  sx={{ mt: 2, color: "#000", borderColor: "#000" }}
                  onClick={handleUnfollow}
                  startIcon={<PersonRemoveIcon />}
                >
                  Dejar de Seguir
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{ mt: 2, backgroundColor: "#000", color: "#fff" }}
                  onClick={handleFollow}
                  startIcon={<PersonAddIcon />}
                >
                  Seguir
                </Button>
              ))}
          </Box>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Próximos Eventos
          </Typography>
          {events.length > 0 ? (
            <Grid container spacing={4} sx={{ mt: 2 }}>
              {events.map((event) => (
                <Grid item xs={12} sm={6} md={4} key={event.id}>
                  <Paper
                    elevation={4}
                    sx={{
                      p: 3,
                      textAlign: "center",
                      backgroundColor: "#1e1e1e",
                      color: "#fff",
                      borderRadius: "15px",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {event.eventName}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Fecha: {new Date(event.dateTime).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                      Ubicación:{" "}
                      {event.location
                        ? `${event.location.lat}, ${event.location.lng}`
                        : "No especificada"}
                    </Typography>
                    {event.photo && (
                      <Box
                        component="img"
                        src={event.photo}
                        alt={event.eventName}
                        sx={{
                          width: "100%",
                          height: "150px",
                          objectFit: "cover",
                          mt: 2,
                          borderRadius: "10px",
                        }}
                      />
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              No hay eventos próximos para esta organización.
            </Typography>
          )}
        </Box>
      )}
    </Container>
  );
};

export default OrganizationProfile;
