import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Button,
  Grid,
  MenuItem,
  CircularProgress,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  collection,
  addDoc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useUserAuth } from "../../../context/userAuthContext";
import { db } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const CreateReceiver = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [receiverData, setReceiverData] = useState({
    Type: "",
    Location: null,
    ParkID: "",
    TrackID: "",
    RUID: "",
    EventID: "", // Add EventID to receiverData
  });
  const [parks, setParks] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [events, setEvents] = useState([]); // State to hold Event data
  const [location, setLocation] = useState(null); // Latitude and Longitude

  const mapContainerStyle = {
    height: "300px",
    width: "100%",
  };

  useEffect(() => {
    // Fetch parks and events on mount
    const fetchParks = async () => {
      const parksQuery = await getDocs(collection(db, "Parks"));
      const parksData = parksQuery.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setParks(parksData);
    };

    const fetchEvents = async () => {
      const eventsQuery = await getDocs(collection(db, "events"));
      const eventsData = eventsQuery.docs.map((doc) => ({
        id: doc.id, // Store EventID
        eventName: doc.data().eventName, // Fetch eventName
      }));
      setEvents(eventsData);
    };

    const getUserLocation = () => {
      // Request user's location
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ lat: latitude, lng: longitude });
            setReceiverData((prev) => ({
              ...prev,
              Location: { lat: latitude, lng: longitude },
            }));
          },
          (error) => {
            console.error("Error getting user location:", error);
            // Set default location to Mexico City if location not allowed
            setLocation({ lat: 19.4326, lng: -99.1332 });
          }
        );
      } else {
        console.error("Geolocation not supported by browser");
        // Fallback to default location
        setLocation({ lat: 19.4326, lng: -99.1332 });
      }
    };

    fetchParks();
    fetchEvents();
    getUserLocation(); // Fetch user's location on component mount
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setReceiverData((prev) => ({ ...prev, [name]: value }));
  };

  const handleParkChange = async (e) => {
    const parkId = e.target.value;
    setReceiverData((prev) => ({ ...prev, ParkID: parkId }));

    const tracksQuery = await getDocs(collection(db, `Parks/${parkId}/Tracks`));
    const tracksData = tracksQuery.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTracks(tracksData);
  };

  const handleMapClick = (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setLocation({ lat, lng });
    setReceiverData((prev) => ({ ...prev, Location: { lat, lng } }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const docRef = await addDoc(collection(db, "Receivers"), {
        Type: receiverData.Type,
        Location: receiverData.Location,
        ParkID: receiverData.ParkID,
        TrackID: receiverData.TrackID,
        EventID: receiverData.EventID, // Add EventID to Firestore
        Owners: [user.uid],
        createdAt: serverTimestamp(),
      });

      await updateDoc(docRef, {
        RUID: docRef.id,
      });

      setLoading(false);
      navigate(`/misreceivers`);
    } catch (error) {
      console.error("Error creating receiver:", error);
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Typography variant="h4" gutterBottom>
          Crear Receiver
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Tipo</InputLabel>
                <Select
                  name="Type"
                  value={receiverData.Type}
                  onChange={handleChange}
                >
                  <MenuItem value="0">StartTime</MenuItem>
                  <MenuItem value="1">FinishTime</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
                <GoogleMap
                  mapContainerStyle={mapContainerStyle}
                  center={location || { lat: 19.4326, lng: -99.1332 }} // Default to Mexico City if location is not set
                  zoom={11}
                  onClick={handleMapClick}
                >
                  {location && <Marker position={location} />}
                </GoogleMap>
              </LoadScript>
              {location && (
                <Typography variant="body1" sx={{ mt: 1 }}>
                  Ubicación seleccionada: {location.lat}, {location.lng}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Parque</InputLabel>
                <Select
                  name="ParkID"
                  value={receiverData.ParkID}
                  onChange={handleParkChange}
                >
                  {parks.map((park) => (
                    <MenuItem key={park.id} value={park.id}>
                      {park.ParkName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {tracks.length > 0 && (
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Track</InputLabel>
                  <Select
                    name="TrackID"
                    value={receiverData.TrackID}
                    onChange={handleChange}
                  >
                    {tracks.map((track) => (
                      <MenuItem key={track.id} value={track.id}>
                        {track.TrackName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Evento</InputLabel>
                <Select
                  name="EventID"
                  value={receiverData.EventID}
                  onChange={handleChange}
                >
                  {events.map((event) => (
                    <MenuItem key={event.id} value={event.id}>
                      {event.eventName}{" "}
                      {/* Display eventName but store EventID */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                sx={{ backgroundColor: "black", color: "white" }}
              >
                {loading ? (
                  <CircularProgress size={24} sx={{ color: "white" }} />
                ) : (
                  "Crear Receiver"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default CreateReceiver;
