import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { Activity, Flag, MapPin, Clock } from "lucide-react";

const ActiveRiders = () => {
  const [activeRuns, setActiveRuns] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isLedOn, setIsLedOn] = useState(true);

  useEffect(() => {
    const updateCurrentTime = () => {
      setCurrentTime(new Date());
      requestAnimationFrame(updateCurrentTime);
    };
    requestAnimationFrame(updateCurrentTime);

    const timer = setInterval(() => {
      setIsLedOn((prevIsLedOn) => !prevIsLedOn);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "Runs"), where("ActiveRun", "==", true)),
      (snapshot) => {
        const activeRunsData = [];
        snapshot.forEach(async (doc) => {
          const runData = doc.data();
          const tagData = await getTagData(runData.NFCIdentifier);
          if (tagData) {
            runData.Owner = await getUserName(tagData.Owner);
            runData.ParkName = await getParkName(runData.ParkID);
            runData.TrackName = await getTrackName(
              runData.ParkID,
              runData.TrackID
            );
            runData.photoUrl = await getUserPhotoUrl(tagData.Owner);
          } else {
            runData.Owner = "Unknown";
            runData.ParkName = await getParkName(runData.ParkID);
            runData.TrackName = await getTrackName(
              runData.ParkID,
              runData.TrackID
            );
            runData.photoUrl = null;
          }
          activeRunsData.push({ ...runData, timestamp: doc.id });
        });
        activeRunsData.sort((a, b) => b.timestamp.localeCompare(a.timestamp));
        setActiveRuns(activeRunsData);
      },
      (error) => console.error("Error fetching active runs:", error)
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const getTagData = async (NFCIdentifier) => {
    try {
      const tagsCollectionRef = collection(db, "Tags");
      const q = query(tagsCollectionRef, where("NFCID", "==", NFCIdentifier));
      const querySnapshot = await getDocs(q);
      return !querySnapshot.empty ? querySnapshot.docs[0].data() : null;
    } catch (error) {
      console.error("Error getting tag data:", error);
      return null;
    }
  };

  const getUserName = async (userID) => {
    try {
      const userDoc = await getDoc(doc(db, "Users", userID));
      return userDoc.exists() ? userDoc.data().name : "Unknown";
    } catch (error) {
      console.error("Error getting user data:", error);
      return "Unknown";
    }
  };

  const getParkName = async (parkID) => {
    try {
      const parkDoc = await getDoc(doc(db, "Parks", parkID));
      return parkDoc.exists() ? parkDoc.data().ParkName : "Unknown Park";
    } catch (error) {
      console.error("Error getting park data:", error);
      return "Unknown Park";
    }
  };

  const getTrackName = async (parkID, trackID) => {
    try {
      const trackDoc = await getDoc(
        doc(db, "Parks", parkID, "Tracks", trackID)
      );
      return trackDoc.exists() ? trackDoc.data().TrackName : "Unknown Track";
    } catch (error) {
      console.error("Error getting track data:", error);
      return "Unknown Track";
    }
  };

  const getUserPhotoUrl = async (userID) => {
    try {
      const userDoc = await getDoc(doc(db, "Users", userID));
      return userDoc.exists() ? userDoc.data().photoUrl : null;
    } catch (error) {
      console.error("Error getting user photo URL:", error);
      return null;
    }
  };

  const calculateElapsedTime = (startTime) => {
    const startTimestamp = new Date(startTime).getTime();
    const currentTimestamp = currentTime.getTime();
    const elapsedMilliseconds = currentTimestamp - startTimestamp;
    const hours = Math.floor(elapsedMilliseconds / 3600000);
    const minutes = Math.floor((elapsedMilliseconds % 3600000) / 60000);
    const seconds = Math.floor((elapsedMilliseconds % 60000) / 1000);
    const milliseconds = Math.floor(elapsedMilliseconds % 1000);
    return (
      <>
        {`${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.`}
        <span className="text-red-600">
          {milliseconds.toString().padStart(3, "0")}
        </span>
      </>
    );
  };

  return (
    <div className="w-full p-4 bg-gray-100">
      <div className="flex flex-col items-center mb-4 space-y-1">
        <h2 className="text-3xl font-semibold text-black">
          {currentTime.toLocaleTimeString()}
        </h2>
        <div className="flex items-center text-xl font-semibold text-blue-600">
          <span className="mr-1">{activeRuns.length}</span> Riders Activos
        </div>
      </div>

      {activeRuns.length === 0 ? (
        <div className="text-center py-6 text-gray-500 text-lg">
          No active runs found
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {activeRuns.map((run, index) => (
            <div
              key={index}
              className="bg-white text-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-200 p-4"
            >
              <div className="flex items-center p-2 border-b border-gray-200">
                <img
                  className="w-10 h-10 rounded-full object-cover"
                  src={
                    run.photoUrl ||
                    "https://cdn.vectorstock.com/i/500p/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg"
                  }
                  alt={run.Owner}
                />
                <div className="ml-4 truncate">
                  <p className="font-semibold text-gray-800 text-sm truncate">
                    {run.Owner}
                  </p>
                </div>
              </div>

              <div className="p-2 space-y-2">
                <div className="flex items-center justify-between text-sm text-gray-600">
                  <div className="flex items-center">
                    <MapPin className="w-4 h-4 mr-2" />
                    <span className="truncate">{run.ParkName}</span>
                  </div>
                  <div className="flex items-center text-gray-500">
                    <Clock className="w-4 h-4 mr-1" />
                    {new Date(run.StartTime).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </div>
                </div>

                <div className="flex items-center justify-between text-sm text-gray-600">
                  <div className="flex items-center">
                    <Flag className="w-4 h-4 mr-2" />
                    <span className="truncate">{run.TrackName}</span>
                  </div>
                  <div className="flex items-center text-2xl font-bold text-blue-500">
                    <Activity className="w-5 h-5 mr-2" />
                    {calculateElapsedTime(run.StartTime)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActiveRiders;
