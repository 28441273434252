import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  CircularProgress,
  CardActionArea,
} from "@mui/material";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";

const MyOrganizations = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        const q = query(
          collection(db, "Organizations"),
          where("Owners", "array-contains", user.uid)
        );
        const querySnapshot = await getDocs(q);
        const orgs = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setOrganizations(orgs);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganizations();
  }, [user.uid]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h5"
        gutterBottom
        sx={{ textAlign: "center", fontWeight: "bold", mt: 3 }}
      >
        Mis Organizaciones
      </Typography>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {organizations.length > 0 ? (
          organizations.map((org) => (
            <Grid item xs={12} md={6} lg={4} key={org.id}>
              <CardActionArea onClick={() => navigate(`/comite/${org.id}`)}>
                <Paper
                  elevation={4}
                  sx={{ borderRadius: "15px", overflow: "hidden" }}
                >
                  <Box
                    sx={{
                      height: "120px",
                      backgroundImage: `url(${org.cover})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                  <Box sx={{ p: 2, textAlign: "center" }}>
                    <img
                      src={org.logo}
                      alt={org.organizationName}
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        objectFit: "cover",
                        marginBottom: "10px",
                      }}
                    />
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {org.organizationName}
                    </Typography>
                  </Box>
                </Paper>
              </CardActionArea>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ textAlign: "center", mt: 4 }}>
              No tienes organizaciones registradas.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default MyOrganizations;
