// EventCount.js
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { collection, getDocs } from "firebase/firestore";

const EventCount = () => {
  const [eventCount, setEventCount] = useState(0);

  useEffect(() => {
    const fetchEventCount = async () => {
      try {
        const eventsSnapshot = await getDocs(collection(db, "events"));
        setEventCount(eventsSnapshot.size);
      } catch (error) {
        console.error("Error fetching event count:", error);
      }
    };

    fetchEventCount();
  }, []);

  return (
    <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition">
      <h3 className="text-xl font-bold text-gray-700">Events</h3>
      <p className="text-2xl font-semibold text-green-600 mt-2">{eventCount}</p>
    </div>
  );
};

export default EventCount;
