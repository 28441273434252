import React, { useState } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  Alert,
  CircularProgress,
  FormControlLabel,
  Switch,
} from "@mui/material";
import SpeedGiftCardList from "./SpeedGiftCardList";

const SpeedGiftCard = () => {
  const { user } = useUserAuth();
  const [amount, setAmount] = useState(0);
  const [managerUID, setManagerUID] = useState("");
  const [giftCardCount, setGiftCardCount] = useState(1);
  const [reviewEnabled, setReviewEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCreateGiftCards = async () => {
    if (!user) {
      setErrorMessage("Usuario no autenticado. Por favor, inicia sesión.");
      return;
    }

    if (!user.type?.includes("admin")) {
      setErrorMessage("No tienes permisos para crear una giftcard.");
      return;
    }

    if (amount <= 0) {
      setErrorMessage("La cantidad debe ser mayor que cero.");
      return;
    }

    if (giftCardCount <= 0) {
      setErrorMessage("La cantidad de giftcards debe ser mayor que cero.");
      return;
    }

    if (managerUID.trim() === "") {
      setErrorMessage("El UID del manager no puede estar vacío.");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const giftcardPromises = Array.from(
        { length: giftCardCount },
        async () => {
          return addDoc(collection(db, "Giftcards"), {
            amount: amount,
            active: true,
            timestamp: Timestamp.now(),
            redeemtime: null,
            creatorUID: user.uid,
            managerUID: managerUID,
            review: reviewEnabled,
          });
        }
      );

      await Promise.all(giftcardPromises);
      setSuccessMessage(
        `Se han creado ${giftCardCount} giftcards exitosamente.`
      );
      setAmount(0);
      setManagerUID("");
      setGiftCardCount(1);
      setReviewEnabled(false);
    } catch (error) {
      console.error("Error al crear las giftcards:", error);
      setErrorMessage(
        "Error al crear las giftcards. Por favor, intente nuevamente."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 4, mt: 5 }}>
        <Typography variant="h4" gutterBottom align="center" color="primary">
          Crear GiftCard de SpeedCoins
        </Typography>
        <Box my={3}>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          {successMessage && <Alert severity="success">{successMessage}</Alert>}
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <TextField
            label="Cantidad de SpeedCoins"
            type="number"
            variant="outlined"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
            sx={{ mb: 3, width: "100%" }}
          />
          <TextField
            label="UID del Manager"
            type="text"
            variant="outlined"
            value={managerUID}
            onChange={(e) => setManagerUID(e.target.value)}
            sx={{ mb: 3, width: "100%" }}
          />
          <TextField
            label="Número de GiftCards"
            type="number"
            variant="outlined"
            value={giftCardCount}
            onChange={(e) => setGiftCardCount(Number(e.target.value))}
            sx={{ mb: 3, width: "100%" }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={reviewEnabled}
                onChange={(e) => setReviewEnabled(e.target.checked)}
              />
            }
            label="Habilitar reseña para la GiftCard"
            sx={{ mb: 3 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreateGiftCards}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : "Crear GiftCards"}
          </Button>
        </Box>
      </Paper>
      <SpeedGiftCardList />
    </Container>
  );
};

export default SpeedGiftCard;
