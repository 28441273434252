import { ChevronLeft } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Stack,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { eventsApi } from "../../lib/client";
import EventsForm from "./EventsForm";
import toast from "react-hot-toast";

const EditEvent = () => {
  const nav = useNavigate();
  const params = useParams();
  const eventId = params.id;
  const [isLoading, setIsLoading] = useState(true);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [eventInfo, setEventInfo] = useState(null);

  const handleDeleteEvent = async () => {
    await eventsApi.deleteEvent(eventId);
    toast.success("Evento eliminado con éxito");
    nav("/my-events");
  };

  const handleOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation((prev) => !prev);
  };

  useEffect(() => {
    const fetchEventInfo = async () => {
      const eventData = await eventsApi.getEventById(eventId, {
        categories: true,
        addons: true,
      });
      setEventInfo(eventData);
      setIsLoading(false);
    };
    fetchEventInfo();
  }, [eventId]);

  if (isLoading) return <Box>Loading...</Box>;

  return (
    <Box>
      <Box sx={{ pb: 2 }}>
        <Button startIcon={<ChevronLeft />} onClick={() => nav(-1)}>
          Regresar
        </Button>
      </Box>
      <EventsForm event={eventInfo} />
      <Divider sx={{ my: 2 }} />
      <Button
        fullWidth
        variant="outlined"
        color="warning"
        onClick={handleOpenDeleteConfirmation}
      >
        Eliminar evento
      </Button>
      <Modal
        open={openDeleteConfirmation}
        onClose={handleOpenDeleteConfirmation}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">
            ¿Estás seguro de eliminar este evento?
          </Typography>
          <Stack
            direction="row"
            spacing={1}
            mt={2}
            justifyContent="space-between"
          >
            <Button variant="contained" onClick={handleOpenDeleteConfirmation}>
              Cancelar
            </Button>
            <Button
              color="error"
              variant="outlined"
              onClick={handleDeleteEvent}
            >
              Eliminar
            </Button>
          </Stack>
        </Paper>
      </Modal>
    </Box>
  );
};

export default EditEvent;
