import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Avatar,
  Collapse,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const TeamMembers = () => {
  const [expandedMember, setExpandedMember] = useState(null);

  const members = [
    {
      name: "Pedro Aguirre",
      role: "Founder & Software Developer",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/1684958269703.jpeg?alt=media&token=deb2c747-6d17-445e-9676-32e2172af0f1",
      description:
        "Mi objetivo es crear un entorno donde la tecnología y la creatividad se combinen para superar las expectativas del usuario final",
    },
    {
      name: "Gerardo Carrizosa",
      role: "CO Founder, Software Engineer & Full-stack Developer",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/1675785281492.jpeg?alt=media&token=0c4527f3-67e4-4da5-850f-91d111f4dca9",
      description:
        "Experto en Arquitectura de software. Apasionado por la tecnología y el aprendizaje continuo.",
    },
    {
      name: "Guillermo Alvarez",
      role: "CTO & Project Manager",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/WhatsApp%20Image%202024-06-06%20at%2015.52.25.jpeg?alt=media&token=f44268f5-be8f-4e0c-a8ec-0c4de6ffaabf",
      description:
        "Trabajo en la interfaz entre el equipo de desarrollo, los clientes y los socios comerciales para definir las características y la dirección del producto.",
    },
    {
      name: "Angel Armando Gutierrez",
      role: "Design Engineer",
      avatar:
        "https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Home%2FScreenshot%202024-10-14%20at%2011.13.54%E2%80%AFa.m..png?alt=media&token=a624db17-be45-4801-b9c5-778194623988",
      description:
        "Me dedico a optimizar productos y crear diseños robustos, fáciles de usar y listos para la fabricación, asegurando eficiencia y calidad en cada etapa del desarrollo.",
    },
  ];

  const handleToggle = (index) => {
    setExpandedMember(expandedMember === index ? null : index);
  };

  return (
    <Grid
      container
      spacing={{ xs: 4, sm: 4, md: 6 }}
      sx={{
        py: 10,
        px: 2,
        backgroundColor: "#ffffff",
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h4"
          component="h2"
          sx={{ fontWeight: "bold", textAlign: "center", mb: 4 }}
        >
          Integrantes
        </Typography>
      </Grid>
      {members.map((member, index) => (
        <Grid item xs={12} md={4} key={member.name}>
          <Box sx={{ textAlign: "center", px: 4 }}>
            <Avatar
              alt={member.name}
              src={member.avatar}
              sx={{
                width: 120,
                height: 120,
                mx: "auto",
                mb: 2,
                cursor: "pointer",
              }}
              onClick={() => handleToggle(index)}
            />
            <Typography variant="h6" component="h3" sx={{ fontWeight: "bold" }}>
              {member.name}
            </Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              {member.role}
            </Typography>
            <IconButton onClick={() => handleToggle(index)}>
              {expandedMember === index ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </IconButton>
            <Collapse in={expandedMember === index}>
              <Typography variant="body1" sx={{ mt: 1 }}>
                {member.description}
              </Typography>
            </Collapse>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default TeamMembers;
