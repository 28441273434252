import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EventsForm from "./EventsForm";
import { Container, Typography, IconButton, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../../context/userAuthContext";

const CreateEvent = () => {
  const { user } = useUserAuth();
  const navigate = useNavigate();

  if (!user) navigate(-1);

  return (
    <Container maxWidth="sm">
      <Stack direction="row" alignItems="center" pb={2} spacing={2}>
        <IconButton aria-label="back" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">Crea tu evento</Typography>
      </Stack>
      <EventsForm creatorUID={user.uid} />
    </Container>
  );
};

export default CreateEvent;
