import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Typography,
  Grid,
  Paper,
  InputBase,
  Badge,
  useMediaQuery,
  Container,
  Skeleton,
} from "@mui/material";
import { PeopleAlt as PeopleAltIcon } from "@mui/icons-material";

const ParticipantCard = ({ participant, onClick }) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-4px)",
        },
      }}
    >
      <Paper
        elevation={2}
        sx={{
          p: 3, // Incrementa el padding para dar más espacio
          display: "flex",
          alignItems: "center",
          borderRadius: 3, // Aumenta el borde redondeado
          backgroundColor: "#FAFAFA", // Fondo más claro
          "&:hover": {
            backgroundColor: "#EEE", // Fondo más llamativo en hover
          },
        }}
      >
        <Avatar
          src={participant.user.photoUrl}
          alt={participant.user.name}
          sx={{
            width: isMobile ? 40 : 60, // Aumenta el tamaño del avatar
            height: isMobile ? 40 : 60,
            border: "2px solid",
            borderColor: "#AAA",
            mr: 3, // Más margen a la derecha del avatar
          }}
        />
        <Box sx={{ overflow: "hidden" }}>
          <Typography
            variant="h6" // Tamaño mayor de texto para resaltar más
            noWrap
            sx={{
              fontWeight: 700,
              fontSize: isMobile ? "1rem" : "1.25rem", // Ajuste de tamaño según dispositivo
              color: "#222",
            }}
          >
            {participant.user.name}
          </Typography>
          <Typography
            variant="body1"
            color="#555"
            noWrap
            sx={{
              fontSize: isMobile ? "0.85rem" : "1rem", // Incrementa ligeramente el tamaño
            }}
          >
            @{participant.user.nickName}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

const CategorySection = ({ category, participants, onParticipantClick }) => {
  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="h5" sx={{ fontWeight: 700, mb: 2 }}>
        {category}
      </Typography>
      <Grid container spacing={3}>
        {participants.map((participant, idx) => (
          <Grid item xs={12} sm={6} md={4} key={idx}>
            <ParticipantCard
              participant={participant}
              onClick={() => onParticipantClick(participant.user.uid)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const EventParticipants = ({ participants, isLoading = false }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [searchTerm, setSearchTerm] = useState("");

  const handleParticipantClick = (uid) => {
    navigate(`/user/${uid}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const groupedParticipants = useMemo(() => {
    return participants
      .filter((participant) =>
        participant.user.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .reduce((acc, participant) => {
        const category = participant.category || "General";
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(participant);
        return acc;
      }, {});
  }, [participants, searchTerm]);

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Typography
            variant={isMobile ? "h5" : "h4"}
            sx={{ fontWeight: 800, flex: isMobile ? "1 1 100%" : "auto" }}
          >
            Participantes del Evento
          </Typography>
          <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
            <InputBase
              placeholder="Buscar..."
              value={searchTerm}
              onChange={handleSearchChange}
              sx={{
                backgroundColor: "#EEE",
                borderRadius: 2,
                pl: 2,
                pr: 2,
                color: "#333",
                width: isMobile ? "100%" : 250,
              }}
            />
            <Badge
              badgeContent={participants.length}
              color="secondary"
              max={999}
              sx={{
                "& .MuiBadge-badge": {
                  backgroundColor: "#444",
                  color: "#FFF",
                },
              }}
            >
              <PeopleAltIcon
                fontSize={isMobile ? "large" : "inherit"} // Aumenta el tamaño del ícono
                sx={{
                  fontSize: isMobile ? "2rem" : "3rem", // Ajusta el tamaño para pantallas pequeñas y grandes
                }}
              />
            </Badge>
          </Box>
        </Box>

        {Object.entries(groupedParticipants).map(
          ([category, categoryParticipants]) => (
            <CategorySection
              key={category}
              category={category}
              participants={categoryParticipants}
              onParticipantClick={handleParticipantClick}
            />
          )
        )}
      </Box>
    </Container>
  );
};

const LoadingSkeleton = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
          <Skeleton variant="text" width={250} height={50} />
          <Skeleton variant="circular" width={60} height={60} />
        </Box>
        {[1, 2].map((section) => (
          <Paper key={section} sx={{ mb: 3, p: 2 }}>
            <Skeleton variant="text" width={180} height={40} sx={{ mb: 2 }} />
            <Grid container spacing={2}>
              {[1, 2, 3, 4, 5, 6].map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item}>
                  <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
                    <Skeleton
                      variant="circular"
                      width={isMobile ? 50 : 60}
                      height={isMobile ? 50 : 60}
                      sx={{ mr: 3 }}
                    />
                    <Box sx={{ width: "100%" }}>
                      <Skeleton variant="text" width="80%" height={28} />
                      <Skeleton variant="text" width="60%" height={24} />
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Paper>
        ))}
      </Box>
    </Container>
  );
};

export default EventParticipants;
