import React from "react";
import { Container, Typography } from "@mui/material";
import PricingTable from "./PricingTable"; // Importamos la tabla de precios
import Evento from "./Evento"; // Importamos el componente Evento
import Tecnologias from "./Tecnologias"; // Importamos el componente Tecnologias
import Features from "./Features";
import Receiver from "./Receiver";

const Prices = () => {
  return (
    <Container sx={{ padding: "2rem" }}>
      {/* Información del evento */}
      <Evento /> {/* Se muestra el componente del evento */}
      {/* Tabla de precios */}
      <Features />
      {/* Se importa la tabla de precios aquí */}
      {/* Tecnologías */}
      <Receiver />
      <Tecnologias /> {/* Se muestra el componente de tecnologías */}
      <PricingTable />
    </Container>
  );
};

export default Prices;
