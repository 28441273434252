import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { Clock, Users, MessageSquare, Heart } from "lucide-react";
import { Avatar } from "@mui/material";

const UpcomingEventsBanner = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const fetchEventsWithOrganizers = async () => {
      try {
        const eventsCollection = collection(db, "events");
        const eventsSnapshot = await getDocs(eventsCollection);

        const eventsData = await Promise.all(
          eventsSnapshot.docs.map(async (eventDoc) => {
            const eventData = eventDoc.data();
            const organizerId = eventData.creatorUID;

            let organizerData = {
              name: "Organizador Desconocido",
              photoUrl: null,
            };
            if (organizerId) {
              try {
                const organizerDocRef = doc(db, "Users", organizerId);
                const organizerDoc = await getDoc(organizerDocRef);
                if (organizerDoc.exists()) {
                  organizerData = {
                    name: organizerDoc.data().name,
                    photoUrl: organizerDoc.data().photoUrl,
                  };
                }
              } catch (error) {
                console.error("Error fetching organizer data:", error);
              }
            }

            const participantsCollection = collection(
              eventDoc.ref,
              "participants"
            );
            const participantsSnapshot = await getDocs(participantsCollection);
            const participantsCount = participantsSnapshot.size;

            const chatCollection = collection(eventDoc.ref, "chat");
            const chatSnapshot = await getDocs(chatCollection);
            const commentsCount = chatSnapshot.size;

            return {
              id: eventDoc.id,
              ...eventData,
              dateTime: eventData.dateTime
                ? new Date(eventData.dateTime)
                : null,
              organizer: {
                name: organizerData.name,
                photoUrl: organizerData.photoUrl,
              },
              participants: participantsCount,
              comments: commentsCount,
              likes: eventData.likes || [],
            };
          })
        );

        const upcomingEvents = eventsData
          .filter((event) => event.dateTime && event.dateTime > new Date())
          .sort((a, b) => a.dateTime - b.dateTime);

        setEvents(upcomingEvents);
      } catch (error) {
        console.error("Error fetching events with organizers:", error);
      }
    };

    const fetchUserId = async () => {
      const user = auth.currentUser;
      if (user) {
        setUserId(user.uid);
      }
    };

    fetchEventsWithOrganizers();
    fetchUserId();
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/event/${eventId}`);
  };

  const handleLike = async (eventId, likes) => {
    if (!userId) return;

    const eventRef = doc(db, "events", eventId);
    try {
      if (likes.includes(userId)) {
        await updateDoc(eventRef, {
          likes: arrayRemove(userId),
        });
      } else {
        await updateDoc(eventRef, {
          likes: arrayUnion(userId),
        });
      }
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === eventId
            ? {
                ...event,
                likes: likes.includes(userId)
                  ? likes.filter((id) => id !== userId)
                  : [...likes, userId],
              }
            : event
        )
      );
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  return (
    <div className="w-full bg-white text-gray-900">
      <div className="flex flex-col gap-8 items-center">
        {events.map((event) => (
          <div
            key={event.id}
            onClick={(e) => {
              e.stopPropagation();
              handleEventClick(event.id);
            }}
            className="w-full max-w-md bg-white rounded-lg shadow-md overflow-hidden border border-gray-200"
          >
            <div className="flex items-center px-4 py-3">
              {!event.organizer.photoUrl ? (
                <Avatar sx={{ mr: 2 }}>
                  {event.organizer.name.substring(0, 2)}
                </Avatar>
              ) : (
                <img
                  src={event.organizer.photoUrl}
                  alt={event.organizer.name || "Organizador"}
                  className="w-10 h-10 rounded-full mr-3"
                />
              )}
              <div>
                <p className="font-semibold text-gray-900 text-sm">
                  {event.organizer.name || "Organizador del Evento"}
                </p>
                <p className="text-gray-500 text-xs">
                  {event.dateTime?.toLocaleDateString("es-ES", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })}
                </p>
              </div>
            </div>

            {/* Imagen del evento */}
            <img
              src={event.photo}
              alt={event.eventName}
              className="w-full h-[400px] object-cover object-center"
            />

            {/* Información del evento */}
            <div className="px-4 py-3">
              <div className="flex items-center gap-4 text-gray-600 text-base mb-3">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLike(event.id, event.likes);
                  }}
                  className="flex items-center gap-2 hover:text-red-600 transition-colors"
                >
                  <Heart
                    className={`w-6 h-6 ${
                      event.likes.includes(userId) ? "text-red-600" : ""
                    }`}
                  />
                  <span>{event.likes.length} likes</span>
                </button>

                <div className="flex items-center gap-2">
                  <Users className="w-6 h-6" />
                  <span>{event.participants}</span>
                </div>

                <div className="flex items-center gap-2">
                  <MessageSquare className="w-6 h-6" />
                  <span>{event.comments} comentarios</span>
                </div>
              </div>

              <h3 className="font-semibold text-gray-900 text-lg mb-2">
                {event.eventName}
              </h3>

              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleEventClick(event.id);
                }}
                className="block w-full text-center bg-gradient-to-r from-blue-400 to-blue-700 text-white py-2 rounded-full font-semibold transition-transform hover:scale-105 shadow-lg hover:shadow-xl"
              >
                Ir a la página del evento
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UpcomingEventsBanner;
