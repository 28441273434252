import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import QRCode from "qrcode.react";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import ConfettiExplosion from "react-confetti-explosion";
import {
  Calendar,
  Layers,
  DollarSign,
  QrCode,
  PartyPopper,
  Ticket,
} from "lucide-react";
import SendSuccess from "./SendSuccess"; // Import to send the email

const Success = () => {
  const { uid } = useParams();
  const [order, setOrder] = useState(null);
  const [eventName, setEventName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        if (!uid) throw new Error("No se proporcionó el UID de la orden.");

        const orderRef = doc(db, "orders", uid);
        const orderSnap = await getDoc(orderRef);

        if (orderSnap.exists()) {
          const orderData = orderSnap.data();
          setOrder(orderData);

          const eventRef = doc(db, "events", orderData.itemId);
          const eventSnap = await getDoc(eventRef);

          if (eventSnap.exists()) {
            setEventName(eventSnap.data().eventName || "Evento no encontrado");
          }

          const categoryRef = doc(
            db,
            "events",
            orderData.itemId,
            "categories",
            orderData.categoryId
          );
          const categorySnap = await getDoc(categoryRef);

          if (categorySnap.exists()) {
            setCategoryName(
              categorySnap.data().name || "Categoría no encontrada"
            );
          }

          // Display confetti after loading order details
          setShowConfetti(true);
        } else {
          throw new Error("Orden no encontrada.");
        }
      } catch (error) {
        console.error("Error al obtener los datos de la orden:", error);
        setError(
          error.message ||
            "Error al obtener los datos de la orden. Intente nuevamente."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchOrderData();
  }, [uid]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-16 h-16 border-4 border-gray-600 rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-4">
        <p className="text-lg font-semibold text-red-600">{error}</p>
      </div>
    );
  }

  if (!order) {
    return (
      <div className="text-center p-4">
        <p className="text-lg font-semibold">Orden no encontrada</p>
      </div>
    );
  }

  const { addons, total } = order;

  return (
    <div>
      {/* Confetti display */}
      {showConfetti && (
        <div className="absolute top-0 left-0 right-0 z-10">
          <ConfettiExplosion />
        </div>
      )}

      {/* Send email using SendSuccess component */}
      <SendSuccess
        order={order}
        eventName={eventName}
        categoryName={categoryName}
      />

      {/* Ticket Container */}
      <div className="max-w-lg w-full bg-gradient-to-r from-white to-gray-100 rounded-lg shadow-lg p-8 relative">
        {/* Message without container */}
        <div className="text-center mb-6">
          <PartyPopper className="mx-auto text-yellow-500 w-12 h-12" />
          <h1 className="text-3xl font-bold mt-2 text-gray-900">
            ¡Gracias por inscribirte!
          </h1>
          <p className="text-gray-700 mt-1">
            Tu aventura está a punto de comenzar
          </p>
        </div>

        {/* Ticket Content */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <div className="flex flex-col items-center mb-4">
            <Ticket className="w-10 h-10 text-gray-900" />
            <h2 className="text-2xl font-bold mt-2 text-gray-900">
              TICKET DE ENTRADA
            </h2>
          </div>

          <hr className="my-4 border-dashed border-2 border-gray-300" />

          {/* QR Code */}
          <div className="flex justify-center mb-4">
            <QRCode
              value={uid}
              size={150}
              level="H"
              className="p-2 bg-white border rounded-md shadow-sm"
            />
          </div>
          <p className="text-center text-gray-600">
            <QrCode className="inline-block mr-1" /> ID: {uid}
          </p>

          {/* Event Details */}
          <div className="bg-gray-50 p-4 rounded-md mb-4">
            <div className="flex justify-between items-center mb-2">
              <p className="flex items-center text-gray-600">
                <Calendar className="w-5 h-5 mr-2 text-gray-800" />
                Evento
              </p>
              <p className="font-semibold text-gray-900">{eventName}</p>
            </div>
            <div className="flex justify-between items-center mb-2">
              <p className="flex items-center text-gray-600">
                <Layers className="w-5 h-5 mr-2 text-gray-800" />
                Categoría
              </p>
              <p className="font-semibold text-gray-900">{categoryName}</p>
            </div>
            <div className="flex justify-between items-center">
              <p className="flex items-center text-gray-600">
                <DollarSign className="w-5 h-5 mr-2 text-gray-800" />
                Total
              </p>
              <p className="font-semibold text-gray-900">${total}</p>
            </div>
          </div>

          {/* Add-ons Section */}
          {addons.length > 0 && (
            <>
              <h3 className="text-lg font-semibold text-center text-gray-900 mb-3">
                Add-ons Incluidos
              </h3>
              <div className="bg-gray-50 p-4 rounded-md">
                {addons.map((addon, index) => (
                  <div key={index} className="mb-2 last:mb-0">
                    <p className="font-semibold text-gray-900">
                      {addon.name} - ${addon.price}
                    </p>
                    <p className="text-gray-600 text-sm">{addon.description}</p>
                  </div>
                ))}
              </div>
            </>
          )}

          {/* Action Button */}
          <Link
            to={`/event/${order.itemId}`}
            className="block mt-6 bg-gray-900 text-white font-bold py-3 rounded-lg text-center transition hover:bg-gray-700"
          >
            Ver Detalles del Evento
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Success;
