import React, { useState, useEffect } from "react";
import { db } from "../../firebase";
import { collection, onSnapshot } from "firebase/firestore";
import {
  GoogleMap,
  LoadScript,
  OverlayView,
  KmlLayer,
} from "@react-google-maps/api";
import { Menu, Map, Satellite, Mountain } from "lucide-react"; // Reemplazo de Terrain por Mountain

const containerStyle = {
  width: "100vw",
  height: "100vh",
};

const center = {
  lat: 29.11580571,
  lng: -110.93319296,
};

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const CustomMarker = ({ position, photoUrl, name, team, speed }) => {
  const speedKmph = (speed || 0) * 3.6;

  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <div style={markerContainerStyle}>
        <div style={imageContainerStyle}>
          <img src={photoUrl} alt={name} style={imageStyle} />
        </div>
        <div style={textStyle}>
          <div>{name}</div>
          <div>Team: {team}</div>
          <div>Speed: {speedKmph.toFixed(2)} km/h</div>
        </div>
      </div>
    </OverlayView>
  );
};

const markerContainerStyle = {
  textAlign: "center",
  width: "150px",
};

const imageContainerStyle = {
  background: "#66ff66",
  border: "1px solid #ccc",
  borderRadius: "50%",
  padding: "4px",
  width: "33px",
  height: "33px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: "5px",
};

const imageStyle = {
  width: "25px",
  height: "25px",
  borderRadius: "50%",
};

const textStyle = {
  background: "#fff",
  color: "#333",
  fontSize: "10px",
  padding: "5px",
  borderRadius: "5px",
};

const LiveMap = () => {
  const [riders, setRiders] = useState([]);
  const [mapType, setMapType] = useState("terrain");
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "Users"), (snapshot) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const updatedRiders = snapshot.docs
        .map((doc) => {
          const userData = doc.data();
          const locationDate = userData.location?.timestamp?.toDate();

          if (userData.location && locationDate >= today) {
            return {
              id: doc.id,
              position: {
                lat: userData.location.latitude,
                lng: userData.location.longitude,
              },
              name: userData.name,
              team: userData.team,
              photoUrl: userData.photoUrl,
              speed: userData.location.speed,
            };
          }
          return null;
        })
        .filter((rider) => rider !== null);
      setRiders(updatedRiders);
    });

    return () => unsubscribe();
  }, []);

  const topographyStyles = [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [{ visibility: "on" }, { hue: "#00aaff" }],
    },
    // otros estilos personalizados...
  ];

  return (
    <LoadScript googleMapsApiKey="AIzaSyBXGQb3-FrLN8POyP-Y4lG_wJNcgFGRd9g">
      <div className="relative w-screen h-screen">
        {/* Botón flotante con menú desplegable */}
        <div className="absolute top-5 right-5 z-10">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="bg-white p-3 rounded-full shadow-lg hover:bg-gray-200 transition flex items-center justify-center"
          >
            <Menu className="w-6 h-6 text-gray-800" />
          </button>
          {menuOpen && (
            <div className="flex flex-col mt-2 bg-white rounded-lg shadow-lg p-2">
              <button
                onClick={() => {
                  setMapType("terrain");
                  setMenuOpen(false);
                }}
                className="flex items-center gap-2 p-2 rounded hover:bg-gray-100 transition"
              >
                <Mountain className="w-5 h-5" />
                <span>Mapa Relieve</span>
              </button>
              <button
                onClick={() => {
                  setMapType("satellite");
                  setMenuOpen(false);
                }}
                className="flex items-center gap-2 p-2 rounded hover:bg-gray-100 transition"
              >
                <Satellite className="w-5 h-5" />
                <span>Mapa Satelital</span>
              </button>
              <button
                onClick={() => {
                  setMapType("roadmap");
                  setMenuOpen(false);
                }}
                className="flex items-center gap-2 p-2 rounded hover:bg-gray-100 transition"
              >
                <Map className="w-5 h-5" />
                <span>Mapa Básico</span>
              </button>
            </div>
          )}
        </div>

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={16}
          options={{
            mapTypeId: mapType,
            styles: topographyStyles,
            fullscreenControl: false,
            scrollwheel: false, // Desactiva el scroll
            draggable: false, // Desactiva el desplazamiento
          }}
        >
          {riders.map((rider) => (
            <CustomMarker
              key={rider.id}
              position={rider.position}
              photoUrl={rider.photoUrl}
              name={rider.name}
              team={rider.team}
              speed={rider.speed}
            />
          ))}
          <KmlLayer
            url="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/Enduro.kml?alt=media&token=cfb193ce-69b7-4739-8165-c5f492020891"
            options={{ preserveViewport: true }}
          />
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default LiveMap;
