import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  Typography,
  Container,
  Card,
  CardMedia,
  Box,
  Avatar,
  CircularProgress,
  Button,
} from "@mui/material";
import ParkTracks from "./ParkTracks";
import ParkMap from "./ParkMap";
import { useUserAuth } from "../../context/userAuthContext";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import FollowTheSignsIcon from "@mui/icons-material/FollowTheSigns";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ParkWeather from "./ParkWeather"; // Importando el nuevo componente
import {
  doc, // Importar doc
  getDoc, // Importar getDoc
  updateDoc, // Importar updateDoc
  arrayUnion, // Importar arrayUnion
  arrayRemove, // Importar arrayRemove
} from "firebase/firestore";
const ParkInfo = () => {
  const { uid } = useParams();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const [park, setPark] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isFollowing, setIsFollowing] = useState(false);

  useEffect(() => {
    const fetchPark = async () => {
      try {
        const parkRef = doc(db, "Parks", uid);
        const parkDoc = await getDoc(parkRef);
        if (parkDoc.exists()) {
          const parkData = parkDoc.data();
          setPark({ id: parkDoc.id, ...parkData });
          if (user && parkData.Followers?.includes(user.uid)) {
            setIsFollowing(true);
          }
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching park data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPark();
  }, [uid, user]);

  const handleFollowClick = async () => {
    if (!user) {
      navigate("/login");
      return;
    }

    try {
      const parkRef = doc(db, "Parks", uid);
      await updateDoc(parkRef, {
        Followers: arrayUnion(user.uid),
      });
      setIsFollowing(true);
      setPark((prevPark) => ({
        ...prevPark,
        Followers: [...(prevPark.Followers || []), user.uid],
      }));
    } catch (error) {
      console.error("Error following park:", error);
    }
  };

  const handleUnfollowClick = async () => {
    if (!user) {
      navigate("/login");
      return;
    }

    try {
      const parkRef = doc(db, "Parks", uid);
      await updateDoc(parkRef, {
        Followers: arrayRemove(user.uid),
      });
      setIsFollowing(false);
      setPark((prevPark) => ({
        ...prevPark,
        Followers: prevPark.Followers.filter(
          (follower) => follower !== user.uid
        ),
      }));
    } catch (error) {
      console.error("Error unfollowing park:", error);
    }
  };

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (!park) {
    return (
      <Container>
        <Typography variant="h5" gutterBottom align="center">
          Parque no encontrado
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Card sx={{ borderRadius: 3, overflow: "hidden", mb: 4, boxShadow: 2 }}>
        <CardMedia
          component="img"
          height="400"
          image={park.Cover}
          alt={`Foto del parque ${park.ParkName}`}
          sx={{
            width: "100%",
            objectFit: "cover",
            "@media (max-width: 600px)": {
              height: "auto",
            },
          }}
        />
        <Box p={3}>
          <Box display="flex" alignItems="center" mb={3}>
            <Avatar
              src={park.Logo}
              alt={`${park.ParkName} logo`}
              sx={{ mr: 2, width: 64, height: 64 }}
            />
            <Typography variant="h4" component="div" fontWeight="bold">
              {park.ParkName}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <LocationOnIcon sx={{ color: "gray", mr: 1 }} />
            <Typography variant="body1" color="text.secondary">
              Ubicación: {park.city}, {park.State}, {park.country}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mb={2}>
            <PeopleIcon sx={{ color: "gray", mr: 1 }} />
            <Typography variant="body1" color="text.secondary">
              Seguidores:{" "}
              {Array.isArray(park.Followers) ? park.Followers.length : 0}
            </Typography>
          </Box>
          {park.description && (
            <Typography variant="body2" color="textSecondary" paragraph>
              {park.description}
            </Typography>
          )}
          {park.Location && (
            <Typography variant="body2" color="textSecondary">
              Latitud: {park.Location.latitude}, Longitud:{" "}
              {park.Location.longitude}
            </Typography>
          )}

          {/* Agregamos el componente de clima */}
          {park.Location && (
            <ParkWeather
              latitude={park.Location.latitude}
              longitude={park.Location.longitude}
            />
          )}

          <Box display="flex" justifyContent="center" mt={3}>
            {isFollowing ? (
              <Button
                variant="contained"
                color="error"
                startIcon={<PersonRemoveIcon />}
                sx={{ borderRadius: 20, px: 4 }}
                onClick={handleUnfollowClick}
              >
                Unfollow
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                startIcon={<FollowTheSignsIcon />}
                sx={{ borderRadius: 20, px: 4 }}
                onClick={handleFollowClick}
              >
                Seguir
              </Button>
            )}
          </Box>
        </Box>
      </Card>

      <Box mt={5}>
        <ParkMap />
        <Typography variant="h5" gutterBottom fontWeight="bold">
          Pistas del Parque
        </Typography>
        <ParkTracks />
      </Box>
    </Container>
  );
};

export default ParkInfo;
