import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Avatar, CircularProgress, Modal } from "@mui/material";
import StoryViewer from "./StoryViewer";

const Stories = () => {
  const [stories, setStories] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedStoryIndex, setSelectedStoryIndex] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userUid, setUserUid] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user) {
      setUserUid(user.uid);
    }
  }, []);

  const handleOpen = async (index) => {
    if (!userUid) {
      alert("Debe iniciar sesión para ver la historia.");
      return;
    }

    const story = stories[index];
    const db = getFirestore();
    const storyRef = doc(db, "stories", story.id);

    // Si el userUid no está en views, se agrega
    if (!story.views.includes(userUid)) {
      await updateDoc(storyRef, {
        views: arrayUnion(userUid),
      });
    }

    setSelectedStoryIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setSelectedStoryIndex(null), 300);
  };

  useEffect(() => {
    const fetchStories = () => {
      setLoading(true);
      const db = getFirestore();
      const storiesCollection = collection(db, "stories");

      // Configuramos `onSnapshot` para obtener actualizaciones en tiempo real
      const unsubscribe = onSnapshot(storiesCollection, async (snapshot) => {
        const storiesData = await Promise.all(
          snapshot.docs.map(async (storyDoc) => {
            const storyData = storyDoc.data();
            const userRef = doc(db, "Users", storyData.userId);
            const userDoc = await getDoc(userRef);
            const userData = userDoc.exists() ? userDoc.data() : {};

            const formattedTimestamp = storyData.timestamp
              ? new Date(storyData.timestamp.seconds * 1000).toLocaleString()
              : "Fecha desconocida";

            return {
              id: storyDoc.id,
              ...storyData,
              nickname: userData.nickName || "Usuario",
              photoUrl: userData.photoUrl || "",
              formattedTimestamp,
              views: storyData.views || [],
            };
          })
        );

        setStories(storiesData);
        setLoading(false);
      });

      // Limpiar la suscripción a los cambios en tiempo real al desmontar el componente
      return unsubscribe;
    };

    fetchStories();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center mt-4">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex gap-4 overflow-x-auto px-4 py-4">
      {stories.map((story, index) => (
        <div
          key={story.id}
          onClick={() => handleOpen(index)}
          className="flex flex-col items-center cursor-pointer"
        >
          <Avatar
            src={story.photoUrl}
            alt={story.nickname}
            className={`w-36 h-36 border-2 ${
              story.views.includes(userUid)
                ? "border-gray-400"
                : "border-orange-500"
            }`}
          />
          <span className="text-sm text-gray-700 mt-1 font-medium">
            {story.nickname}
          </span>
        </div>
      ))}

      {/* Renderiza el modal si open es true */}
      {open && selectedStoryIndex !== null && (
        <Modal open={open} onClose={handleClose}>
          <div className="flex items-center justify-center min-h-screen">
            <StoryViewer
              stories={stories}
              initialIndex={selectedStoryIndex}
              onClose={handleClose}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Stories;
