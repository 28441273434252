import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const GoogleMapSection = ({ location }) => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyBSXE8zZ-SqMWdDz16hr00Djvkm3mMit28">
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "400px",
          borderRadius: "10px",
        }}
        center={{
          lat: location.lat,
          lng: location.lng,
        }}
        zoom={8}
      >
        <Marker
          position={{
            lat: location.lat,
            lng: location.lng,
          }}
        />
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapSection;
