import React from "react";
import {
  Typography,
  Container,
  Avatar,
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import TimerIcon from "@mui/icons-material/Timer";
import StartIcon from "@mui/icons-material/PlayArrow";

const medalColors = {
  gold: "linear-gradient(45deg, #FFD700, #FFA500)",
  silver: "linear-gradient(45deg, #C0C0C0, #A9A9A9)",
  bronze: "linear-gradient(45deg, #CD7F32, #8B4513)",
  default: "linear-gradient(45deg, #FFFFFF, #F0F0F0)",
};

const LeaderboardCard = styled(Box)(({ theme, bgcolor }) => ({
  borderRadius: theme.spacing(1),
  overflow: "hidden",
  transition: "all 0.3s ease",
  background: bgcolor || medalColors.default,
  color: "#000",
  cursor: "pointer",
  position: "relative",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.15)",
  },
  margin: theme.spacing(1),
  padding: theme.spacing(1),
  "@media (max-width: 600px)": {
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
  },
}));

const TimeChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.85)",
  color: "#fff",
  fontWeight: "bold",
  fontSize: "0.75rem",
  padding: theme.spacing(0.5),
}));

const formatTimeDifference = (differenceInMs) => {
  const ms = differenceInMs % 1000;
  const seconds = Math.floor((differenceInMs / 1000) % 60);
  const minutes = Math.floor((differenceInMs / (1000 * 60)) % 60);

  if (minutes > 0) {
    return `${minutes}m ${seconds}s ${ms}ms`;
  } else if (seconds > 0) {
    return `${seconds}s ${ms}ms`;
  } else {
    return `${ms}ms`;
  }
};

const EventLeaderboardUI = ({
  loading,
  runs,
  ownerData,
  getTimeDifference,
  handleCardClick,
}) => {
  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 3, mb: 6 }}>
        {[...Array(6)].map((_, index) => (
          <LeaderboardCard key={index}>
            <Box sx={{ height: 80, bgcolor: "grey.200" }} />
          </LeaderboardCard>
        ))}
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <List>
        {runs.length === 0 ? (
          <Typography variant="h6" align="center" sx={{ width: "100%" }}>
            No hay resultados disponibles para este evento.
          </Typography>
        ) : (
          runs.map((run, index) => {
            // console.log("run =>", run);
            const position = index + 1;
            const backgroundColor =
              position <= 3
                ? medalColors[Object.keys(medalColors)[position - 1]]
                : medalColors.default;

            let timeDifference = null;
            if (position > 1) {
              const previousRun = runs[index - 1];
              const differenceInMs = getTimeDifference(
                previousRun.ElapsedTime,
                run.ElapsedTime
              );
              timeDifference = formatTimeDifference(differenceInMs);
            }

            const owner = ownerData[run.NFCIdentifier] || {};

            return (
              <React.Fragment key={run.id}>
                <ListItem
                  // onClick={() => handleCardClick(run.id)}
                  alignItems="flex-start"
                  sx={{
                    background: backgroundColor,
                    mb: 1,
                    borderRadius: 1,
                    padding: "10px 15px",
                    boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                    "@media (max-width: 600px)": {
                      padding: "8px",
                    },
                  }}
                >
                  <ListItemAvatar sx={{ alignSelf: "center" }}>
                    <Avatar
                      src={owner.PhotoUrl}
                      alt={owner.Owner || "Desconocido"}
                      sx={{
                        width: 50,
                        height: 50,
                        border: "2px solid #FFF",
                        "@media (max-width: 600px)": {
                          width: 40,
                          height: 40,
                        },
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1rem",
                          "@media (max-width: 600px)": {
                            fontSize: "0.85rem",
                          },
                        }}
                      >
                        {owner.Owner || "Desconocido"}
                      </Typography>
                    }
                    secondary={
                      <>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <TimerIcon
                            sx={{
                              mr: 1,
                              color: "#000",
                              fontSize: 25,
                              "@media (max-width: 600px)": {
                                fontSize: 18,
                              },
                            }}
                          />
                          <Typography
                            variant="h6"
                            sx={{
                              fontWeight: "bold",
                              color: "#000",
                              fontSize: "1.1rem",
                              "@media (max-width: 600px)": {
                                fontSize: "0.95rem",
                              },
                            }}
                          >
                            {run.ElapsedTime}
                          </Typography>
                          {timeDifference && (
                            <TimeChip
                              label={`+${timeDifference}`}
                              sx={{ ml: 2 }}
                            />
                          )}
                        </Box>
                        {/* <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mt: 0.5,
                          }}
                        >
                          <StartIcon
                            sx={{
                              mr: 1,
                              color: "#000",
                              fontSize: 18,
                              "@media (max-width: 600px)": {
                                fontSize: 16,
                              },
                            }}
                          />
                        </Box> */}
                      </>
                    }
                  />
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: "bold",
                      alignSelf: "center",
                      ml: 2,
                      color: "#000",
                      "@media (max-width: 600px)": {
                        fontSize: "1.5rem",
                      },
                    }}
                  >
                    #{position}
                  </Typography>
                </ListItem>
                {index !== runs.length - 1 && <Divider variant="inset" />}
              </React.Fragment>
            );
          })
        )}
      </List>
    </Container>
  );
};

export default EventLeaderboardUI;
