import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Container,
  useTheme,
} from "@mui/material";
import { Timer, Group, EventAvailable, CheckCircle } from "@mui/icons-material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { keyframes } from "@mui/system";

// Definir el keyframe para la animación del borde
const borderAnimation = keyframes`
  0% { border-color: #ff5f6d; }
  25% { border-color: #ffc371; }
  50% { border-color: #42e695; }
  75% { border-color: #3bb2b8; }
  100% { border-color: #ff5f6d; }
`;

// Crear un tema personalizado
const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#ffffff",
    },
    background: {
      default: "#0a0a0a",
      paper: "#141414",
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h2: {
      fontWeight: 700,
      fontSize: "2.5rem",
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
          "&:hover": {
            transform: "translateY(-8px)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
          fontWeight: 600,
          fontSize: "1rem",
          padding: "10px 24px",
        },
      },
    },
  },
});

const packages = [
  {
    title: "Bundle",
    price: "$10,000 mxn",
    features: [
      { text: "Cronometraje (10 Receivers, 5 stages)", icon: Timer },
      {
        text: "Inscripciones (administración por plataforma)",
        icon: EventAvailable,
      },
      { text: "Soporte presencial", icon: Group },
    ],
    isFeatured: true,
    eventText: "X evento",
  },
  {
    title: "Individual",
    price: "$11,000 mxn",
    features: [
      { text: "Cronometraje (10 Receivers, 5 stages)", icon: Timer },
      {
        text: "Inscripciones (administración por plataforma)",
        icon: EventAvailable,
      },
      { text: "Soporte presencial", icon: Group },
    ],
    isFeatured: false,
    eventText: "X evento",
  },
];

const PricingCard = ({ packageData }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        height: "100%",
        backgroundColor: packageData.isFeatured ? "#1e1e1e" : "#141414",
        position: "relative",
        transform: packageData.isFeatured ? "translateY(-20px)" : "none",
        zIndex: packageData.isFeatured ? 1 : 0,
        boxShadow: packageData.isFeatured
          ? "0 12px 24px rgba(255, 255, 255, 0.1)"
          : "0 6px 12px rgba(0, 0, 0, 0.3)",
        border: packageData.isFeatured ? "2px solid" : "none",
        borderColor: "transparent",
        ...(packageData.isFeatured && {
          animation: `${borderAnimation} 5s infinite linear`,
        }),
      }}
    >
      <CardContent>
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          sx={{ color: "white" }}
        >
          {packageData.title}
        </Typography>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: "white", fontWeight: "bold", mb: 3 }}
        >
          {packageData.price}
          <Typography
            component="span"
            variant="body2"
            sx={{ display: "block", color: "grey.500" }}
          >
            {packageData.eventText}
          </Typography>
        </Typography>
        <Box sx={{ mb: 3 }}>
          {packageData.features.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1.5,
                  justifyContent: "center",
                }}
              >
                <Icon sx={{ mr: 1, color: "white" }} />
                <Typography variant="body1" sx={{ color: "white" }}>
                  {feature.text}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: "center", pb: 3 }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "white",
            color: "#141414",
            "&:hover": {
              backgroundColor: "#f0f0f0",
            },
          }}
        >
          Seleccionar
        </Button>
      </CardActions>
    </Card>
  );
};

export default function PricingTable() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ borderRadius: 4, bgcolor: "background.default", py: 8 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            align="center"
            sx={{ color: "white", mb: 2 }}
          >
            Planes de Precios
          </Typography>
          <Typography
            variant="h6"
            align="center"
            sx={{ color: "grey.500", mb: 6 }}
          >
            Elija el plan perfecto para sus necesidades
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {packages.map((pkg, index) => (
              <Grid item xs={12} sm={6} md={6} key={index}>
                <PricingCard packageData={pkg} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}
