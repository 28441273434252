import React from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { db } from "../../../firebase";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import toast from "react-hot-toast";
import { TagStatus } from "./TagActions";
import { useUserAuth } from "../../../context/userAuthContext";

function AddTagModal() {
  const { user } = useUserAuth();
  const [open, setOpen] = React.useState(false);
  const [tagData, setTagData] = React.useState({ NFCID: "", TagType: "" });
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleOpen = () => {
    setTagData({ NFCID: "", TagType: "" });
    setOpen((prev) => !prev);
  };

  const handleScanNFC = async () => {
    try {
      const ndef = new window.NDEFReader();
      await ndef.scan();

      ndef.onreading = (event) => {
        const serialNumber = event.serialNumber || "Unknown";
        setTagData((prevData) => ({ ...prevData, NFCID: serialNumber }));
      };

      ndef.onreadingerror = () => {
        console.log("Error al leer el tag NFC");
      };
    } catch (error) {
      console.error("Error al iniciar el escaneo NFC: " + error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTagData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const tagsCollectionRef = collection(db, "Tags");

      const nfcQuery = query(
        tagsCollectionRef,
        where("NFCID", "==", tagData.NFCID)
      );
      const querySnapshot = await getDocs(nfcQuery);

      const isTagRegisteredElsewhere = querySnapshot.docs.some(
        (doc) => doc.data().Owner !== user.uid
      );
      if (isTagRegisteredElsewhere) {
        toast.error(`Tag ${tagData.NFCID} ya está registrado en otra cuenta.`);
        setIsSubmitting(false);
        return;
      }

      const isTagAlreadyRegistered = querySnapshot.docs.some(
        (doc) => doc.data().Owner === user.uid
      );
      if (isTagAlreadyRegistered) {
        toast.error(`Tag ${tagData.NFCID} ya está agregado a tu cuenta.`);
        setIsSubmitting(false);
        return;
      }

      await addDoc(tagsCollectionRef, {
        NFCID: tagData.NFCID,
        TagType: tagData.TagType,
        Owner: "",
        status: TagStatus.available,
      });

      setTagData({ NFCID: "", TagType: "" });
      setIsSubmitting(false);
      handleOpen();
    } catch (error) {
      console.error("Error al agregar tag:", error);
      setIsSubmitting(false);
    }
  };
  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Agregar
      </Button>
      <Modal
        open={open}
        onClose={handleOpen}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Paper elevation={1} sx={{ p: 2, maxWidth: "95%" }}>
          <Typography mb={2} variant="h6" component="h2">
            Agregar tag
          </Typography>
          <Grid container spacing={2} component="form" onSubmit={handleSubmit}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Agrega tu NFCID"
                variant="outlined"
                name="NFCID"
                value={tagData.NFCID}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleScanNFC}
              >
                Escanear Tag NFC
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="tag-type-label">
                  Selecciona un tipo de Tag
                </InputLabel>
                <Select
                  labelId="tag-type-label"
                  id="TagType"
                  name="TagType"
                  value={tagData.TagType}
                  label="Selecciona un tipo de Tag"
                  onChange={handleChange}
                  required
                >
                  <MenuItem value="Pulsera NFC">Pulsera NFC</MenuItem>
                  <MenuItem value="Llavero NFC">Llavero NFC</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between">
                <Button variant="outlined" onClick={handleOpen}>
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Agregar Tag"
                  )}
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </div>
  );
}

export default AddTagModal;
