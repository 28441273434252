import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  useTheme,
  useMediaQuery,
  Fade,
} from "@mui/material";
import { WaterDrop, Cloud, Air, AccessTime } from "@mui/icons-material";
import { keyframes } from "@mui/system";
import axios from "axios";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const WeatherStat = ({ title, value, icon, delay }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Fade in timeout={600} style={{ transitionDelay: `${delay}ms` }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 0.5,
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "translateY(-5px)",
          },
        }}
      >
        {React.cloneElement(icon, {
          sx: {
            fontSize: isSmallScreen ? 24 : 28,
            color: theme.palette.primary.main,
            mb: 0.5,
          },
        })}
        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            fontSize: isSmallScreen ? "1.2rem" : "1.5rem",
          }}
        >
          {value}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.secondary,
            fontSize: isSmallScreen ? "0.75rem" : "0.875rem",
          }}
        >
          {title}
        </Typography>
      </Box>
    </Fade>
  );
};

const ParkWeather = ({ latitude, longitude }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [weatherData, setWeatherData] = useState({
    temperature: null,
    humidity: null,
    cloud: null,
    windKph: null,
    conditionText: "",
    conditionIcon: "",
    localTime: "",
  });
  const [loading, setLoading] = useState(true);
  const apiKey = "a9c224289afe436db8c182531240310";

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await axios.get(
          `https://api.weatherapi.com/v1/current.json?key=${apiKey}&q=${latitude},${longitude}&aqi=no`
        );
        const { current, location } = response.data;

        setWeatherData({
          temperature: current.temp_c,
          humidity: current.humidity,
          cloud: current.cloud,
          windKph: current.wind_kph,
          conditionText: current.condition.text,
          conditionIcon: current.condition.icon,
          localTime: location.localtime,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching weather data:", error);
        setLoading(false);
      }
    };

    fetchWeather();
  }, [latitude, longitude]);

  const getTemperatureColor = (temp) => {
    if (temp >= 38) return theme.palette.error.main;
    if (temp <= 20) return theme.palette.primary.main;
    return theme.palette.text.primary;
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <Fade in timeout={1000}>
      <Box
        sx={{
          padding: isSmallScreen ? 2 : 4,
          animation: `${fadeIn} 0.6s ease-out`,
          background: theme.palette.background.paper,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mb: isSmallScreen ? 2 : 0,
            }}
          >
            <img
              src={weatherData.conditionIcon}
              alt={weatherData.conditionText}
              style={{
                width: isSmallScreen ? 80 : 100,
                height: isSmallScreen ? 80 : 100,
              }}
            />
            <Box>
              <Typography
                variant="h1"
                fontWeight="bold"
                sx={{
                  fontSize: isSmallScreen ? "3rem" : "4rem",
                  color: getTemperatureColor(weatherData.temperature),
                  transition: "color 0.3s ease",
                }}
              >
                {weatherData.temperature}°C
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.text.secondary,
                  fontSize: isSmallScreen ? "1.2rem" : "1.5rem",
                }}
              >
                {weatherData.conditionText}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Grid container spacing={isSmallScreen ? 3 : 4} justifyContent="center">
          <Grid item xs={6} sm={3}>
            <WeatherStat
              title="Humedad"
              value={`${weatherData.humidity}%`}
              icon={<WaterDrop />}
              delay={200}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <WeatherStat
              title="Nubosidad"
              value={`${weatherData.cloud}%`}
              icon={<Cloud />}
              delay={400}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <WeatherStat
              title="Viento"
              value={`${weatherData.windKph} km/h`}
              icon={<Air />}
              delay={600}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <WeatherStat
              title="Hora"
              value={weatherData.localTime.split(" ")[1]}
              icon={<AccessTime />}
              delay={800}
            />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default ParkWeather;
