import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  orderBy,
  where,
} from "firebase/firestore";
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Button,
  Stack,
  Tooltip,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddTagModal from "./AddTagModal";
import { Trash } from "lucide-react";

export const TagStatus = Object.freeze({
  available: 0,
  sold: 1,
  in_rent: 2,
});

const TagControl = () => {
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchNFCID, setSearchNFCID] = useState("");
  const [searchOwner, setSearchOwner] = useState("");
  const [searchStatus, setSearchStatus] = useState(""); // New state for status search

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const tagQuery = collection(db, "Tags");
      // let tagQuery = query(collection(db, "Tags"), orderBy("NFCID", "asc"));

      const tagsSnapshot = await getDocs(tagQuery);

      const tagsData = await Promise.all(
        tagsSnapshot.docs.map(async (tagDoc) => {
          const tagData = { id: tagDoc.id, ...tagDoc.data() };

          // Convert status number to readable text
          tagData.statusText = Object.keys(TagStatus).find(
            (key) => TagStatus[key] === tagData.status
          );

          // If Owner exists, get the name from the Users collection
          if (tagData.Owner) {
            const userRef = doc(db, "Users", tagData.Owner);
            const userSnap = await getDoc(userRef);
            tagData.OwnerName = userSnap.exists()
              ? userSnap.data().name
              : tagData.Owner;
          } else {
            tagData.OwnerName = "-";
          }

          return tagData;
        })
      );

      // Filter based on search criteria
      const filteredTags = tagsData.filter((tag) => {
        const nfcidMatch = searchNFCID
          ? tag.NFCID.toLowerCase().includes(searchNFCID.toLowerCase())
          : true;
        const ownerMatch = searchOwner
          ? tag.OwnerName.toLowerCase().includes(searchOwner.toLowerCase())
          : true;
        const statusMatch =
          searchStatus !== ""
            ? tag.status === parseInt(searchStatus, 10)
            : true;

        return nfcidMatch && ownerMatch && statusMatch;
      });

      setTags(filteredTags);
    } catch (error) {
      console.error("Error al buscar tags:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleScanNFC = async () => {
    try {
      const ndef = new window.NDEFReader();
      await ndef.scan();

      ndef.onreading = (event) => {
        const serialNumber = event.serialNumber || "Unknown";
        // setTagData((prevData) => ({ ...prevData, NFCID: serialNumber }));
        setSearchNFCID(serialNumber);
      };

      ndef.onreadingerror = () => {
        console.log("Error al leer el tag NFC");
      };
    } catch (error) {
      console.error("Error al iniciar el escaneo NFC: " + error);
    }
  };

  const handleClearFilters = () => {
    setSearchNFCID("");
    setSearchOwner("");
    setSearchStatus("");
  };

  return (
    <Box p={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pb={2}
      >
        <Typography variant="h5" gutterBottom>
          Gestionar Tags
        </Typography>
        <AddTagModal />
      </Stack>
      <Stack spacing={1} mb={2}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Tooltip title="Borrar filtros">
            <IconButton onClick={handleClearFilters}>
              <Trash />
            </IconButton>
          </Tooltip>
          <TextField
            fullWidth
            label="Buscar por Propietario"
            variant="outlined"
            size="small"
            value={searchOwner}
            onChange={(e) => setSearchOwner(e.target.value)}
          />
          <FormControl variant="outlined" size="small" sx={{ minWidth: 100 }}>
            <InputLabel>Status</InputLabel>
            <Select
              label="Buscar por Estado"
              value={searchStatus}
              onChange={(e) => setSearchStatus(e.target.value)}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value={TagStatus.available}>Disponible</MenuItem>
              <MenuItem value={TagStatus.sold}>Vendido</MenuItem>
              <MenuItem value={TagStatus.in_rent}>En Renta</MenuItem>
            </Select>
          </FormControl>
          <Button
            sx={{ minWidth: 100 }}
            variant="outlined"
            color="primary"
            onClick={handleSearch}
            disabled={loading}
          >
            Buscar
          </Button>
        </Stack>
        <Stack
          spacing={1}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button variant="contained" color="secondary" onClick={handleScanNFC}>
            NFC
          </Button>
          <TextField
            label="Buscar por NFCID"
            variant="outlined"
            size="small"
            value={searchNFCID}
            onChange={(e) => setSearchNFCID(e.target.value)}
          />
        </Stack>
      </Stack>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">NFCID</TableCell>
                <TableCell align="left">Propietario</TableCell>
                <TableCell align="left">Tipo</TableCell>
                <TableCell align="left">Estado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map((tag) => (
                <TableRow
                  key={tag.id}
                  onClick={() => navigate(tag.id)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                      opacity: 0.9,
                    },
                  }}
                >
                  <TableCell align="left">{tag.NFCID}</TableCell>
                  <TableCell align="left">{tag.OwnerName}</TableCell>
                  <TableCell align="left">{tag.TagType}</TableCell>
                  <TableCell align="left">{tag.statusText}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default TagControl;
