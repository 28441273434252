import React, { useState, useEffect } from "react";
import { useUserAuth } from "../../context/userAuthContext";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";
import {
  Container,
  Box,
  Typography,
  CircularProgress,
  Alert,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  Grid,
  Chip,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { green, grey } from "@mui/material/colors";
import ShareIcon from "@mui/icons-material/Share";

const SpeedGiftCardList = () => {
  const { user, loading: authLoading } = useUserAuth();
  const [loading, setLoading] = useState(true);
  const [giftcards, setGiftcards] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedGiftcardId, setSelectedGiftcardId] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const fetchGiftcards = async () => {
      if (authLoading) {
        return;
      }

      if (!user) {
        setErrorMessage("Usuario no autenticado. Por favor, inicia sesión.");
        setLoading(false);
        return;
      }

      try {
        const giftcardsCollection = collection(db, "Giftcards");
        const giftcardsSnapshot = await getDocs(giftcardsCollection);
        let giftcardsData = [];

        giftcardsSnapshot.forEach((doc) => {
          const data = doc.data();
          giftcardsData.push({ id: doc.id, ...data });
        });

        setGiftcards(giftcardsData);
      } catch (error) {
        console.error("Error al obtener las giftcards:", error);
        setErrorMessage(
          "Error al obtener las giftcards. Por favor, intente nuevamente."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchGiftcards();
  }, [user, authLoading]);

  const handleShare = (giftcardId) => {
    setSelectedGiftcardId(giftcardId);
    setOpenDialog(true);
  };

  const handleDialogClose = (agree) => {
    setOpenDialog(false);
    if (agree && selectedGiftcardId) {
      const shareUrl = `${window.location.origin}/giftcard/${selectedGiftcardId}`;
      window.open(
        `https://api.whatsapp.com/send?text=${encodeURIComponent(shareUrl)}`,
        "_blank"
      );
    }
  };

  if (authLoading || loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (errorMessage) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Typography
        variant="h5"
        gutterBottom
        align="center"
        color="primary"
        sx={{ fontSize: isMobile ? "1.3rem" : "1.8rem", mt: 3 }}
      >
        Lista de GiftCards de SpeedCoins
      </Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {giftcards.map((giftcard) => (
          <Grid item xs={12} sm={6} md={4} key={giftcard.id}>
            <Card
              sx={{
                backgroundColor: giftcard.active ? green[50] : grey[100],
                borderRadius: 3,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.2s",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
            >
              <CardContent sx={{ p: 2 }}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                >
                  <Typography
                    variant="subtitle1"
                    fontWeight={600}
                    color="primary"
                  >
                    GiftCard #{giftcard.id.slice(-6)}
                  </Typography>
                  <Chip
                    label={giftcard.active ? "Activa" : "Inactiva"}
                    color={giftcard.active ? "success" : "default"}
                    size="small"
                    sx={{ fontWeight: 500 }}
                  />
                </Box>
                <Box display="flex" alignItems="center" mb={1}>
                  <Avatar
                    src="https://firebasestorage.googleapis.com/v0/b/mtbking-aa657.appspot.com/o/speedcoins%2Fmario-coins.gif?alt=media&token=977b8311-54c5-4d38-930e-12cb1ce7739e"
                    sx={{ width: 28, height: 28, mr: 1 }}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {giftcard.amount} SpeedCoins
                  </Typography>
                </Box>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ mb: 0.5 }}
                >
                  UID Creador: {giftcard.creatorUID.slice(0, 6)}...
                  {giftcard.creatorUID.slice(-4)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Canjeado:{" "}
                  {giftcard.redeemtime
                    ? giftcard.redeemtime.toDate().toLocaleString()
                    : "No"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Creado: {giftcard.timestamp.toDate().toLocaleString()}
                </Typography>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<ShareIcon />}
                    onClick={() => handleShare(giftcard.id)}
                  >
                    Compartir Cupón
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={openDialog}
        onClose={() => handleDialogClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Advertencia"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Este cupón puede ser canjeado por cualquier usuario, Livespeed no se
            hace responsable del uso que se le dé.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => handleDialogClose(true)}
            color="primary"
            autoFocus
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default SpeedGiftCardList;
