import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@mui/material";
import EventLeaderboardUI from "./EventLeaderboardUI";
import ComparePopup from "./ComparePopup";
import PersonIcon from "@mui/icons-material/Person";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FilterListIcon from "@mui/icons-material/FilterList";
import ErrorIcon from "@mui/icons-material/Error"; // Icono rojo para DNF

const EventLeaderboard = () => {
  const { uid } = useParams();
  const [runs, setRuns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ownerData, setOwnerData] = useState({});
  const [isCompareOpen, setIsCompareOpen] = useState(false);
  const [categories, setCategories] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [currentTrack, setCurrentTrack] = useState(0);
  const [tracks, setTracks] = useState([]);
  const [totalTimes, setTotalTimes] = useState([]);

  useEffect(() => {
    const fetchEventData = async () => {
      const categoriesRef = collection(db, `events/${uid}/categories`);
      const categoriesSnap = await getDocs(categoriesRef);
      const categoriesData = categoriesSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesData);

      const participantsRef = collection(db, `events/${uid}/participants`);
      const participantsSnap = await getDocs(participantsRef);
      const participantsData = participantsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setParticipants(participantsData);
    };

    fetchEventData();
  }, [uid]);

  useEffect(() => {
    const fetchRuns = async () => {
      const eventRef = doc(db, "events", uid);
      const eventSnapshot = await getDoc(eventRef);
      if (!eventSnapshot.exists()) return;
      const eventData = eventSnapshot.data();
      const runsQuery = query(
        collection(db, "Runs"),
        where("EventID", "==", uid)
      );

      const unsubscribe = onSnapshot(runsQuery, async (snapshot) => {
        const runsData = snapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((run) => run.ElapsedTime);

        if (!eventData.stages) return;

        const uniqueTracks = await Promise.all(
          eventData.stages.map(async (s) => {
            const { trackId, parkId } = s;
            if (!trackId || !parkId) return;
            const parkRef = doc(db, "Parks", parkId);
            const parkSnapshot = await getDoc(parkRef);
            if (!parkSnapshot.exists()) return null;
            const trackRef = doc(parkRef, "Tracks", trackId);
            const trackSnapshot = await getDoc(trackRef);
            if (trackSnapshot.exists()) {
              return {
                id: trackSnapshot.id,
                name: trackSnapshot.data().TrackName,
              };
            } else {
              return null;
            }
          })
        );
        setTracks([
          {
            id: 0,
            name: "total",
          },
          ...uniqueTracks,
        ]);

        const sortedRuns = runsData.sort(
          (a, b) =>
            convertTimeToMilliseconds(a.ElapsedTime) -
            convertTimeToMilliseconds(b.ElapsedTime)
        );

        const bestTimesByUser = sortedRuns.reduce((acc, run) => {
          const key = `${run.NFCIdentifier}-${run.TrackID}`;
          if (
            !acc[key] ||
            convertTimeToMilliseconds(run.ElapsedTime) <
              convertTimeToMilliseconds(acc[key].ElapsedTime)
          ) {
            acc[key] = run;
          }
          return acc;
        }, {});

        const uniqueRuns = Object.values(bestTimesByUser);

        const totalTimesData = calculateTotalTimes(
          uniqueRuns,
          uniqueTracks.map((t) => t.id)
        );
        setTotalTimes(totalTimesData);

        const ownerPromises = uniqueRuns.map(async (run) => {
          const tagsQuery = query(
            collection(db, "Tags"),
            where("NFCID", "==", run.NFCIdentifier)
          );
          const tagsSnapshot = await getDocs(tagsQuery);

          if (!tagsSnapshot.empty) {
            const ownerUID = tagsSnapshot.docs[0].data().Owner;
            if (ownerUID === "") {
              return {
                NFCIdentifier: run.NFCIdentifier,
                Owner: "Desconocido",
                PhotoUrl: "/default-avatar.jpg",
                categoryId: null,
              };
            }
            const participant = participants.find((p) => p.userId === ownerUID);
            const categoryId = participant ? participant.categoryId : null;

            const userDocRef = doc(db, "Users", ownerUID);
            const userDoc = await getDoc(userDocRef);

            if (!userDoc.empty) {
              const userData = userDoc.data();
              return {
                NFCIdentifier: run.NFCIdentifier,
                Owner: userData.name,
                PhotoUrl: userData.photoUrl || "/default-avatar.jpg",
                categoryId: categoryId,
              };
            }
          }

          // Cubetazo de cambiar NFCIdentifier por User.id
          const userDocRef = doc(db, "Users", run.NFCIdentifier);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            const ownerUID = userDoc.id;
            const participant = participants.find((p) => p.userId === ownerUID);
            const categoryId = participant ? participant.categoryId : null;

            return {
              NFCIdentifier: run.NFCIdentifier,
              Owner: userData.name,
              PhotoUrl: userData.photoUrl || "/default-avatar.jpg",
              categoryId: categoryId,
            };
          }

          return {
            NFCIdentifier: run.NFCIdentifier,
            Owner: "Desconocido",
            PhotoUrl: "/default-avatar.jpg",
            categoryId: null,
          };
        });

        const ownerResults = await Promise.all(ownerPromises);
        const ownerDataMap = ownerResults.reduce((acc, ownerResult) => {
          acc[ownerResult.NFCIdentifier] = {
            Owner: ownerResult.Owner,
            PhotoUrl: ownerResult.PhotoUrl,
            categoryId: ownerResult.categoryId,
          };
          return acc;
        }, {});

        setOwnerData(ownerDataMap);
        setRuns(uniqueRuns);
        setLoading(false);
      });

      return () => unsubscribe();
    };

    if (participants.length > 0) {
      fetchRuns();
    }
  }, [uid, participants]);

  const convertTimeToMilliseconds = (time) => {
    if (!time || time === "---" || time === "DNF") return null;
    try {
      const [hours, minutes, seconds] = time.split(":");
      const [secs, ms] = seconds.split(".");
      return (
        parseInt(hours) * 3600000 +
        parseInt(minutes) * 60000 +
        parseInt(secs) * 1000 +
        parseInt(ms)
      );
    } catch (error) {
      console.error("Error converting time:", time, error);
      return 0;
    }
  };

  const convertMillisecondsToTime = (ms) => {
    if (!ms || ms === 0) return "---";
    try {
      const hours = Math.floor(ms / 3600000);
      const minutes = Math.floor((ms % 3600000) / 60000);
      const seconds = Math.floor((ms % 60000) / 1000);
      const milliseconds = ms % 1000;

      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.${milliseconds
        .toString()
        .padStart(3, "0")}`;
    } catch (error) {
      console.error("Error converting milliseconds:", ms, error);
      return "---";
    }
  };

  const getTimeDifference = (aboveTime, currentTime) => {
    if (
      !aboveTime ||
      !currentTime ||
      aboveTime === "DNF" ||
      currentTime === "DNF"
    ) {
      return null;
    }
    try {
      const aboveInMs = convertTimeToMilliseconds(aboveTime);
      const currentInMs = convertTimeToMilliseconds(currentTime);
      return currentInMs - aboveInMs;
    } catch (error) {
      console.error("Error calculating time difference:", error);
      return null;
    }
  };

  const calculateTotalTimes = (runsData, requiredTrackIds) => {
    if (requiredTrackIds.length === 0) return;
    const totalsByUser = {};

    runsData.forEach((run) => {
      if (!totalsByUser[run.NFCIdentifier]) {
        totalsByUser[run.NFCIdentifier] = {
          NFCIdentifier: run.NFCIdentifier,
          ParkID: run.ParkID,
          EventID: run.EventID,
          trackTimes: {},
          totalTime: 0,
          complete: true,
          id: run.id,
        };
      }

      const currentTimeMs = convertTimeToMilliseconds(run.ElapsedTime);
      if (
        !totalsByUser[run.NFCIdentifier].trackTimes[run.TrackID] ||
        currentTimeMs <
          convertTimeToMilliseconds(
            totalsByUser[run.NFCIdentifier].trackTimes[run.TrackID]
          )
      ) {
        totalsByUser[run.NFCIdentifier].trackTimes[run.TrackID] =
          run.ElapsedTime;
      }
    });

    const totalsArray = Object.values(totalsByUser).map((userData) => {
      const userTrackIDs = Object.keys(userData.trackTimes);

      requiredTrackIds.forEach((trackID) => {
        if (!userTrackIDs.includes(trackID)) {
          userData.trackTimes[trackID] = "DNF";
          userData.complete = false;
        }
      });

      if (userData.complete) {
        const totalMs = Object.values(userData.trackTimes).reduce(
          (sum, time) => sum + convertTimeToMilliseconds(time),
          0
        );
        userData.ElapsedTime = convertMillisecondsToTime(totalMs);
      } else {
        userData.ElapsedTime = "DNF";
      }

      return userData;
    });

    return totalsArray.sort((a, b) => {
      if (a.complete && !b.complete) return -1;
      if (!a.complete && b.complete) return 1;
      if (!a.complete && !b.complete) return 0;
      return (
        convertTimeToMilliseconds(a.ElapsedTime) -
        convertTimeToMilliseconds(b.ElapsedTime)
      );
    });
  };

  // const handleCardClick = (runId) => {
  //   navigate(`/run/${runId}`);
  // };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleTrackChange = (event, newValue) => {
    setCurrentTrack(newValue);
  };

  const getFilteredRuns = () => {
    if (currentTrack === 0) {
      return totalTimes.filter((run) => {
        const ownerInfo = ownerData[run.NFCIdentifier];
        return (
          selectedCategory === "all" ||
          (ownerInfo && ownerInfo.categoryId === selectedCategory)
        );
      });
    }

    return runs.filter((run) => {
      const ownerInfo = ownerData[run.NFCIdentifier];
      const matchesCategory =
        selectedCategory === "all" ||
        (ownerInfo && ownerInfo.categoryId === selectedCategory);
      const matchesTrack = run.TrackID === tracks[currentTrack].id;
      return matchesCategory && matchesTrack;
    });
  };

  if (tracks.length === 0)
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black"></div>
      </div>
    );

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
        <Tabs
          value={currentTrack}
          onChange={handleTrackChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Total" />
          {tracks.slice(1).map((t) => (
            <Tab key={t.id} label={t.name} />
          ))}
        </Tabs>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
        px={2}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Categoría</InputLabel>
          <Select
            value={selectedCategory}
            onChange={handleCategoryChange}
            label="Categoría"
            startAdornment={<FilterListIcon sx={{ mr: 1 }} />}
          >
            <MenuItem value="all">Selecciona</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#000",
            color: "#fff",
            padding: "10px 20px",
            borderRadius: "30px",
            textTransform: "none",
            "&:hover": { backgroundColor: "#333" },
          }}
          onClick={() => setIsCompareOpen(true)}
          startIcon={<PersonIcon />}
          endIcon={<PersonIcon />}
        >
          <CompareArrowsIcon sx={{ fontSize: "24px", mx: 1 }} />
          Comparate
        </Button>
      </Box>

      <ComparePopup
        open={isCompareOpen}
        onClose={() => setIsCompareOpen(false)}
        runs={getFilteredRuns()}
        ownerData={ownerData}
        getTimeDifference={getTimeDifference}
      />

      <Box sx={{ bgcolor: "#F1F1F1", borderRadius: 2, m: 2, p: 2 }}>
        <Typography variant="h6" fontWeight={600}>
          {currentTrack === 0
            ? "Tiempo Total"
            : tracks[currentTrack].name || "Pista desconocida"}
        </Typography>
      </Box>

      <EventLeaderboardUI
        runs={getFilteredRuns()}
        ownerData={ownerData}
        loading={loading}
        // trackNames={trackNames}
        totalTimes={totalTimes}
        // onCardClick={handleCardClick}
        currentTrack={currentTrack}
        getTimeDifference={getTimeDifference}
      />
    </div>
  );
};

export default EventLeaderboard;
