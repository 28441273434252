import { array, boolean, date, number, object, string } from "yup";

const requiredMessage = "Este campo es requerido";

export const addonOptionSchema = object({
  name: string().required(requiredMessage),
  image: string(),
});

export const eventCategorySchema = object({
  id: string(),
  name: string().required(),
});

export const addonVariantSchema = object({
  name: string(),
  options: array(addonOptionSchema),
});

export const addonSchema = object({
  id: string(),
  name: string().required(requiredMessage),
  price: number().required(requiredMessage),
  description: string().required(requiredMessage),
  required: boolean().required(requiredMessage),
  image: string(),
  variants: array(addonVariantSchema),
});

export const locationSchema = object({
  lat: string().required(),
  lng: string().required(),
}).optional();

export const eventsSchema = object({
  // id: string().required(requiredMessage),
  id: string(),
  creatorUID: string().required(requiredMessage),
  eventName: string().required(requiredMessage),
  description: string().required(requiredMessage),
  dateTime: date().required(requiredMessage),
  price: number()
    .min(0, "No se aceptan números negativos")
    .required(requiredMessage),
  photo: string(),
  location: locationSchema,
  addons: array(addonSchema),
  organizationId: string(),
  categories: array(eventCategorySchema).min(
    1,
    "Se debe agreagar al menos una categoría"
  ),
  // pulsera: boolean(),
  // participants?: EventParticipant[],
});
